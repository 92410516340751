import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardSubtitle,
    CardTitle,
} from "@progress/kendo-react-layout";
import dayjs from "dayjs";
import JobDocumentDetailsDropDown from "./JobDocumentDetailsDropDown";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Field } from "@progress/kendo-react-form";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import { JobDocumentContext } from "../../../../providers/Deprecated/Customer/JobDocumentProvider";
import {
    FormDropDown,
    FormInput,
} from "../../../../components/Deprecated/FormComponents";
import { nameValidator } from "../../../../resources/Deprecated/formValidators";
import { jobDocumentTypeFormatter } from "../../../../resources/Deprecated/stringFormatter";
import { BusinessInformationDisplay } from "../../../../components/Pages/Documents/BusinessInformationDisplay.jsx";

const JobDocumentCompanyCustomerDetails = ({
    formRenderProps,
    onCancel,
    handleUpdateJobDocument,
    PDFPrintContainer,
}) => {
    const [jobDocType, setJobDocType] = useState("");
    const [isDatePickerActive, setDatePickerActive] = useState(false);
    const { customer } = useContext(CustomerContext);
    const { relationships, job } = useContext(JobContext);
    const { isJobDocumentEditable } = useContext(JobDocumentContext);
    const formattedCustomerPhoneField = `[${customer?.primaryContact?.phone?.phoneNumberType?.name}] - ${customer?.primaryContact?.phone?.phoneNumber}`;

    const toggleDatePicker = () => {
        setDatePickerActive(!isDatePickerActive);
    };

    useEffect(() => {
        setJobDocType(
            jobDocumentTypeFormatter(formRenderProps.valueGetter("type"))
        );
    }, [formRenderProps.valueGetter("type")]);

    const handleDateChange = () => {
        toggleDatePicker();
    };

    return (
        <Card className={"CardContainer"}>
            <CardHeader className={"CardHeader"}>
                <Field
                    name={"name"}
                    component={FormInput}
                    label={"Title:"}
                    validator={nameValidator}
                    className={"JobDocTitle"}
                    disabled={!isJobDocumentEditable}
                    keepValue={true}
                />
                <JobDocumentDetailsDropDown
                    formRenderProps={formRenderProps}
                    handleUpdateJobDocument={handleUpdateJobDocument}
                    onCancel={onCancel}
                    PDFPrintContainer={PDFPrintContainer}
                />
            </CardHeader>
            <CardBody>
                <div className={"JobDocInformation"}>
                    <span className={"k-display-flex !k-gap-3"}>
                        <BusinessInformationDisplay formData={job} />
                    </span>
                    <span className={"JobDocNumber"}>
                        <CardTitle
                            className={"CardTitle k-display-flex !k-gap-2"}
                            style={{
                                fontSize: 32,
                            }}
                        >
                            <strong>{jobDocType}: </strong>
                            <p>
                                (#
                                {formRenderProps.valueGetter("documentNumber")})
                            </p>
                        </CardTitle>
                        <CardSubtitle
                            className={
                                "CardSubtitle k-display-flex k-justify-content-center"
                            }
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                        >
                            Date:{" "}
                            {isDatePickerActive && isJobDocumentEditable ? (
                                <Field
                                    name={"documentDate"}
                                    component={DatePicker}
                                    onBlur={toggleDatePicker}
                                    onChange={handleDateChange}
                                    show={true}
                                />
                            ) : (
                                <p onClick={toggleDatePicker}>
                                    {dayjs
                                        .utc(
                                            formRenderProps.valueGetter(
                                                "documentDate"
                                            )
                                        )
                                        .format("MM/DD/YYYY")}
                                </p>
                            )}
                        </CardSubtitle>
                        <Field
                            name={"salesPerson"}
                            component={FormDropDown}
                            label={"Salesperson:"}
                            data={relationships?.employees ?? []}
                            dataItemKey="id"
                            textField="fullName"
                            showDisabledValue={"fullName"}
                            disabled={!isJobDocumentEditable}
                            style={{ fontSize: "14px" }}
                        />
                    </span>
                </div>
                <div>
                    <span>
                        {" "}
                        <section className={"CustomerInformation"}>
                            <p>{customer?.combinedName}</p>
                            <p>{formattedCustomerPhoneField}</p>
                            <p>{customer?.primaryContact?.email}</p>
                            <p>
                                {customer?.primaryContact?.address?.fullAddress}
                            </p>
                        </section>
                    </span>
                </div>
            </CardBody>
        </Card>
    );
};

export default JobDocumentCompanyCustomerDetails;
