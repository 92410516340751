import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { useModal } from "../../../../hooks/useModal";
import {
    deleteEvent,
    getAllJobEventsForJobAndEventType,
} from "../../../../services/Deprecated/customer/eventServices";
import { utcToLocalFormatter } from "../../../../resources/Deprecated/stringFormatter";
import { EventModal } from "./EventModal/EventModal";
import CalendarEventCopyModal from "./CalendarEventCopyModal";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { orderBy } from "@progress/kendo-data-query";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";
import { CustomCell } from "../../../../components/Deprecated/CustomGridCells";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { ActionButton } from "../../../../components/Buttons/ActionButton";

dayjs.extend(utc);

const EventTypeDatesPortlet = (props) => {
    const eventId = props?.match?.params?.eventId;
    const eventContext = useContext(EventContext);
    const { triggerReload, reloadData } = useContext(ReloadDataContext);
    const [data, setData] = useState([]);
    const { job, eventType, id, eventDate } = eventContext.event;
    const copyModal = useModal();
    const eventModal = useModal();

    useEffect(() => {
        if (eventType && id && job?.id) {
            getAllJobEventsForJobAndEventType(job.id, eventType.id).then(
                (response) => {
                    setData(formatData(response.data));
                }
            );
        }
    }, [eventType, job, eventId, eventDate, reloadData]);

    // This is a custom formatter for the appointment time
    // It takes the start and end time and formats it to a string
    // Example: 8:00 AM - 10:00 AM
    const displayAppointmentTime = (dataItem) => {
        if (!dataItem.appointmentTime) {
            return "";
        }

        const appointmentTime = dataItem.appointmentTime;

        const startTime = utcToLocalFormatter(
            appointmentTime.startTime,
            "h:mm A"
        );
        const endTime = utcToLocalFormatter(appointmentTime.endTime, "h:mm A");

        return `${startTime} - ${endTime}`;
    };

    /**
     * @function formatData
     * @description Formats the data to display in the grid
     * @param data - The data to format for display
     * @return {Array|Object} - The formatted data
     */
    const formatData = (data) => {
        return data.map((event) => {
            return {
                ...event,
                eventDate: dayjs(event.eventDate).format("MM/DD/YYYY"),
            };
        });
    };

    /**
     * @function onCopy
     * @description Opens the copy modal
     * @param id - The id of the event to copy
     */
    const onCopy = (id) => {
        copyModal.open(id);
    };

    return (
        <Card
            style={{
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                maxHeight: "316px",
            }}
        >
            <EventModal {...eventModal} />
            <CalendarEventCopyModal {...copyModal} />
            <CardHeader
                className="k-hbox"
                style={{
                    background: "transparent",
                    width: "100%",
                }}
            >
                <CardTitle
                    style={{
                        marginBottom: "4px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        height: "40px",
                    }}
                >
                    <strong className={"text-overflow"}>
                        &quot;{eventContext.event?.eventType?.name}&quot; Events
                        On Related Job
                    </strong>
                    <ActionButton
                        buttonText={"Create"}
                        icon={"k-icon k-i-plus"}
                        onClick={() =>
                            eventModal.open(undefined, {
                                eventDateTitle:
                                    eventContext.event?.eventType?.name,
                                eventType: eventContext.event?.eventType,
                                eventDate: new Date(),
                                job: job,
                                customer: eventContext.event?.customer,
                            })
                        }
                        permissions={[PermissionsEnum.CreateCalendarEvent]}
                    />
                </CardTitle>
            </CardHeader>
            <CardBody
                style={{
                    padding: "5px",
                    overflowY: "auto",
                }}
            >
                {id ? (
                    <Grid
                        data={orderBy(data, [
                            { field: "eventDate", dir: "asc" },
                        ])}
                        style={{
                            height: "235px",
                        }}
                    >
                        <GridNoRecords>
                            No Events with same type on this Job
                        </GridNoRecords>
                        <GridColumn
                            field="eventDate"
                            title="Date:"
                            width={110}
                            headerClassName={"hide-element"}
                            cell={(props) => (
                                <LinkCell
                                    {...props}
                                    style={{
                                        fontWeight: "bold",
                                        backgroundColor:
                                            props.dataItem.id === id
                                                ? "rgba(51, 122, 183, 0.2)"
                                                : "inherit",
                                    }}
                                    uri={`/Event/${props.dataItem.id}`}
                                />
                            )}
                        />
                        <GridColumn
                            field="appointmentTime"
                            title="Apppointment Time:"
                            width={195}
                            headerClassName={"hide-element"}
                            cell={(props) => (
                                <CustomCell
                                    {...props}
                                    style={{
                                        backgroundColor:
                                            props.dataItem.id === id
                                                ? "rgba(51, 122, 183, 0.2)"
                                                : "inherit",
                                    }}
                                >
                                    <strong>
                                        {displayAppointmentTime(props.dataItem)}
                                    </strong>
                                </CustomCell>
                            )}
                        />
                        <GridColumn
                            headerClassName={"hide-element"}
                            width={188}
                            cell={(props) => (
                                <EventTypeDatesPortletActionCell
                                    {...props}
                                    onEdit={() =>
                                        eventModal.open(props.dataItem.id)
                                    }
                                    onDelete={() =>
                                        deleteEvent(props.dataItem.id).then(
                                            () => {
                                                triggerReload();
                                            }
                                        )
                                    }
                                    onCopy={onCopy}
                                    calEventId={id}
                                />
                            )}
                        />
                    </Grid>
                ) : (
                    <CenterLoader />
                )}
            </CardBody>
        </Card>
    );
};

export default EventTypeDatesPortlet;

const EventTypeDatesPortletActionCell = (props) => {
    const { onDelete, onEdit, onCopy, calEventId, dataItem } = props;

    return dataItem.id !== calEventId ? (
        <ActionCell
            onDelete={() => onDelete(dataItem.id)}
            onEdit={() => onEdit(dataItem.id)}
            permissions={{
                delete: [PermissionsEnum.DeleteCalendarEvent],
                edit: [PermissionsEnum.UpdateCalendarEvent],
            }}
            additionalActions={[
                {
                    onClick: () => onCopy(dataItem.id),
                    buttonText: "Copy",
                    permissions: [PermissionsEnum.CreateCalendarEvent],
                },
            ]}
        />
    ) : (
        <ActionCell
            additionalActions={[
                {
                    onClick: () => onCopy(dataItem.id),
                    buttonText: "Copy",
                    permissions: [PermissionsEnum.CreateCalendarEvent],
                },
            ]}
        />
    );
};
