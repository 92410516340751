import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";

const PendingChangesWarning = ({ visible, onConfirm, onCancel }) => {
    return (
        <div>
            {visible && (
                <Dialog
                    title={"Please confirm"}
                    onClose={onCancel}
                    width={300}
                    height={"auto"}
                >
                    <p
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            margin: "10px",
                        }}
                    >
                        Are you sure you want to Cancel? Any pending changes
                        will be lost.
                    </p>
                    <div>
                        <Button
                            themeColor={"primary"}
                            fillMode={"solid"}
                            rounded={"medium"}
                            onClick={onCancel}
                            style={{ width: "100px", margin: "10px" }}
                        >
                            No
                        </Button>
                        <Button
                            themeColor={"error"}
                            fillMode={"solid"}
                            rounded={"medium"}
                            onClick={onConfirm}
                            style={{ width: "100px", margin: "10px" }}
                        >
                            Yes
                        </Button>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

export default PendingChangesWarning;
