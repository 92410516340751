import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import { ColumnMenu } from "../../../../components/Deprecated/ColumnMenu";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";
import { CustomDateCell } from "../../../../components/Deprecated/CustomGridCells";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import { markJobActive } from "../../../../services/Deprecated/customer/jobServices";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";

const DeadJobsList = () => {
    const customerContext = useContext(CustomerContext);

    const jobFilter = {
        filters: [
            {
                field: "isMarkedDead",
                operator: "eq",
                value: true,
            },
            {
                field: "isArchived",
                operator: "eq",
                value: false,
            },
            {
                field: "customerId",
                operator: "eq",
                value: customerContext.customer.id,
            },
        ],
        logic: "and",
    };

    return customerContext.customer.id ? (
        <DataGrid
            uri={`/Customer/Job/GetAll?`}
            initialFilter={jobFilter}
            searchFields={["name", "description"]}
        >
            <GridColumn
                field="name"
                title="Name:"
                filter={"text"}
                locked={true}
                width={300}
                columnMenu={ColumnMenu}
                cell={(props) => (
                    <LinkCell
                        {...props}
                        uri={`/customer/${customerContext.customer.id}/job/${props.dataItem.id}`}
                    />
                )}
            />
            <GridColumn
                field="description"
                title="Description:"
                filter={"text"}
                width={300}
                columnMenu={ColumnMenu}
            />
            <GridColumn
                field={"address.fullAddress"}
                title="Address:"
                width={300}
                cell={(props) => (
                    <LinkCell
                        {...props}
                        uri={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                            props.dataItem.address.fullAddress
                        )}`}
                        text={props.dataItem.address.fullAddress}
                        target="_blank"
                    />
                )}
            />
            <GridColumn
                field="created"
                title="Date Created:"
                filter={"date"}
                columnMenu={ColumnMenu}
                width={150}
                cell={(props) => (
                    <CustomDateCell {...props} data={props.dataItem.created} />
                )}
            />
            <GridColumn
                field={"markedDeadDate"}
                title={"Dead Date:"}
                filter={"text"}
                columnMenu={ColumnMenu}
                width={150}
                cell={(props) => (
                    <CustomDateCell
                        {...props}
                        data={props.dataItem.markedDeadDate}
                    />
                )}
            />
            <GridColumn
                width={160}
                cell={(props) => (
                    <ActionButtonsCommandCell
                        record={props.dataItem}
                        dataId={props.dataItem.id}
                        action={markJobActive}
                        actionName={"Mark Active"}
                        viewPage={{
                            uri: `/customer/${customerContext.customer.id}/job/${props.dataItem.id}`,
                            breadcrumbInfo: [
                                {
                                    id: props.dataItem.id,
                                    text: customerContext.customer.combinedName,
                                },
                                {
                                    id: props.dataItem.id,
                                    text: props.dataItem.name,
                                },
                            ],
                        }}
                        requiredPermissionsView={[PermissionsEnum.ViewJob]}
                        requiredPermissionsAction={[PermissionsEnum.UpdateJob]}
                    />
                )}
            />
        </DataGrid>
    ) : (
        <CenterLoader />
    );
};

export default DeadJobsList;
