import React, { useContext } from "react";
import {
    Card,
    CardActions,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import JobDocumentSummaries from "./JobDocumentSummaries";
import { Button } from "@progress/kendo-react-buttons";
import "./JobDocument.module.scss";
import { JobDocumentContext } from "../../../../providers/Deprecated/Customer/JobDocumentProvider";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { useModal } from "../../../../hooks/useModal.js";
import { VendorDocumentModalWrapper } from "../../../Inventory/VendorDocuments/Components/VendorDocumentModal/VendorDocumentModal.jsx";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider.jsx";

const JobDocumentPortlet = ({ portletName, jobDocType }) => {
    const { job } = useContext(JobContext);
    const jobDocumentContext = useContext(JobDocumentContext);
    const jobDocuments = jobDocumentContext.jobDocuments[jobDocType];
    const vendorDocumentModal = useModal();

    const onCreateClick = () => {
        if (jobDocType === 3) {
            vendorDocumentModal.open(undefined, {
                businessInformation: job.businessInformation,
                documentNumber: "000000",
                address: job.businessInformation.address,
                job: job,
            });
        } else if (jobDocType === 0) {
            jobDocumentContext.setSelectedJobDocument({
                id: undefined,
                operation: "create",
            });
        }
    };

    return (
        <Card
            style={{
                width: 400,
                height: 415,
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                marginTop: "15px",
            }}
        >
            <CardHeader
                className="k-hbox"
                style={{
                    background: "transparent",
                    width: "100%",
                }}
            >
                <CardTitle
                    style={{
                        marginBottom: "4px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <strong>{portletName}</strong>
                    {/* Allow the creation of 'Estimates(0)' and 'Purchase Orders(3)' */}
                    {(jobDocType === 3 || jobDocType === 0) && (
                        <>
                            <VendorDocumentModalWrapper
                                {...vendorDocumentModal}
                            />
                            <Button
                                themeColor={"primary"}
                                fillMode={"solid"}
                                rounded={"medium"}
                                style={{
                                    width: "80px",
                                }}
                                icon={"k-icon k-i-plus"}
                                onClick={onCreateClick}
                            >
                                Create
                            </Button>
                        </>
                    )}
                </CardTitle>
            </CardHeader>
            <CardBody
                style={{
                    padding: "2px",
                }}
            >
                {jobDocuments ? (
                    <JobDocumentSummaries jobDocuments={jobDocuments} />
                ) : (
                    <CenterLoader />
                )}
            </CardBody>
            <CardActions
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <span
                    style={{
                        fontSize: "13px",
                        alignSelf: "center",
                        color: "#656565",
                    }}
                >
                    {jobDocuments ? jobDocuments.length : 0} Documents
                </span>
            </CardActions>
        </Card>
    );
};

export default JobDocumentPortlet;
