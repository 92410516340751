export default class LineItemHelper {
    /**
     * @function calculateRunningTotal
     * @description Calculate the running total of line items
     * @param lineItems {Array} - The line items to calculate
     * @return {boolean}
     */
    static calculateRunningTotal(lineItems) {
        return lineItems.reduce((acc, item) => {
            return acc + item.total.amount;
        }, 0);
    }
}
