import React, { useState } from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";

const AddDiscount = ({
    onAddDiscount,
    onClose,
    initialDiscountScope,
    selectedItem,
}) => {
    const [discountType, setDiscountType] = useState("Flat");
    const [discountValue, setDiscountValue] = useState(0);

    const handleSubmit = (event) => {
        event.preventDefault();

        // For a flat discount, negate the absolute value; for a percentage discount, use the absolute value directly
        const value =
            discountType === "Flat"
                ? -Math.abs(discountValue)
                : Math.abs(discountValue);

        const discountData = {
            type: discountType,
            scope: initialDiscountScope, // "Item" for selected items or "Document" for document-wide discounts
            value: value,
            item:
                initialDiscountScope === "Item"
                    ? { id: selectedItem.id }
                    : null, // Use the ID of the selected item directly
            parentId: initialDiscountScope === "Item" ? selectedItem.id : null,
        };

        onAddDiscount(discountData);
        onClose();
    };

    return (
        <form onSubmit={handleSubmit}>
            <DropDownList
                data={["Flat", "Percentage"]}
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
                style={{ marginBottom: "10px" }}
            />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                }}
            >
                <NumericTextBox
                    placeholder="Discount Value"
                    value={discountValue}
                    onChange={(e) => setDiscountValue(e.value)}
                    style={{
                        width:
                            discountType === "Percentage"
                                ? "calc(100% - 40px)"
                                : "100%",
                    }}
                />
                {discountType === "Percentage" && (
                    <Label style={{ marginLeft: "5px" }}>%</Label>
                )}
            </div>
            <Button
                themeColor="success"
                fillMode="solid"
                rounded="medium"
                type="submit"
                style={{ marginTop: "20px", marginRight: "10px" }}
                icon="k-icon k-i-plus"
            >
                Add
            </Button>
            <Button
                themeColor="primary"
                fillMode="solid"
                rounded="medium"
                onClick={onClose}
                type="button"
                style={{ marginTop: "20px" }}
            >
                Cancel
            </Button>
        </form>
    );
};

export default AddDiscount;
