import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { deleteEventType } from "../../../../../services/Deprecated/calendar/eventTypeServices";
import { YesNoCell } from "../../../../../components/GridColumnComponents/YesNoCell";
import { EventTypeModal } from "./Components/EventTypeModal";
import { BaseCell } from "../../../../../components/GridColumnComponents/BaseCell";
import GeneralTaskGridModal from "../GeneralTasks/GeneralTaskGridModal";
import EventTypeTaskAndCategoriesModal from "./Components/EventTypeTaskAndCategoriesModal";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const EventTypeDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const eventTypeModal = useModal();
    const eventTypeCategoriesModal = useModal();
    const generalTaskGridModal = useModal();

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <EventTypeModal {...eventTypeModal} />
            <GeneralTaskGridModal {...generalTaskGridModal} />
            <EventTypeTaskAndCategoriesModal {...eventTypeCategoriesModal} />
            <span className={"DisplayFlex SmallGap"}>
                <ActionButton
                    onClick={() => eventTypeModal.open()}
                    buttonText={"Create EventType"}
                    icon={"k-icon k-i-plus"}
                    permissions={[PermissionsEnum.CreateEventType]}
                />
                <ActionButton
                    onClick={() => generalTaskGridModal.open()}
                    buttonText={"View General Tasks (Skills)"}
                    permissions={[PermissionsEnum.ViewGeneralTask]}
                />
            </span>
            <DataGrid
                uri={"/Customer/EventType/GetAll?"}
                style={{ maxWidth: 875 }}
                initialSort={[]}
            >
                <GridColumn field="name" title="Name:" width="250px" />
                <GridColumn
                    field="isAssociatedToCoA"
                    title="Associated to CoA:"
                    cells={{
                        data: YesNoCell,
                    }}
                    width="200px"
                />
                <GridColumn
                    field="calendarColor"
                    title="Color:"
                    width="150px"
                    cell={(props) => (
                        <BaseCell
                            {...props}
                            style={{
                                backgroundColor: props.dataItem.calendarColor,
                            }}
                        />
                    )}
                />
                <GridColumn
                    width={250}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() =>
                                deleteEventType(props.dataItem.id).then(() =>
                                    reloadDataContext.triggerReload()
                                )
                            }
                            onEdit={() =>
                                eventTypeModal.open(props.dataItem.id)
                            }
                            permissions={{
                                edit: [PermissionsEnum.UpdateEventType],
                                delete: [PermissionsEnum.DeleteEventType],
                            }}
                            additionalActions={[
                                {
                                    buttonText: "Add Categories",
                                    onClick: () =>
                                        eventTypeCategoriesModal.open(
                                            props.dataItem.id
                                        ),
                                    permissions: [
                                        PermissionsEnum.UpdateTaskCategory,
                                    ],
                                },
                            ]}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
export default EventTypeDashboardPage;
