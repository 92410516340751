import React, { useContext, useMemo } from "react";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import { Field } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { ItemRelationshipsContext } from "../../../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import { AccountHelper } from "../../../../Accounting/Helpers/AccountHelper";
import { useAccounts } from "../../../../../hooks/Deprecated/useAccounts";
import {
    FormDropDown,
    FormInput,
    FormNumericInput,
    FormTextArea,
} from "../../../../../components/Deprecated/FormComponents";
import {
    nameValidator,
    requiredValidator,
} from "../../../../../resources/Deprecated/formValidators";

const AccountDropdownItemRenderer = (li, itemProps) => {
    const isRoot = itemProps.dataItem.isRoot;
    let itemChildren;

    if (isRoot) {
        itemChildren = (
            <span>
                <strong>{itemProps.dataItem.name}</strong>
            </span>
        );
    } else {
        itemChildren = (
            <span>
                <span>{itemProps.dataItem.parentAccount.name} -</span>
                <span> {itemProps.dataItem.name}</span>
            </span>
        );
    }

    return React.cloneElement(li, li.props, itemChildren);
};

export const DetailItemInformationFormExtension = ({ allowLoad }) => {
    const { relationships } = useContext(ItemRelationshipsContext);

    const expenseAccounts = useMemo(
        () =>
            relationships.expenseAccounts.flatMap((acc) =>
                AccountHelper.flatmapAccounts(acc)
            ),
        [relationships.expenseAccounts]
    );
    const { incomeAccounts } = useAccounts(allowLoad);

    return (
        <>
            <GridLayoutItem col={1} row={1}>
                <Field
                    name={"name"}
                    component={FormInput}
                    label={"Name:"}
                    validator={nameValidator}
                />
                <Field
                    name={"retailPrice.amount"}
                    component={FormNumericInput}
                    format={"c2"}
                    label={"Retail Price:"}
                />
                <span
                    style={{ marginTop: 10 }}
                    className={"JustifyLeftAlignCenter FlexColumn LargeGap"}
                >
                    <Field
                        name={"isActive"}
                        component={Checkbox}
                        label={"Is Active"}
                        rounded={"large"}
                        size={"medium"}
                    />
                    <Field
                        name={"isDescriptionEditAllowed"}
                        component={Checkbox}
                        label={"Is Description Edit Allowed"}
                        rounded={"large"}
                        size={"medium"}
                    />
                </span>
            </GridLayoutItem>
            {incomeAccounts.length > 0 && expenseAccounts.length > 0 && (
                <GridLayoutItem col={2} row={1}>
                    <Field
                        name={"expenseAccount"}
                        component={FormDropDown}
                        data={expenseAccounts}
                        dataItemKey="id"
                        textField="name"
                        validator={requiredValidator}
                        label={"Expense Account:"}
                        itemRender={AccountDropdownItemRenderer}
                    />
                    <Field
                        name={"incomeAccount"}
                        component={FormDropDown}
                        data={incomeAccounts}
                        dataItemKey="id"
                        textField="name"
                        validator={requiredValidator}
                        label={"Income Account:"}
                        itemRender={AccountDropdownItemRenderer}
                    />
                </GridLayoutItem>
            )}
            <GridLayoutItem col={1} row={3} colSpan={2}>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5 }}
                >
                    <h4
                        style={{
                            margin: "0",
                            marginTop: "8px",
                            marginBottom: "8px",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                            width: "55%",
                            textAlign: "left",
                        }}
                    >
                        SE/SO Description:
                    </h4>
                    <Field
                        name={"baseDescription"}
                        component={FormTextArea}
                        validator={requiredValidator}
                        autoSize={true}
                        rows={4}
                        label={"SE/SO Description:"}
                    />
                </fieldset>
            </GridLayoutItem>
        </>
    );
};
