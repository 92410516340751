import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons";
import { FormRemoteDropDown } from "../../../../../../components/Deprecated/FormComponents";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators.js";
import { VendorDocumentModalContext } from "../Providers/VendorDocumentModalProvider.jsx";
import { AuthContext } from "../../../../../../providers/Deprecated/Authentication/User/AuthProvider.jsx";
import VendorDocumentService from "../../../../../../services/VendorDocumentService.js";

export const ReceivalModal = (props) => {
    const { close, visible, record } = props;
    const { lineItems, reloadItemGrid } = useContext(
        VendorDocumentModalContext
    );
    const { user } = useContext(AuthContext);
    const service = useRef(new VendorDocumentService());
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [initialFormData, setInitialFormData] = useState(undefined);

    useEffect(() => {
        if (visible) {
            const quantity = Math.abs(
                record.record.quantityReceived - record.prevValue
            );

            setInitialFormData({
                quantityReceived: quantity,
                item: record.record.item,
                lineItemId: record.record.id,
            });
        }
    }, [visible]);

    const onClose = () => {
        lineItems.current = lineItems.current.map((item) => {
            if (item.id === record.record.id) {
                item.quantityReceived = record.prevValue;
            }
            return item;
        });
        reloadItemGrid();
        resetState();
        close();
    };

    const resetState = () => {
        setInitialFormData(undefined);
        setLoaderVisible(false);
    };

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        const data = {
            storageLocationId: dataItem.storageLocation.id,
            lineItemId: initialFormData.lineItemId,
            quantity: initialFormData.quantityReceived,
        };

        const request =
            record.record.quantityReceived >= record.prevValue
                ? service.current.receive(data)
                : service.current.unReceive(data);

        request.then((res) => {
            if (!res || !res.success) {
                lineItems.current = lineItems.current.map((item) => {
                    if (item.id === data.lineItemId) {
                        item.quantityReceived = record.prevValue;
                    }
                    return item;
                });
                reloadItemGrid();
                onClose();
                return;
            }

            lineItems.current = lineItems.current.map((item) => {
                if (item.id === data.lineItemId && !item.receiver) {
                    const names = user.name.split(" ");
                    item.receiver = names[0][0] + names[1][0];
                }
                return item;
            });

            reloadItemGrid();
            onClose();
        });
    };

    const generateReceiveMessage = () => {
        let initMsg;

        if (record.record.quantityReceived >= record.prevValue) {
            initMsg = "Do you wish to receive ";
        } else {
            initMsg = "Do you wish to undo the receival of ";
        }

        return (
            <p>
                {initMsg}
                <strong style={{ textDecoration: "underline" }}>
                    {initialFormData.quantityReceived}
                </strong>{" "}
                {initialFormData.item.name}?
            </p>
        );
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={"Receive Item"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            {generateReceiveMessage()}
                            <hr className={"Separator"} />
                            <Field
                                url={"/Inventory/StorageLocation/GetAll?"}
                                name={"storageLocation"}
                                component={FormRemoteDropDown}
                                label={"Storage Location of Item(s):"}
                                validator={requiredValidator}
                                style={{ width: "100%" }}
                            />
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={false}
                                buttonText={"Yes"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
