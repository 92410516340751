import React, { useContext, useEffect, useState } from "react";
import { DropDownButton } from "@progress/kendo-react-buttons";
import dayjs from "dayjs";
import clone from "just-clone";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { JobDocumentContext } from "../../../../providers/Deprecated/Customer/JobDocumentProvider";
import { JobDocumentTypeEnum } from "../../../../resources/Enums/JobDocumentTypeEnum";
import { jobDocumentTypeFormatter } from "../../../../resources/Deprecated/stringFormatter";
import {
    deleteJobDocument,
    updateJobDocumentType,
} from "../../../../services/Deprecated/customer/jobServices";
import { exportPDF } from "@progress/kendo-drawing";
import ConfirmDialogue from "../../../../components/Deprecated/ConfirmDialogue";

const JobDocumentDetailsDropDown = ({
    formRenderProps,
    onCancel,
    handleUpdateJobDocument,
    PDFPrintContainer,
}) => {
    const {
        jobDocument,
        refreshJobDocData,
        setSelectedJobDocument,
        isJobDocumentEditable,
        checkForExistingDocument,
    } = useContext(JobDocumentContext);
    const [items, setItems] = useState([]);
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [deleteLoaderVisible, setDeleteLoaderVisible] = useState(false);
    const name = formRenderProps.valueGetter("name") ?? "UnnamedJobDocument";

    //! This whole component will need a refactor to include more guard clauses
    //! At this moment is is very hard to work in
    useEffect(() => {
        let itemBuilder = [
            { actionName: "Export PDF to Print", icon: "export" },
        ];

        if (isJobDocumentEditable) {
            // Add "Create Sales Order" option only for Estimates
            if (jobDocument?.type === JobDocumentTypeEnum.Estimate) {
                itemBuilder.push({
                    actionName: "Create Sales Order",
                    icon: "add",
                });
            }
        }

        // Always allow creating Invoice from Sales Orders, but only if an Invoice doesn't already exist
        if (jobDocument?.type === JobDocumentTypeEnum.SalesOrder) {
            const hasExistingInvoice = checkForExistingDocument(
                jobDocument.id,
                JobDocumentTypeEnum.Invoice
            );

            if (!hasExistingInvoice) {
                itemBuilder.push({ actionName: "Create Invoice", icon: "add" });
            }
        }

        // Add/Delete option logic
        if (
            (isJobDocumentEditable ||
                jobDocument?.type === JobDocumentTypeEnum.Invoice) &&
            jobDocument?.id // Check to ensure jobdoc is not being created
        ) {
            const action = {
                actionName: `Delete ${jobDocumentTypeFormatter(
                    jobDocument.type
                )}`,
                icon: "delete",
                disabled: false,
            };

            // Disable delete option if an Invoice has been created from this Sales Order
            if (jobDocument?.type === JobDocumentTypeEnum.SalesOrder) {
                const hasExistingInvoice = checkForExistingDocument(
                    jobDocument.id,
                    JobDocumentTypeEnum.Invoice
                );

                if (hasExistingInvoice) {
                    action.disabled = true;
                    action.title =
                        "Cannot delete this Sales Order as an Invoice has already been created from it.";
                }
            }

            // Further disable delete option if payment has been applied, irrespective of document type
            if (jobDocument.totalPaid.amount > 0) {
                action.disabled = true;
                action.title =
                    "Cannot delete this document as a payment has been applied.";
            }

            // Add the delete action regardless, but it may be disabled based on the conditions above
            itemBuilder.push(action);
        }

        setItems(itemBuilder);
    }, [jobDocument, isJobDocumentEditable, checkForExistingDocument]);

    const exportJobDocument = () => {
        exportPDF(
            `${name}-${new Date().toLocaleDateString()}`,
            PDFPrintContainer
        );
    };

    const handleDeleteJobDocument = () => {
        setDeleteLoaderVisible(true);
        deleteJobDocument(jobDocument.id).then(() => {
            refreshJobDocData();
            setDeleteLoaderVisible(false);
            onCancel();
        });
    };

    const handleCreateNewJobDocument = async (newJobDocumentType) => {
        const hasExistingDocument = checkForExistingDocument(
            jobDocument.id,
            newJobDocumentType
        );

        if (hasExistingDocument) {
            // Handle the case where an existing document is found
            alert(
                `A document of type ${newJobDocumentType} already exists for this Job Document.`
            );
            return;
        }
        const clonedJobDocument = clone(jobDocument);
        clonedJobDocument.documentDate = dayjs(
            clonedJobDocument.documentDate
        ).toISOString();
        clonedJobDocument.lineItems = formRenderProps.valueGetter("lineItems");
        await handleUpdateJobDocument({
            values: clonedJobDocument,
        });
        onCancel();
        try {
            const newJobDocResponse = await updateJobDocumentType(
                jobDocument.id,
                newJobDocumentType
            );
            setSelectedJobDocument({
                id: newJobDocResponse.data.id,
                operation: "edit",
            });
            refreshJobDocData();
        } catch (error) {
            console.error("Error creating new Sales Order:", error);
        }
    };

    const handleAction = (e) => {
        const action = e.item.actionName;

        switch (action) {
            case "Export PDF to Print":
                exportJobDocument();
                break;
            case `Delete ${jobDocumentTypeFormatter(jobDocument.type)}`:
                setDeleteConfirmVisible(true);
                break;
            case "Create Sales Order":
                handleCreateNewJobDocument(JobDocumentTypeEnum.SalesOrder);
                break;
            case "Create Invoice":
                handleCreateNewJobDocument(JobDocumentTypeEnum.Invoice);
                break;
            default:
                break;
        }
    };

    const itemRender = (itemProps) => {
        const item = itemProps.item;

        if (!item) {
            return null;
        }

        const content = (
            <span
                className={`k-item ${item.disabled ? "k-state-disabled" : ""}`}
                style={{ pointerEvents: item.disabled ? "all" : "auto" }}
                title={item.disabled ? item.title : ""}
            >
                {item.icon && <span className={`k-icon k-i-${item.icon}`} />}
                {item.actionName}
            </span>
        );

        // Wrap content in Tooltip only if the item is disabled and has a title
        if (item.disabled && item.title) {
            return (
                <Tooltip anchorElement="target" position="bottom">
                    {content}
                </Tooltip>
            );
        }

        return content;
    };

    return (
        <>
            <span>
                <ConfirmDialogue
                    visible={deleteConfirmVisible}
                    setVisible={setDeleteConfirmVisible}
                    loaderVisible={deleteLoaderVisible}
                    actionName={"Delete"}
                    onConfirm={handleDeleteJobDocument}
                    onCancel={() => setDeleteConfirmVisible(false)}
                />
                <DropDownButton
                    text="Details"
                    items={items}
                    itemRender={itemRender}
                    iconClass={"k-icon k-i-align-right"}
                    themeColor={"primary"}
                    textField="actionName"
                    className={"export-hide"}
                    onItemClick={(e) => handleAction(e)}
                />
            </span>
        </>
    );
};

export default JobDocumentDetailsDropDown;
