import React, { useContext, useEffect, useRef, useState } from "react";
import clone from "just-clone";
import { Form, FormElement } from "@progress/kendo-react-form";
import { PDFExport } from "@progress/kendo-react-pdf";
import dayjs from "dayjs";
import { JobDocumentContext } from "../../../../providers/Deprecated/Customer/JobDocumentProvider";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import { AuthContext } from "../../../../providers/Deprecated/Authentication/User/AuthProvider";
import { JobDocumentTypeEnum } from "../../../../resources/Enums/JobDocumentTypeEnum";
import {
    createJobDocument,
    hasAllocationsNeeded,
    updateJobDocument,
} from "../../../../services/Deprecated/customer/jobServices";
import PendingChangesWarning from "../../../../components/Deprecated/PendingChangesWarning";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import JobDocumentCompanyCustomerDetails from "../../JobDocuments/Components/JobDocumentCompanyCustomerDetails";
import JobDocumentLineItemsDetails from "../../JobDocuments/Components/JobDocumentLineItemsDetails";
import TotalsComponent from "../../JobDocuments/Components/TotalsComponent";
import JobDocumentActionButtons from "../../JobDocuments/Components/JobDocumentActionButtons";
import { ManualToastComponent } from "../../../../components/ManualToastComponent";

const JobDocumentModal = () => {
    const jobDocContext = useContext(JobDocumentContext);
    const { job, setNeedsAllocations } = useContext(JobContext);
    const [toast, setToast] = useState(null);
    const [visible, setVisible] = useState(false);
    const [initialRecord, setInitialRecord] = useState({});
    const [pendingChangesVisible, setPendingChangesVisible] = useState(false);
    const { user } = useContext(AuthContext);
    const PDFPrintContainer = useRef(null);

    useEffect(() => {
        const operation = jobDocContext.selectedJobDocument.operation;

        //? Is the operation edit and does the job document exist? If not return
        if (operation === "edit" && !jobDocContext.jobDocument) return;

        //? Does the operation exist?
        if (operation) {
            //? Does the job document exist? If not set it to an empty object
            const recordClone = clone(jobDocContext.jobDocument) ?? {};

            if (recordClone.created) {
                new Date(recordClone.created);
            }

            if (recordClone.documentDate) {
                recordClone.documentDate = dayjs(
                    recordClone.documentDate
                ).toDate();
            } else {
                recordClone.documentDate = new Date();
            }

            recordClone.documentNumber = recordClone.documentNumber ?? "000000";
            recordClone.type = recordClone.type ?? JobDocumentTypeEnum.Estimate;

            if (operation === "create" && user?.activeEmployeeId) {
                const employee = user.activeEmployee;
                if (employee) {
                    recordClone.salesPerson = {
                        id: employee.id,
                        fullName: employee.name,
                    };
                    recordClone.salesPersonId = user.activeEmployeeId;
                }
            } else if (operation === "edit") {
                recordClone.salesPerson = {
                    id: recordClone.salesPerson.id,
                    fullName: recordClone.salesPerson.name,
                };
            }

            setInitialRecord(recordClone);
            setVisible(true);
        }
    }, [
        jobDocContext.selectedJobDocument.operation,
        jobDocContext.jobDocument,
    ]);

    const checkForPendingChanges = (formRenderProps) => {
        if (formRenderProps.touched) setPendingChangesVisible(true);
        else onCancel();
    };

    const onCancel = () => {
        jobDocContext.setSelectedJobDocument({
            id: undefined,
            operation: undefined,
        });
        setVisible(false);
        setPendingChangesVisible(false);
    };

    const assignValues = (dataItem) => {
        const values = dataItem.values;

        values.lineItems = values.lineItems.map((lineItem) => {
            return {
                ...lineItem,
                itemId: lineItem.item?.id,
                isDetailItem: lineItem.item?.isDetailItem,
            };
        });

        values.jobId = job.id;
        values.salesPersonId = values.salesPerson?.id;
        values.documentDate = values.documentDate
            ? dayjs(values.documentDate).format("YYYY-MM-DD").toString()
            : undefined;

        return values;
    };

    const handleCreateJobDocument = async (dataItem) => {
        const values = assignValues(dataItem);
        return await createJobDocument(values);
    };

    const handleUpdateJobDocument = async (dataItem) => {
        const values = assignValues(dataItem);
        await updateJobDocument(values);
    };

    const clearToast = () => {
        setToast(null);
    };

    const onSubmit = async (dataItem) => {
        if (!dataItem.isValid) return;

        const buttonClicked = dataItem.event?.nativeEvent?.submitter?.innerText;
        const saveButtonClicked = buttonClicked === "Save";
        const saveAndCloseButtonClicked = buttonClicked === "Save And Close";
        const isCreateJobDocument = !jobDocContext.selectedJobDocument.id;

        if (saveButtonClicked || saveAndCloseButtonClicked) {
            if (isCreateJobDocument) {
                const apiResponse = await handleCreateJobDocument(dataItem);
                jobDocContext.setSelectedJobDocument({
                    id: apiResponse.data.id,
                    operation: "edit",
                });
                dataItem.values.id = apiResponse.data.id;
            }

            if (!isCreateJobDocument) {
                await handleUpdateJobDocument(dataItem);

                if (saveButtonClicked) {
                    jobDocContext.setReloadJobDocument(
                        !jobDocContext.reloadJobDocument
                    );
                }
            }
            jobDocContext.refreshJobDocData();
            hasAllocationsNeeded(job.id).then((res) => {
                setNeedsAllocations(res.data);
            });

            if (saveButtonClicked) {
                setToast({
                    message: "Job Document updated successfully",
                    type: "success",
                });
            }

            if (saveAndCloseButtonClicked) onCancel();
        }
    };

    const assignFormTitle = (name) => {
        return name ? "Edit Job Document: " + name : "Create Job Document:";
    };

    return (
        <div>
            <PendingChangesWarning
                visible={pendingChangesVisible}
                setVisible={setPendingChangesVisible}
                onCancel={() => setPendingChangesVisible(false)}
                onConfirm={onCancel}
            />
            {visible && initialRecord && (
                <Form
                    onSubmitClick={onSubmit}
                    initialValues={initialRecord}
                    render={(formRenderProps) => (
                        <PDFExport>
                            <ResponsiveDialog
                                title={assignFormTitle(
                                    formRenderProps.valueGetter("name")
                                )}
                                onClose={() =>
                                    checkForPendingChanges(formRenderProps)
                                }
                                size={"extraLarge"}
                            >
                                <div
                                    ref={PDFPrintContainer}
                                    className="pdf-export-container job-doc-pdf-export"
                                    style={{ position: "relative" }}
                                >
                                    <FormElement>
                                        <JobDocumentCompanyCustomerDetails
                                            formRenderProps={formRenderProps}
                                            handleUpdateJobDocument={
                                                handleUpdateJobDocument
                                            }
                                            onCancel={onCancel}
                                            PDFPrintContainer={
                                                PDFPrintContainer
                                            }
                                        />
                                        <JobDocumentLineItemsDetails
                                            formRenderProps={formRenderProps}
                                            handleUpdateJobDocument={
                                                handleUpdateJobDocument
                                            }
                                        />
                                        <TotalsComponent
                                            discounts={
                                                jobDocContext.totals.discounts
                                            }
                                            total={jobDocContext.totals.total}
                                            totalPaid={
                                                jobDocContext?.jobDocument
                                                    ?.totalPaid?.amount ?? 0
                                            }
                                        />
                                        <JobDocumentActionButtons
                                            onCancel={() =>
                                                checkForPendingChanges(
                                                    formRenderProps
                                                )
                                            }
                                        />
                                    </FormElement>
                                    <ManualToastComponent
                                        toast={toast}
                                        clearToast={clearToast}
                                    />
                                    <div className="export-show-only">
                                        <div className="signature-line">
                                            <div>Signature:</div>
                                            <div className="solid-line"></div>
                                        </div>
                                    </div>
                                </div>
                            </ResponsiveDialog>
                        </PDFExport>
                    )}
                />
            )}
        </div>
    );
};

export default JobDocumentModal;
