import React, { useContext } from "react";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import { CustomerTypeEnum } from "../../../../resources/Enums/CustomerStatusEnum";
import FormField from "../../../../components/Deprecated/StylingField";
import { addressFormatter } from "../../../../resources/Deprecated/helpers";

const CustomerDetails = () => {
    const customerContext = useContext(CustomerContext);
    const customer = customerContext.customer;

    return (
        customer?.id && (
            <div className={"JustifyLeftAlignLeft LargeGap"}>
                <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
                    {customer.type === CustomerTypeEnum.Enum.Individual ? (
                        <FormField
                            label="Full Name"
                            value={customer.combinedName}
                        />
                    ) : (
                        <FormField
                            label="Company Name"
                            value={customer.name1}
                        />
                    )}
                    <FormField
                        label="Address"
                        value={addressFormatter(customer.address, "short")}
                    />
                    <FormField label="City" value={customer.address.city} />
                    <FormField label="State" value={customer.address.state} />
                    <FormField label="Zip" value={customer.address.zipCode} />
                </span>
                <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
                    <FormField
                        label="Primary Contact"
                        value={customer.primaryContact.combinedName}
                    />
                    {customer.primaryContact.email && (
                        <FormField
                            label="Email"
                            value={customer.primaryContact.email}
                        />
                    )}
                    {customer.primaryContact.phone && (
                        <FormField
                            label="Phone"
                            value={`(${customer.primaryContact.phone.phoneNumberType.name}) ${customer.primaryContact.phone.phoneNumber}`}
                        />
                    )}
                </span>
                {customer.secondaryContact && (
                    <span
                        className={"JustifyLeftAlignLeft SmallGap FlexColumn"}
                    >
                        <FormField
                            label="Secondary Contact"
                            value={customer.secondaryContact.combinedName}
                        />
                        {customer.secondaryContact.email && (
                            <FormField
                                label="Email"
                                value={customer.secondaryContact.email}
                            />
                        )}
                        {customer.secondaryContact.phone && (
                            <FormField
                                label="Phone"
                                value={`(${customer.secondaryContact.phone.phoneNumberType.name}) ${customer.secondaryContact.phone.phoneNumber}`}
                            />
                        )}
                    </span>
                )}
            </div>
        )
    );
};

export default CustomerDetails;
