import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import CustomerDetails from "../../Customers/Components/CustomerDetails";
import JobDetails from "../../Jobs/Components/JobDetails";

export const EventTabs = () => {
    const [selected, setSelected] = useState(0);

    return (
        <TabStrip
            selected={selected}
            onSelect={(e) => setSelected(e.selected)}
            className="tabs"
            style={{ height: "350px" }}
        >
            <TabStripTab title="Job Details">
                <JobDetails />
            </TabStripTab>
            <TabStripTab title="Customer Details">
                <CustomerDetails />
            </TabStripTab>
        </TabStrip>
    );
};
