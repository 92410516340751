import { Button } from "@progress/kendo-react-buttons";
import React, { useContext } from "react";
import { JobDocumentContext } from "../../../../providers/Deprecated/Customer/JobDocumentProvider";

const JobDocumentActionButtons = ({ onCancel }) => {
    const jobDocContext = useContext(JobDocumentContext);
    const isDocumentEditable = jobDocContext.isJobDocumentEditable;

    return (
        <div className={"ActionButtons export-hide"}>
            <Button
                themeColor={"info"}
                fillMode={"solid"}
                rounded={"medium"}
                icon={"k-icon k-i-plus"}
                type={"submit"}
                disabled={!isDocumentEditable}
            >
                Save
            </Button>
            <Button
                themeColor={"info"}
                fillMode={"solid"}
                rounded={"medium"}
                icon={"k-icon k-i-plus"}
                type={"submit"}
                disabled={!isDocumentEditable}
            >
                Save And Close
            </Button>
            <Button
                themeColor={"primary"}
                fillMode={"solid"}
                rounded={"medium"}
                onClick={onCancel}
                style={{
                    padding: "5px",
                }}
            >
                Cancel
            </Button>
        </div>
    );
};

export default JobDocumentActionButtons;
