import React, { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { EventModalHelper } from "./Helpers/EventModalHelper";
import { Stepper } from "@progress/kendo-react-layout";
import { EventModalStepOne } from "./Components/EventModalStepOne";
import { EventModalStepTwo } from "./Components/EventModalStepTwo";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import {
    createEvent,
    getEvent,
    updateEvent,
} from "../../../../../services/Deprecated/customer/eventServices";
import ResponsiveDialog from "../../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../../components/Buttons/FormButtons";

export const EventModal = (props) => {
    const InitialStepState = {
        isLastStep: false,
        currentStep: 0,
        steps: [
            {
                label: "Information",
                isValid: true,
            },
            {
                label: "Optional Relations",
                isValid: true,
            },
        ],
        onPreviousStepSubmit: () => onPrev(),
    };
    const { id, close, visible, record, onSuccessCallback, onFailureCallback } =
        props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [stepState, setStepState] = useState(InitialStepState);

    useEffect(() => {
        if (!visible) return;

        if (id) {
            getEvent(id).then((res) => {
                const data = EventModalHelper.formatDataForModal(res.data);
                setInitialFormData(data);
                setInitialStepState(data.eventType);
            });
        } else {
            setInitialFormData(EventModalHelper.formatDataForModal(record));
            setInitialStepState(undefined);
        }
    }, [visible, id]);

    const onClose = () => {
        close();
        setLoaderVisible(false);
        setInitialFormData(undefined);
        setStepState(InitialStepState);
    };

    const onPrev = () => {
        setStepState({
            ...stepState,
            currentStep: Math.max(stepState.currentStep - 1, 0),
        });
    };

    const onSubmit = (e) => {
        const { isValid, values, event } = e;

        if (!isValid) {
            return;
        }

        const buttonClickedStr = event?.nativeEvent?.submitter?.innerText;
        if (!isValid || buttonClickedStr === "Next") {
            setStepState(EventModalHelper.formatStepState(stepState, isValid));
            return;
        }

        const data = EventModalHelper.formatDataForNetwork(values);

        setLoaderVisible(true);
        const submitData = id ? updateEvent : createEvent;

        submitData(data).then((res) => {
            triggerReload();
            onClose();

            if ((res.status >= 200 || res.status <= 400) && onSuccessCallback) {
                onSuccessCallback();
            } else if (res.status >= 400 && onFailureCallback) {
                onFailureCallback();
            }
        });
    };

    // If EventType is associated to CoA, set the step state to only show the first step
    // Pass "ForceSingleStep to the record if we want to bypass the step system"
    const setInitialStepState = (eventType) => {
        if (eventType?.isAssociatedToCoA || record.forceSingleStep) {
            setStepState({
                currentStep: 0,
                isLastStep: true,
                steps: [
                    {
                        label: "Information",
                        isValid: true,
                    },
                ],
            });
            return;
        }

        setStepState(InitialStepState);
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={id ? "Edit Event" : "Add Event"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmitClick={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement
                            className={
                                "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                            }
                        >
                            <div style={{ width: "100%" }}>
                                {stepState.steps.length > 1 && (
                                    <Stepper
                                        value={stepState.currentStep}
                                        items={stepState.steps}
                                    />
                                )}
                                {stepState.currentStep === 0 && (
                                    <EventModalStepOne
                                        id={id}
                                        formRenderProps={formRenderProps}
                                        record={record}
                                        setInitialStepState={
                                            setInitialStepState
                                        }
                                    />
                                )}
                                {stepState.currentStep === 1 && (
                                    <EventModalStepTwo
                                        formRenderProps={formRenderProps}
                                        initialValues={initialFormData}
                                    />
                                )}
                            </div>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={true}
                                isCreate={!id}
                                stepState={stepState}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
