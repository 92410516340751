import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const getStartTime = (event) => {
    const eventDate = dayjs.utc(event.eventDate);
    return new Date(eventDate.year(), eventDate.month(), eventDate.date(), 0);
};

const getEndTime = (event) => {
    const eventDate = dayjs.utc(event.eventDate);
    return new Date(eventDate.year(), eventDate.month(), eventDate.date(), 23);
};

/**
 * @function parseEvents
 * @description Takes in events and parses them to make them compatible with the scheduler.
 * @param {Array} events - Array of event objects
 * @returns {Array}
 */
export const parseEvents = (events) => {
    if (!events) {
        return [];
    }

    return events.map((e) => {
        return {
            ...e,
            id: e.id,
            eventTypeId: e.eventType.id,
            isAllDay: !e.appointmentTime,
            start: getStartTime(e),
            end: getEndTime(e),
        };
    });
};

/**
 * @function parseEventTypes
 * @description Takes in event types and parses them to make them compatible with the scheduler.
 * @param {Array} eventTypes - Array of event type objects
 * @returns {Array - Object}
 */
export const parseEventTypes = (eventTypes) => {
    if (!eventTypes) {
        return [];
    }

    return eventTypes.map((et) => {
        return {
            id: et.id,
            text: "",
            value: et.id,
            color: et.calendarColor,
        };
    });
};
