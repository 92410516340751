import * as React from "react";
import { SchedulerViewItem } from "@progress/kendo-react-scheduler";

export const CustomSchedulerViewItem = (props) => {
    return (
        <SchedulerViewItem
            {...props}
            style={{
                ...props.style,
                height: "auto",
                minHeight: "120px",
            }}
        />
    );
};
