import React, { useContext, useEffect, useState } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import AddItem from "./AddItem";
import AddDiscount from "./AddDiscount";
import { Dialog } from "@progress/kendo-react-dialogs";
import "./JobDocument.module.scss";
import { JobDocumentContext } from "../../../../providers/Deprecated/Customer/JobDocumentProvider";
import {
    JobDocumentHelper,
    moveItemInArray,
} from "../Helpers/JobDocumentHelper";
import { roundToTwoDecimals } from "../../../../resources/Deprecated/currencyHelper";
import { ItemTypeEnum } from "../../../../resources/Enums/ItemTypeEnum";
import { resetIndices } from "../../../../resources/Deprecated/helpers";
import { Button } from "@progress/kendo-react-buttons";
import {
    CellEditRender,
    JobDocCellEditCommandCell,
    RowEditRender,
} from "../../../../components/Deprecated/CustomGridCells";

const EDIT_FIELD = "inEdit";

const JobDocumentLineItemsDetails = ({ formRenderProps }) => {
    const jobDocumentContext = useContext(JobDocumentContext);
    const [jobDocumentLineItems, setJobDocumentLineItems] = useState([]);
    const [addItemVisible, setAddItemVisible] = useState(false);
    const [selectedDataItem, setSelectedDataItem] = useState(null);
    const [addDiscountVisible, setAddDiscountVisible] = useState(false);
    const [addItemIndex, setAddItemIndex] = useState(null);
    const [initialDiscountScope, setInitialDiscountScope] =
        useState("Document");
    const { setTotals } = useContext(JobDocumentContext);
    const [itemNameWidth, setItemNameWidth] = useState(200);
    const itemNameMinWidth = 200;
    const itemNameMaxWidth = 500;
    const descriptionWidth = 500;
    const quantityWidth = 100;
    const priceWidth = 100;
    const totalsWidth = 100;
    const commandCellWidth = 185;

    useEffect(() => {
        const document = jobDocumentContext.jobDocument;

        if (!document) {
            setJobDocumentLineItems([]);
            return;
        }

        const data = JobDocumentHelper.formatJobDocumentLineItems(
            document.lineItems
        );
        setJobDocumentLineItems(data);
    }, [jobDocumentContext.jobDocument, jobDocumentContext.reloadJobDocument]);

    useEffect(() => {
        formRenderProps.onChange("lineItems", {
            value: jobDocumentLineItems,
        });
        jobDocumentContext.setRunningTotal(calculateRunningTotal());
    }, [jobDocumentLineItems]);

    const calculateRunningTotal = () => {
        return jobDocumentLineItems.reduce((total, lineItem) => {
            // Add the line item's total to the running total.
            let newTotal = total;

            newTotal += roundToTwoDecimals(lineItem.total.amount);

            return newTotal;
        }, 0);
    };

    // Function to calculate discounts (if not already defined)
    const calculateDiscounts = () => {
        return jobDocumentLineItems.reduce((total, lineItem) => {
            if (lineItem.type === ItemTypeEnum.Discount) {
                return (
                    total + Math.abs(roundToTwoDecimals(lineItem.total.amount))
                );
            }
            return total;
        }, 0);
    };

    useEffect(() => {
        const discounts = calculateDiscounts();
        const total = calculateRunningTotal();

        setTotals({ discounts, total });
    }, [jobDocumentLineItems]);

    const remove = (dataItem) => {
        const newData = jobDocumentLineItems.filter(
            (lineItem) => lineItem.indexPosition !== dataItem.indexPosition
        );
        resetIndices(newData);
        setJobDocumentLineItems(newData);
    };

    const addNew = (dataItem, insertIndex = -1) => {
        const jobDocLengthWithoutDiscounts = jobDocumentLineItems.filter(
            (x) => x.type !== ItemTypeEnum.Discount
        ).length;
        let newLineItem = {
            indexPosition:
                insertIndex >= 0 ? insertIndex : jobDocLengthWithoutDiscounts,
            documentId: formRenderProps.valueGetter("id"),
            quantity: 1,
            ...dataItem,
        };
        const newJobDocLineItems = [...jobDocumentLineItems];
        newJobDocLineItems.splice(newLineItem.indexPosition, 0, newLineItem);
        resetIndices(newJobDocLineItems);
        setJobDocumentLineItems(newJobDocLineItems);
    };

    const handleAddDiscount = (discountData) => {
        setAddDiscountVisible(false);
        let discountPrice = 0;
        let quantity = 1;
        let defaultDescription = "Discount Applied";

        // Calculate discount amount based on type
        if (discountData.type === "Flat") {
            discountPrice = discountData.value;
        } else if (discountData.type === "Percentage") {
            quantity = discountData.value;
            // Calculate percentage based on scope
            discountPrice = -Math.abs(
                (calculateRunningTotal() / 100) * quantity
            );
            defaultDescription = "Percentage Discount Applied";
        }

        const discountObject = {
            item: { name: "---DISCOUNT---" },
            type: ItemTypeEnum.Discount,
            description: discountData.description || defaultDescription,
            quantity: quantity,
            price: { amount: discountPrice, currency: "USD" },
            total: { amount: discountPrice, currency: "USD" },
            parentId:
                discountData.scope === "Item" ? selectedDataItem.id : null,
            parentItemId:
                discountData.scope === "Item" ? selectedDataItem.itemId : null,
            parentIndex:
                discountData.scope === "Item"
                    ? selectedDataItem.indexPosition
                    : null,
            documentId: jobDocumentContext.jobDocument?.id,
        };

        if (discountData.scope === "Document") {
            setJobDocumentLineItems((prevItems) => {
                // Return the new state with the global discount added
                return resetIndices([...prevItems, discountObject]);
            });
        } else {
            // If the scope is "Item", add the discount as a child to the selected item
            setJobDocumentLineItems((prevItems) => {
                return resetIndices(
                    prevItems.map((item) => {
                        ``;
                        if (
                            item.indexPosition === discountObject.parentIndex &&
                            item.itemId === discountObject.parentItemId
                        ) {
                            return {
                                ...item,
                                total: {
                                    amount:
                                        item.total.amount -
                                        discountObject.total.amount,
                                    currency: "USD",
                                },
                                expanded: true,
                                children: item.children
                                    ? [...item.children, discountObject]
                                    : [discountObject],
                            };
                        }
                        return item;
                    })
                );
            });
        }

        // Recalculate the running total to include the new discount
        jobDocumentContext.setRunningTotal(calculateRunningTotal());
    };

    const addNote = () => {
        addNew({
            item: { name: "---NOTE---" },
            description: "Enter note details here...",
            type: ItemTypeEnum.Note,
            total: { amount: 0, currency: "USD" },
            price: { amount: 0, currency: "USD" },
            quantity: 0,
        });
    };

    const enterEdit = (dataItem, field) => {
        if (!jobDocumentContext.isJobDocumentEditable) return;

        if (!JobDocumentHelper.canEnterEditConditional(field, dataItem)) {
            return;
        }

        const newData = jobDocumentLineItems.map((lineItem) => ({
            ...lineItem,
            [EDIT_FIELD]:
                lineItem.indexPosition === dataItem.indexPosition
                    ? field
                    : undefined,
        }));

        setJobDocumentLineItems(newData);
    };

    const exitEdit = () => {
        const newData = jobDocumentLineItems.map((lineItem) => ({
            ...lineItem,
            [EDIT_FIELD]: undefined,
        }));
        setJobDocumentLineItems(newData);
    };

    const onItemChange = (event) => {
        let field = event.field || "";
        let updateValid = JobDocumentHelper.changeItemConditional(
            field,
            event,
            true,
            formRenderProps
        );

        if (!updateValid) return;

        const newData = jobDocumentLineItems.map((lineItem) => {
            if (lineItem.indexPosition !== event.dataItem.indexPosition)
                return lineItem;

            // Kendo creates a new field "price.amount" rather than updating the object Price
            // To avert this we check if the field is "price.amount" and update the price object
            if (field === "price.amount") {
                lineItem.price.amount = event.value;
            } else {
                lineItem[field] = event.value;
            }

            return { ...lineItem }; // Ensure a new object is created
        });

        setJobDocumentLineItems(newData);
    };

    const customCellRender = (td, props) => {
        let cellUnit = "";
        let renderNoChildren = false;
        // If the item is a discount and the quantity is greater than 1, it is a percentage discount
        const isPercentageDiscount =
            props.dataItem.type === ItemTypeEnum.Discount &&
            props.dataItem.quantity > 1;

        if (props.field === "quantity" && isPercentageDiscount) {
            cellUnit = "%";
        }

        if (props.field === "price" && isPercentageDiscount) {
            renderNoChildren = true;
        }

        return (
            <CellEditRender
                originalProps={props}
                td={td}
                enterEdit={enterEdit}
                editField={EDIT_FIELD}
                cellUnit={cellUnit}
                renderNoChildren={renderNoChildren}
            />
        );
    };

    const customRowRender = (tr, props) => {
        const isNotActive = !props.dataItem.item.isActive;
        const className = isNotActive ? "danger-row" : tr.props.className;

        return (
            <RowEditRender
                originalProps={props}
                tr={{ ...tr, props: { ...tr.props, className } }}
                exitEdit={exitEdit}
                editField={EDIT_FIELD}
            />
        );
    };

    const commandCellRender = (props) => {
        const isGlobalDiscount =
            props.dataItem.type === ItemTypeEnum.Discount &&
            !props.dataItem.itemId;

        return (
            <JobDocCellEditCommandCell
                {...props}
                remove={remove}
                moveUp={
                    !isGlobalDiscount &&
                    jobDocumentContext.isJobDocumentEditable
                        ? (dataItem) => moveItem(dataItem, "up")
                        : undefined
                }
                moveDown={
                    !isGlobalDiscount &&
                    jobDocumentContext.isJobDocumentEditable
                        ? (dataItem) => moveItem(dataItem, "down")
                        : undefined
                }
                onAddBelow={
                    !isGlobalDiscount &&
                    jobDocumentContext.isJobDocumentEditable
                        ? (dataItem) => {
                              const index = jobDocumentLineItems.findIndex(
                                  (item) =>
                                      item.indexPosition ===
                                      dataItem.indexPosition
                              );
                              setAddItemVisible(true);
                              setAddItemIndex(index + 1); // Adjust to add below the current item
                          }
                        : undefined
                }
                isJobDocumentEditable={jobDocumentContext.isJobDocumentEditable} // This prop is new
            />
        );
    };

    const moveItem = (dataItem, direction) => {
        if (!jobDocumentContext.isJobDocumentEditable) return;
        const newData = moveItemInArray(
            [...jobDocumentLineItems],
            dataItem,
            direction
        );
        if (newData) {
            resetIndices(newData);
            setJobDocumentLineItems(newData);
        }
    };

    const openAddDiscountModalForDocument = () => {
        setInitialDiscountScope("Document");
        setAddDiscountVisible(true);
    };

    useEffect(() => {
        const otherColumnsWidth =
            115 +
            descriptionWidth +
            quantityWidth +
            priceWidth +
            totalsWidth +
            commandCellWidth;
        const gridPadding = 20;

        const handleResize = () => {
            const totalWidthAvailable =
                window.innerWidth - otherColumnsWidth - gridPadding;
            const adjustedWidth = Math.max(
                itemNameMinWidth,
                Math.min(itemNameMaxWidth, totalWidthAvailable)
            );
            setItemNameWidth(adjustedWidth);
        };

        handleResize(); // Call once to set initial size based on current window size
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [
        descriptionWidth,
        quantityWidth,
        priceWidth,
        totalsWidth,
        commandCellWidth,
    ]);

    return (
        <>
            <AddItem
                addNew={addNew}
                visible={addItemVisible}
                setVisible={setAddItemVisible}
                addItemIndex={addItemIndex}
                setAddItemIndex={setAddItemIndex}
            />
            {addDiscountVisible && (
                <Dialog
                    title="Add Discount"
                    onClose={() => setAddDiscountVisible(false)}
                >
                    <AddDiscount
                        onAddDiscount={handleAddDiscount}
                        onClose={() => setAddDiscountVisible(false)}
                        initialDiscountScope={initialDiscountScope}
                        selectedItem={selectedDataItem}
                    />
                </Dialog>
            )}
            <Grid
                data={jobDocumentLineItems}
                expandField="expanded"
                onExpandChange={(e) => {
                    e.dataItem.expanded = !e.dataItem.expanded;
                    setJobDocumentLineItems([...jobDocumentLineItems]);
                }}
                style={{ width: "100%" }}
                dataItemKey="indexPosition"
                sort={[{ field: "indexPosition", dir: "asc" }]}
                onItemChange={onItemChange}
                cellRender={customCellRender}
                rowRender={customRowRender}
                editField={EDIT_FIELD}
                onRowClick={(e) => setSelectedDataItem(e.dataItem)}
                className="job-doc-line-item-export"
            >
                <GridToolbar style={{ display: "flex" }}>
                    <span style={{ marginLeft: "auto" }}>
                        <Button
                            themeColor="primary"
                            className="export-hide"
                            fillMode="solid"
                            rounded="medium"
                            icon="k-icon k-i-pencil"
                            onClick={addNote}
                            style={{ marginRight: "10px" }}
                            disabled={!jobDocumentContext.isJobDocumentEditable}
                        >
                            Add Note
                        </Button>
                        <Button
                            themeColor="success"
                            className="export-hide"
                            fillMode="solid"
                            rounded="medium"
                            icon="k-icon k-i-plus"
                            onClick={() => {
                                setAddItemVisible(true);
                                setAddItemIndex(-1);
                            }}
                            style={{ marginRight: "10px" }}
                            disabled={!jobDocumentContext.isJobDocumentEditable}
                        >
                            Add Item
                        </Button>
                        <Button
                            themeColor="success"
                            className="export-hide"
                            fillMode="solid"
                            rounded="medium"
                            icon="k-icon k-i-dollar"
                            onClick={openAddDiscountModalForDocument}
                            style={{ marginRight: "10px" }}
                            disabled={!jobDocumentContext.isJobDocumentEditable}
                        >
                            Add Discount
                        </Button>
                    </span>
                </GridToolbar>
                <GridColumn
                    field="item.name"
                    title="Item Name"
                    width={itemNameWidth}
                    editable={false}
                    className="text-overflow"
                    locked={true}
                />
                <GridColumn
                    field="description"
                    title="Description"
                    editable={true}
                    width={descriptionWidth}
                    className="text-overflow"
                />
                <GridColumn
                    field="quantity"
                    title="Quantity"
                    editable={true}
                    editor="numeric"
                    width={quantityWidth}
                />
                <GridColumn
                    field="price.amount"
                    title="Price"
                    editable={true}
                    editor="numeric"
                    format="{0:c}"
                    width={priceWidth}
                />
                <GridColumn
                    field="total.amount"
                    title="Total"
                    editable={false}
                    editor="numeric"
                    format="{0:c}"
                    width={totalsWidth}
                />
                <GridColumn cell={commandCellRender} width={commandCellWidth} />
            </Grid>
        </>
    );
};

export default JobDocumentLineItemsDetails;
