import React, { useContext, useEffect } from "react";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import ToastContext from "react-bootstrap/ToastContext";

/**
 * @function ToastComponent
 * @description Component that displays toast notifications
 * @return {React.JSX.Element}
 * @constructor
 */
export const ToastComponent = () => {
    const { toast, clearToast } = useContext(ToastContext);

    // Clear toasts after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            if (clearToast) {
                clearToast();
            }
        }, 5000);
        return () => clearTimeout(timer);
    });

    return (
        <NotificationGroup
            style={{
                right: 10,
                bottom: 0,
            }}
        >
            <Fade>
                {toast && (
                    <Notification
                        type={{
                            style: toast.type,
                            icon: true,
                        }}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "1rem",
                            width: "auto",
                            height: "40px",
                            fontSize: "1rem",
                        }}
                        closable={true}
                        onClose={() => clearToast()}
                    >
                        <span>{toast.message}</span>
                    </Notification>
                )}
            </Fade>
        </NotificationGroup>
    );
};
