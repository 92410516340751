import * as React from "react";
import { Error, Hint } from "@progress/kendo-react-labels";
import { TextArea } from "@progress/kendo-react-inputs";

/**
 * @function TextAreaInput
 * @description A form input component that represents a TextArea
 * @param fieldRenderProps.validationMessage {string} - The validation message for the field
 * @param fieldRenderProps.touched {boolean} - Whether the field has been touched
 * @param fieldRenderProps.id {string} - The ID of the field
 * @param fieldRenderProps.disabled {boolean} - Whether the field is disabled
 * @param fieldRenderProps.showDisabledValue {boolean} - Whether to show the disabled value
 * @param fieldRenderProps.hint {string} - The hint for the field
 * @param fieldRenderProps.value {string} - The value of the field
 * @param fieldRenderProps.maxLength {number} - The maximum length of the field
 * @param fieldRenderProps.others {object} - Additional props
 * @return {Element}
 * @constructor
 */
export const TextAreaInput = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        id,
        disabled,
        showDisabledValue,
        hint,
        value,
        maxLength = 1000,
        ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const shownValue = showDisabledValue ? value : undefined;

    return (
        <div>
            <TextArea
                id={id}
                maxLength={maxLength}
                ariaDescribedBy={`${hintId} ${errorId}`}
                value={disabled ? shownValue : value ?? undefined}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            <span className={"JustifyBetweenAlignCenter"}>
                <Error id={errorId}>
                    {showValidationMessage && validationMessage}
                </Error>
                <Hint direction={"end"}>
                    {value?.length ?? 0} / {maxLength}
                </Hint>
            </span>
        </div>
    );
};
