import React, {useState} from "react";
import "../../../../../styles/CommonTextStyles.scss";
import "../../../../../styles/CommonPositionalStyles.scss";
import clone from "just-clone";
import {FormRemoteDropDown} from "../../../../../components/Deprecated/FormComponents";
import ResponsiveDialog from "../../../../../components/Deprecated/DialogWrapper";
import {FormButtons} from "../../../../../components/Buttons/FormButtons";

/**
 * @function ItemPackageAddItemModal
 * @description Modal for adding items to a package
 * @param props.visible {boolean} - Modal visibility
 * @param props.close {function} - Modal close function
 * @param props.record {object} - Record object to pass information to modal
 * @param props.appendItemToPackage {function} - Function to append item to package
 * @return {Element}
 * @constructor
 */
export const ItemPackageAddItemModal = (props) => {
    const { visible, close, record, appendItemToPackage } = props;
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(undefined);

    // Since different entities have different props, we need to account for that
    const fetchFiltersBaseOnUri = (uri) => {
        if (uri === "/Inventory/Item/GetAll?") {
            return [
                {
                    field: "IsActive",
                    operator: "eq",
                    value: true,
                },
                {
                    field: "IsStockOrSupplyItem",
                    operator: "eq",
                    value: false,
                },
            ];
        } else {
            return [
                {
                    field: "IsActive",
                    operator: "eq",
                    value: true,
                },
            ];
        }
    };

    const closeForm = () => {
        setSelectedEntity(undefined);
        setLoaderVisible(false);
        close();
    };

    const append = () => {
        setLoaderVisible(true);

        const item = {
            name: selectedEntity.value.name,
            quantity: 1,
            retailPrice: selectedEntity.value.retailPrice,
            total: clone(selectedEntity.value.retailPrice),
            IN_LINE_EDIT: true,
        };

        if (record.uri === "/Inventory/Item/GetAll?") {
            item.itemId = selectedEntity.value.id;
        } else {
            item.detailItemId = selectedEntity.value.id;
        }

        appendItemToPackage(item);
        setLoaderVisible(false);
        closeForm();
    };

    return (
        visible && (
            <ResponsiveDialog
                title={"Add Item"}
                onClose={closeForm}
                size={"small"}
            >
                <FormRemoteDropDown
                    url={record.uri}
                    validationMessage={"Please select an item"}
                    label={"Select an Item..."}
                    style={{ width: "100%" }}
                    initialFilters={fetchFiltersBaseOnUri(record.uri)}
                    onChange={(e) => setSelectedEntity(e)}
                />
                <FormButtons
                    actionOnCancel={closeForm}
                    loaderVisible={loaderVisible}
                    isCreate={true}
                    allowSubmit={selectedEntity !== undefined}
                    onSubmit={append}
                    buttonText={"Add"}
                />
            </ResponsiveDialog>
        )
    );
};
