import { BaseEnum } from "./BaseEnum";

export const JobDocumentTypeEnum = {
    Estimate: 0,
    SalesOrder: 1,
    Invoice: 2,
    VendorDocument: 3,
    FieldVendorDocument: 4,
    Credit: 5,
    ChangeOrder: 6,
    StockOrder: 7,
};

export class NewJobDocumentTypeEnum extends BaseEnum {
    static Enum = {
        Estimate: 0,
        SalesOrder: 1,
        Invoice: 2,
        VendorDocument: 3,
        FieldVendorDocument: 4,
        Credit: 5,
        ChangeOrder: 6,
        StockOrder: 7,
    };
}
