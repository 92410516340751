import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import ActionButtonsCommandCell from "../../../components/Deprecated/ActionButtonsCommandCell";
import { deleteVendor } from "../../../services/Deprecated/inventory/vendorService";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";
import { VendorContext } from "../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { BreadcrumbContext } from "../../../providers/BreadCrumbProvider";
import { LinkCell } from "../../../components/GridColumnComponents/LinkCell";
import { DataGrid } from "../../../components/Grids/DataGrid";
import VendorCreationComponent from "./Components/VendorCreateForm";
import vendorEditForm from "./Components/VendorEditForm";

const Dashboard = () => {
    const { relationshipsLoading } = useContext(VendorContext);
    const { handleRouteChange } = useContext(BreadcrumbContext);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
        >
            {!relationshipsLoading ? (
                <>
                    {/* This is the creation form, this will be visible once the create button is toggled */}
                    <VendorCreationComponent />
                    <DataGrid
                        uri={"/inventory/Vendor/GetAll?"}
                        style={{
                            maxWidth: "800px",
                        }}
                        searchFields={["name"]}
                    >
                        <GridColumn
                            field="name"
                            title="Name:"
                            locked={true}
                            width={200}
                            cell={(props) => (
                                <td>
                                    <a
                                        href={`/inventory/vendor/${props.dataItem.id}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleRouteChange({
                                                uri: `/inventory/vendor/${props.dataItem.id}`,
                                                breadcrumbInfo: [
                                                    {
                                                        id: props.dataItem.id,
                                                        text: props.dataItem
                                                            .name,
                                                    },
                                                ],
                                            });
                                        }}
                                        className={"link"}
                                    >
                                        {props.dataItem.name}
                                    </a>
                                </td>
                            )}
                        />
                        <GridColumn
                            field="address.fullAddress"
                            title="Location:"
                            sortable={false}
                            width={400}
                            cell={(props) => (
                                <LinkCell
                                    {...props}
                                    uri={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                        props.dataItem.address.fullAddress
                                    )}`}
                                    text={props.dataItem.address.fullAddress}
                                    target="_blank"
                                />
                            )}
                        />
                        <GridColumn
                            width={180}
                            cell={(props) => (
                                <ActionButtonsCommandCell
                                    record={props.dataItem}
                                    dataId={props.dataItem.id}
                                    action={deleteVendor}
                                    EditComponent={vendorEditForm}
                                    viewPage={{
                                        uri: `/inventory/vendor/${props.dataItem.id}`,
                                        breadcrumbInfo: [
                                            {
                                                id: props.dataItem.id,
                                                text: props.dataItem.name,
                                            },
                                        ],
                                    }}
                                    requiredPermissionsView={[
                                        PermissionsEnum.ViewVendor,
                                    ]}
                                    requiredPermissionsEdit={[
                                        PermissionsEnum.UpdateVendor,
                                    ]}
                                    requiredPermissionsAction={[
                                        PermissionsEnum.DeleteVendor,
                                    ]}
                                />
                            )}
                        />
                    </DataGrid>
                </>
            ) : (
                <CenterLoader />
            )}
        </div>
    );
};

export default Dashboard;
