import React, { useContext } from "react";
import JobSummary from "./JobAccountingComponents/JobSummary";
import PaymentsGrid from "./JobAccountingComponents/PaymentsGrid";
import AccountingFiles from "./JobAccountingComponents/AccountingFiles";
import styles from "./JobAccounting.module.scss";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import PaymentProvider from "../../../../providers/Deprecated/Customer/PaymentProvider";

const JobAccounting = () => {
    const { job } = useContext(JobContext);

    return (
        <PaymentProvider>
            <span className={styles.SummaryDisbursementContainer}>
                <JobSummary />
                <span className={styles.PaymentAndDisbursementContainer}>
                    <PaymentsGrid />
                    <AccountingFiles jobId={job.id} />
                </span>
            </span>
        </PaymentProvider>
    );
};

export default JobAccounting;
