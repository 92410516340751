export const PermissionsEnum = {
    ViewStorageLocation: "E887B9AB-9F72-4B9D-99FF-8D11B0FD63A4",
    CreateStorageLocation: "69446473-77D3-42FB-9C6E-ABBE231D0980",
    UpdateStorageLocation: "4B585F82-B625-40F5-98F4-FDF6BA2281EC",
    DeleteStorageLocation: "D637D6B3-B5FB-42FF-9889-FBE5D3B118E5",

    ViewInventory: "B2D2BBA2-8CA4-4E27-A4C1-6F748BE3A7CE",
    CreateInventory: "0DA7EEF6-6522-4DB2-BFB8-7B2B9329C351",
    UpdateInventory: "3F75AA3C-CD13-4540-A584-4B907CCD309E",
    DeleteInventory: "582D8EF6-B435-4BAD-9EB4-CACC2283F66C",
    ReceiveInventory: "32FEDEE4-7FF7-45A1-84E0-50E911E63A0C",
    UnReceiveInventory: "1DAE4B56-D6D2-4512-8B45-24A629A41548",
    AllocateInventory: "2601FF99-10D3-47AA-B45D-F217BB1F1941",
    UnAllocateInventory: "F2E068C2-9675-438F-91C3-50DC977BA603",

    SetPayrollInterval: "B1B07ABA-E189-4DD7-81BE-B6DC845E9BB7",

    CreateGeneralTask: "881F5CC0-CB21-4449-BB8E-900FEDF7CCA8",
    ViewGeneralTask: "8FD7DC92-122D-4637-898C-42419B82F603",
    UpdateGeneralTask: "49844F5D-3412-4E1D-A3B8-F1A7F87C06A5",
    DeleteGeneralTask: "11EC23A4-65A4-4738-A063-89FC9A7AC4A6",

    GeneratePayroll: "0FB70DD4-D302-4046-91FF-746996CD9B14",

    CreateEmployee: "01000000-0000-0000-0000-000000000017",
    ViewEmployee: "01000000-0000-0000-0000-000000000018",
    UpdateEmployee: "01000000-0000-0000-0000-000000000019",
    DeleteEmployee: "01000000-0000-0000-0000-000000000020",
    ViewSocialSecurityNumber: "01000000-0000-0000-0000-000000001121",
    SetEmployeeAllowedTasks: "01000000-0000-0000-0000-000000001122",

    CreateUser: "01000000-0000-0000-0000-000000000145",
    ViewUser: "01000000-0000-0000-0000-000000000146",
    UpdateUser: "01000000-0000-0000-0000-000000000147",
    DeleteUser: "01000000-0000-0000-0000-000000000148",

    ViewDashboard: "01000000-0000-0000-0000-000000000166",
    ViewTrafficReport: "01000000-0000-0000-0000-000000000193",
    ViewMoneyDueReport: "01000000-0000-0000-0000-000000000200",

    CreateTool: "01000000-0000-0000-0000-000000000169",
    ViewTool: "01000000-0000-0000-0000-000000000170",
    UpdateTool: "01000000-0000-0000-0000-000000000171",
    DeleteTool: "01000000-0000-0000-0000-000000000172",

    CreateToolBrand: "01000000-0000-0000-0000-000000000173",
    ViewToolBrand: "01000000-0000-0000-0000-000000000174",
    UpdateToolBrand: "01000000-0000-0000-0000-000000000175",
    DeleteToolBrand: "01000000-0000-0000-0000-000000000176",

    CreateToolCategory: "01000000-0000-0000-0000-000000000177",
    ViewToolCategory: "01000000-0000-0000-0000-000000000178",
    UpdateToolCategory: "01000000-0000-0000-0000-000000000179",
    DeleteToolCategory: "01000000-0000-0000-0000-000000000180",

    CreateToolPurchaseFrom: "01000000-0000-0000-0000-000000000181",
    ViewToolPurchaseFrom: "01000000-0000-0000-0000-000000000182",
    UpdateToolPurchaseFrom: "01000000-0000-0000-0000-000000000183",
    DeleteToolPurchaseFrom: "01000000-0000-0000-0000-000000000184",

    CreateToolVoltage: "01000000-0000-0000-0000-000000000185",
    ViewToolVoltage: "01000000-0000-0000-0000-000000000186",
    UpdateToolVoltage: "01000000-0000-0000-0000-000000000187",
    DeleteToolVoltage: "01000000-0000-0000-0000-000000000188",

    CreateToolAmperage: "01000000-0000-0000-0000-000000000189",
    ViewToolAmperage: "01000000-0000-0000-0000-000000000190",
    UpdateToolAmperage: "01000000-0000-0000-0000-000000000191",
    DeleteToolAmperage: "01000000-0000-0000-0000-000000000192",

    CreateAccount: "01000000-0000-0000-0000-000000000157",
    ViewAccount: "01000000-0000-0000-0000-000000000158",
    UpdateAccount: "01000000-0000-0000-0000-000000000159",
    DeleteAccount: "01000000-0000-0000-0000-000000000160",

    CreateCustomization: "01000000-0000-0000-0000-000000000053",
    ViewCustomization: "01000000-0000-0000-0000-000000000054",
    UpdateCustomization: "01000000-0000-0000-0000-000000000055",
    DeleteCustomization: "01000000-0000-0000-0000-000000000056",

    CreateCustomer: "01000000-0000-0000-0000-000000000105",
    ViewCustomer: "01000000-0000-0000-0000-000000000106",
    UpdateCustomer: "01000000-0000-0000-0000-000000000107",
    DeleteCustomer: "01000000-0000-0000-0000-000000000108",

    CreatePhoneType: "01000000-0000-0000-0000-000000000001",
    ViewPhoneType: "01000000-0000-0000-0000-000000000002",
    UpdatePhoneType: "01000000-0000-0000-0000-000000000003",
    DeletePhoneType: "01000000-0000-0000-0000-000000000004",

    CreatePayroll: "01000000-0000-0000-0000-000000000021",
    ViewPayroll: "01000000-0000-0000-0000-000000000022",
    UpdatePayroll: "01000000-0000-0000-0000-000000000023",
    DeletePayroll: "01000000-0000-0000-0000-000000000024",
    MarkPayrollAsPaid: "01000000-0000-0000-0000-000000001125",

    CreateRole: "01000000-0000-0000-0000-000000000149",
    ViewRole: "01000000-0000-0000-0000-000000000150",
    UpdateRole: "01000000-0000-0000-0000-000000000151",
    DeleteRole: "01000000-0000-0000-0000-000000000152",

    CreateTransaction: "01000000-0000-0000-0000-000000000161",
    ViewTransaction: "01000000-0000-0000-0000-000000000162",
    UpdateTransaction: "01000000-0000-0000-0000-000000000163",
    DeleteTransaction: "01000000-0000-0000-0000-000000000164",
    ReconcileTransaction: "01000000-0000-0000-0000-000000001165",

    CreateItemType: "01000000-0000-0000-0000-000000000057",
    ViewItemType: "01000000-0000-0000-0000-000000000058",
    UpdateItemType: "01000000-0000-0000-0000-000000000059",
    DeleteItemType: "01000000-0000-0000-0000-000000000060",

    CreateJobDocumentLineItem: "01000000-0000-0000-0000-000000000109",
    ViewJobDocumentLineItem: "01000000-0000-0000-0000-000000000110",
    UpdateJobDocumentLineItem: "01000000-0000-0000-0000-000000000111",
    DeleteJobDocumentLineItem: "01000000-0000-0000-0000-000000000112",

    CreateSocialMedia: "01000000-0000-0000-0000-000000000005",
    ViewSocialMedia: "01000000-0000-0000-0000-000000000006",
    UpdateSocialMedia: "01000000-0000-0000-0000-000000000007",
    DeleteSocialMedia: "01000000-0000-0000-0000-000000000008",

    CreateLocation: "01000000-0000-0000-0000-000000000025",
    ViewLocation: "01000000-0000-0000-0000-000000000026",
    UpdateLocation: "01000000-0000-0000-0000-000000000027",
    DeleteLocation: "01000000-0000-0000-0000-000000000028",

    CreatePermission: "01000000-0000-0000-0000-000000000153",
    ViewPermission: "01000000-0000-0000-0000-000000000154",
    UpdatePermission: "01000000-0000-0000-0000-000000000155",
    DeletePermission: "01000000-0000-0000-0000-000000000156",

    CreateCategory: "01000000-0000-0000-0000-000000000061",
    ViewCategory: "01000000-0000-0000-0000-000000000062",
    UpdateCategory: "01000000-0000-0000-0000-000000000063",
    DeleteCategory: "01000000-0000-0000-0000-000000000064",

    CreateJobDocument: "01000000-0000-0000-0000-000000000113",
    ViewJobDocument: "01000000-0000-0000-0000-000000000114",
    UpdateJobDocument: "01000000-0000-0000-0000-000000000115",
    DeleteJobDocument: "01000000-0000-0000-0000-000000000116",

    CreatePaymentType: "01000000-0000-0000-0000-000000000009",
    ViewPaymentType: "01000000-0000-0000-0000-000000000010",
    UpdatePaymentType: "01000000-0000-0000-0000-000000000011",
    DeletePaymentType: "01000000-0000-0000-0000-000000000012",

    CreateIdentificationType: "01000000-0000-0000-0000-000000000029",
    ViewIdentificationType: "01000000-0000-0000-0000-000000000030",
    UpdateIdentificationType: "01000000-0000-0000-0000-000000000031",
    DeleteIdentificationType: "01000000-0000-0000-0000-000000000032",

    CreateVendor: "01000000-0000-0000-0000-000000000065",
    ViewVendor: "01000000-0000-0000-0000-000000000066",
    UpdateVendor: "01000000-0000-0000-0000-000000000067",
    DeleteVendor: "01000000-0000-0000-0000-000000000068",

    CreateJob: "01000000-0000-0000-0000-000000000117",
    ViewJob: "01000000-0000-0000-0000-000000000118",
    UpdateJob: "01000000-0000-0000-0000-000000000119",
    DeleteJob: "01000000-0000-0000-0000-000000000120",

    CreateCustomerContact: "01000000-0000-0000-0000-000000000121",
    ViewCustomerContact: "01000000-0000-0000-0000-000000000122",
    UpdateCustomerContact: "01000000-0000-0000-0000-000000000123",
    DeleteCustomerContact: "01000000-0000-0000-0000-000000000124",

    CreateMaritalStatus: "01000000-0000-0000-0000-000000000033",
    ViewMaritalStatus: "01000000-0000-0000-0000-000000000034",
    UpdateMaritalStatus: "01000000-0000-0000-0000-000000000035",
    DeleteMaritalStatus: "01000000-0000-0000-0000-000000000036",

    CreateReminder: "01000000-0000-0000-0000-000000000125",
    ViewReminder: "01000000-0000-0000-0000-000000000126",
    UpdateReminder: "01000000-0000-0000-0000-000000000127",
    DeleteReminder: "01000000-0000-0000-0000-000000000128",
    AdminReadAllReminders: "01000000-0000-0000-0000-000000001129",

    CreateBusinessInformation: "01000000-0000-0000-0000-000000000013",
    ViewBusinessInformation: "01000000-0000-0000-0000-000000000014",
    UpdateBusinessInformation: "01000000-0000-0000-0000-000000000015",
    DeleteBusinessInformation: "01000000-0000-0000-0000-000000000016",

    CreateLaborType: "01000000-0000-0000-0000-000000000037",
    ViewLaborType: "01000000-0000-0000-0000-000000000038",
    UpdateLaborType: "01000000-0000-0000-0000-000000000039",
    DeleteLaborType: "01000000-0000-0000-0000-000000000040",

    CreateVendorDocument: "01000000-0000-0000-0000-000000000077",
    ViewVendorDocument: "01000000-0000-0000-0000-000000000078",
    UpdateVendorDocument: "01000000-0000-0000-0000-000000000079",
    DeleteVendorDocument: "01000000-0000-0000-0000-000000000080",

    CreateCustomerPayment: "01000000-0000-0000-0000-000000000129",
    ViewCustomerPayment: "01000000-0000-0000-0000-000000000130",
    UpdateCustomerPayment: "01000000-0000-0000-0000-000000000131",
    DeleteCustomerPayment: "01000000-0000-0000-0000-000000000132",
    DisburseCustomerPayment: "01000000-0000-0000-0000-000000001133",

    ViewCommunicationType: "01000000-0000-0000-0000-000000000209",
    CreateCommunicationType: "01000000-0000-0000-0000-000000000210",
    UpdateCommunicationType: "01000000-0000-0000-0000-000000000211",
    DeleteCommunicationType: "01000000-0000-0000-0000-000000000212",

    CreateEmployeeStatus: "01000000-0000-0000-0000-000000000041",
    ViewEmployeeStatus: "01000000-0000-0000-0000-000000000042",
    UpdateEmployeeStatus: "01000000-0000-0000-0000-000000000043",
    DeleteEmployeeStatus: "01000000-0000-0000-0000-000000000044",

    CreateVendorContact: "01000000-0000-0000-0000-000000000081",
    ViewVendorContact: "01000000-0000-0000-0000-000000000082",
    UpdateVendorContact: "01000000-0000-0000-0000-000000000083",
    DeleteVendorContact: "01000000-0000-0000-0000-000000000084",

    CreateCustomerStatus: "01000000-0000-0000-0000-000000000133",
    ViewCustomerStatus: "01000000-0000-0000-0000-000000000134",
    UpdateCustomerStatus: "01000000-0000-0000-0000-000000000135",
    DeleteCustomerStatus: "01000000-0000-0000-0000-000000000136",

    CreateTaskCategory: "01000000-0000-0000-0000-000000000045",
    ViewTaskCategory: "01000000-0000-0000-0000-000000000046",
    UpdateTaskCategory: "01000000-0000-0000-0000-000000000047",
    DeleteTaskCategory: "01000000-0000-0000-0000-000000000048",

    CreateItem: "01000000-0000-0000-0000-000000000085",
    ViewItem: "01000000-0000-0000-0000-000000000086",
    UpdateItem: "01000000-0000-0000-0000-000000000087",
    DeleteItem: "01000000-0000-0000-0000-000000000088",

    CreateLead: "01000000-0000-0000-0000-000000000137",
    ViewLead: "01000000-0000-0000-0000-000000000138",
    UpdateLead: "01000000-0000-0000-0000-000000000139",
    DeleteLead: "01000000-0000-0000-0000-000000000140",

    ViewLeadSource: "01000000-0000-0000-0000-000000000201",
    CreateLeadSource: "01000000-0000-0000-0000-000000000202",
    UpdateLeadSource: "01000000-0000-0000-0000-000000000203",
    DeleteLeadSource: "01000000-0000-0000-0000-000000000204",

    ViewLeadCommunication: "01000000-0000-0000-0000-000000000205",
    CreateLeadCommunication: "01000000-0000-0000-0000-000000000206",
    UpdateLeadCommunication: "01000000-0000-0000-0000-000000000207",
    DeleteLeadCommunication: "01000000-0000-0000-0000-000000000208",

    ConvertLeadToCustomer: "01000000-0000-0000-0000-000000001141",

    CreateTask: "01000000-0000-0000-0000-000000000049",
    ViewTask: "01000000-0000-0000-0000-000000000050",
    UpdateTask: "01000000-0000-0000-0000-000000000051",
    DeleteTask: "01000000-0000-0000-0000-000000000052",

    CreateBillingTerm: "01000000-0000-0000-0000-000000000089",
    ViewBillingTerm: "01000000-0000-0000-0000-000000000090",
    UpdateBillingTerm: "01000000-0000-0000-0000-000000000091",
    DeleteBillingTerm: "01000000-0000-0000-0000-000000000092",

    CreateNote: "01000000-0000-0000-0000-000000000141",
    ViewNote: "01000000-0000-0000-0000-000000000142",
    UpdateNote: "01000000-0000-0000-0000-000000000143",
    DeleteNote: "01000000-0000-0000-0000-000000000144",

    CreateTimeClock: "01000000-0000-0000-0000-000000000194",
    ViewTimeClock: "01000000-0000-0000-0000-000000000195",
    UpdateTimeClock: "01000000-0000-0000-0000-000000000196",
    DeleteTimeClock: "01000000-0000-0000-0000-000000000197",
    TimeClockAdmin: "063AFE6C-C6E5-4AA8-AA9A-810DCDD3D8F3",
    CanActAsStationaryTimeClock: "01000000-0000-0000-0000-000000001198",

    CreateUnitOfMeasure: "01000000-0000-0000-0000-000000000093",
    ViewUnitOfMeasure: "01000000-0000-0000-0000-000000000094",
    UpdateUnitOfMeasure: "01000000-0000-0000-0000-000000000095",
    DeleteUnitOfMeasure: "01000000-0000-0000-0000-000000000096",

    ReadStatus: "5D7CD8E0-8DF3-4F0D-B67F-4324B009A22E",
    DeleteStatus: "ADE0FAB9-D34F-43B1-AFDB-B0B319B5386A",
    CreateStatus: "499385AA-9752-4251-8696-C1A8C38D5FD5",
    UpdateStatus: "F006A530-3AB8-42D2-B171-D8E19DE69973",

    CreateInventoryStatus: "01000000-0000-0000-0000-000000000097",
    ViewInventoryStatus: "01000000-0000-0000-0000-000000000098",
    UpdateInventoryStatus: "01000000-0000-0000-0000-000000000099",
    DeleteInventoryStatus: "01000000-0000-0000-0000-000000000100",

    CreateVendorPayment: "01000000-0000-0000-0000-000000000101",
    ViewVendorPayment: "01000000-0000-0000-0000-000000000102",
    UpdateVendorPayment: "01000000-0000-0000-0000-000000000103",
    DeleteVendorPayment: "01000000-0000-0000-0000-000000000104",
    DisburseVendorPayment: "01000000-0000-0000-0000-000000001105",

    ReadEventType: "CCE25EDD-8541-43DE-A93C-02784F5BFA99",
    DeleteEventType: "C2F99600-AE89-49E9-8D7D-0A98BC6E1E15",
    CreateEventType: "95F0CD36-6E42-401F-9370-5C58AE332AAB",
    UpdateEventType: "39FCF476-26B4-415B-8EF2-AE50AA9DD45D",

    ReadCalendarEvent: "3BB52E9F-E156-4473-A6C2-3843F648662C",
    CreateCalendarEvent: "BEC0D517-D988-40E6-AE8F-4CF48B579C95",
    UpdateCalendarEvent: "0E45AA4A-2A98-486C-B3D2-D29E6B2CF99F",
    DeleteCalendarEvent: "02F8BACF-070C-4A71-BF10-DDDA79E716AE",
};
