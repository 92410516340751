import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import clone from "just-clone";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormRemoteDropDown } from "../../../../components/Deprecated/FormComponents";

const AddItem = ({ visible, setVisible, addNew, addItemIndex }) => {
    const [dataItem, setDataItem] = useState({});
    const [dropDownValid, setDropDownValid] = useState(true);

    /**
     * @function addItem
     * @description Add the selected item to the grid, and close the dialog
     * @returns {void}
     */
    const addItem = () => {
        if (!dataItem.id) {
            setDropDownValid(false);
            return;
        }

        const lineItemCopy = clone(dataItem);

        const formattedItem = {
            isDetailItem: lineItemCopy.isDetailItem,
            isPackage: lineItemCopy.isPackage,
            description: lineItemCopy.baseDescription,
            expanded: false,
            item: lineItemCopy,
            price: lineItemCopy.retailPrice,
            total: clone(lineItemCopy.retailPrice),
            quantity: 1,
            type: 0,
        };

        addNew(formattedItem, addItemIndex);
        setVisible(false);
    };

    const onItemChange = (e) => {
        setDataItem(e.target.value);
        setDropDownValid(true);
    };

    return (
        <div>
            {visible && (
                <ResponsiveDialog
                    title={"Add Item"}
                    onClose={() => setVisible(false)}
                    size={"small"}
                >
                    <FormRemoteDropDown
                        url={"/Customer/Job/Item/GetAll?"}
                        onChange={onItemChange}
                        valid={dropDownValid}
                        validationMessage={"Please select an item"}
                        label={"Select an Item..."}
                        style={{ width: "100%" }}
                        initialFilters={[
                            {
                                field: "IsActive",
                                operator: "eq",
                                value: true,
                            },
                            {
                                field: "IsStockOrSupplyItem",
                                operator: "eq",
                                value: false,
                            },
                        ]}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "3px",
                            marginTop: "15px",
                        }}
                    >
                        <Button
                            themeColor={"success"}
                            fillMode={"solid"}
                            rounded={"medium"}
                            style={{
                                width: "80px",
                            }}
                            icon={"k-icon k-i-plus"}
                            onClick={addItem}
                        >
                            Add
                        </Button>
                        <Button
                            themeColor={"primary"}
                            fillMode={"solid"}
                            rounded={"medium"}
                            onClick={() => setVisible(false)}
                            style={{
                                width: "70px",
                                padding: "5px",
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </ResponsiveDialog>
            )}
        </div>
    );
};

export default AddItem;
