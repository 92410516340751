import React from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import styles from "./UserDetails.module.scss";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";
import { DataGrid } from "../../../../components/Grids/DataGrid";

const UserDashboardPage = () => {
    return (
        <div className={styles.UserDetailsContainer}>
            <DataGrid
                uri={"/Authentication/User/GetAll?"}
                initialSort={[
                    {
                        field: "firstName",
                        dir: "asc",
                    },
                ]}
            >
                <GridColumn
                    width={350}
                    field="primaryEmail"
                    title="Email:"
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`mailto:${props.dataItem.email}`}
                            text={props.dataItem.email}
                        />
                    )}
                />
                <GridColumn
                    width={250}
                    field="name"
                    title="Name:"
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Admin/Security/Users/${props.dataItem.id}`}
                        />
                    )}
                />
                <GridColumn
                    width={85}
                    cell={(props) => (
                        <ActionButtonsCommandCell
                            record={props.dataItem}
                            dataId={props.dataItem.id}
                            viewPage={{
                                uri: `/Admin/Security/Users/${props.dataItem.id}`,
                                breadcrumbInfo: [
                                    {
                                        id: props.dataItem.id,
                                        text: props.dataItem.name,
                                    },
                                ],
                            }}
                            requiredPermissionsView={[PermissionsEnum.ViewUser]}
                        />
                    )}
                />
            </DataGrid>
        </div>
    );
};

export default UserDashboardPage;
