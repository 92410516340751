import React, { useContext } from "react";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import FormField from "../../../../components/Deprecated/StylingField";

const JobDetails = () => {
    const { job } = useContext(JobContext);

    return (
        job.id && (
            <div className={"JustifyLeftAlignLeft LargeGap"}>
                <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
                    <FormField label="Name" value={job.name} />
                    <FormField
                        label="Address"
                        value={job.address.fullAddress}
                    />
                    <FormField
                        label="Displayed Business Information"
                        value={job.businessInformation?.nickName}
                    />
                    <FormField label="Description" value={job.description} />
                </span>
                <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
                    <FormField
                        label="Primary Contact"
                        value={job.primaryContact?.combinedName}
                    />
                    {job?.primaryContact?.email && (
                        <FormField
                            label="Email"
                            value={job.primaryContact.email}
                        />
                    )}
                    {job?.primaryContact?.phone && (
                        <FormField
                            label="Phone"
                            value={`(${job.primaryContact.phone.phoneNumberType.name}) ${job.primaryContact.phone.phoneNumber}`}
                        />
                    )}
                </span>
                <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
                    <FormField
                        label="Secondary Contact"
                        value={job?.secondaryContact?.combinedName}
                    />
                    {job?.secondaryContact?.email && (
                        <FormField
                            label="Email"
                            value={job.secondaryContact.email}
                        />
                    )}
                    {job?.secondaryContact?.phone && (
                        <FormField
                            label="Phone"
                            value={`(${job.secondaryContact.phone.phoneNumberType.name}) ${job.secondaryContact.phone.phoneNumber}`}
                        />
                    )}
                </span>
            </div>
        )
    );
};

export default JobDetails;
