import * as React from "react";
import { useContext } from "react";
import { SchedulerViewSlot } from "@progress/kendo-react-scheduler";
import { SchedulerContext } from "../../../../providers/Deprecated/Customer/SchedulerProvider";

export const CustomSchedulerViewSlot = (props) => {
    const schedulerContext = useContext(SchedulerContext);

    if (
        (schedulerContext.calendarType === "work week" ||
            schedulerContext.calendarType === "2 week work week") &&
        !props.isWorkDay
    ) {
        return;
    }

    return (
        <SchedulerViewSlot
            id={props.id}
            {...props}
            style={{
                ...props.style,
                minHeight: "120px",
            }}
        />
    );
};
