import React, { useCallback, useEffect, useState } from "react";
import { processTreeViewItems } from "@progress/kendo-react-treeview";
import {
    Button,
    Toolbar,
    ToolbarItem,
    ToolbarSeparator,
    ToolbarSpacer,
} from "@progress/kendo-react-buttons";
import { TextBox } from "@progress/kendo-react-inputs";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { useModal } from "../../../../hooks/useModal";
import {
    createItemCategory,
    deleteItemCategory,
    getAllCategories,
    moveItemCategory,
    updateItemCategoryName,
} from "../../../../services/Deprecated/inventory/itemCategoryService";
import { getAllItemsByCategory } from "../../../../services/Deprecated/inventory/itemService";
import { DetailItemModal } from "../../Item/DetailItems/Components/DetailItemModal";
import ItemCreationComponent from "../../Item/Components/ItemCreateForm";
import { ItemPackageModal } from "../../Item/Packages/Components/ItemPackageModal";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import ConfirmDialogue from "../../../../components/Deprecated/ConfirmDialogue";
import AlertModal from "../../../../components/Deprecated/AlertModal";
import { DragAndDropTreeView } from "../../../../components/DragAndDropTreeView";

const ItemCategoryNameModal = ({
    action,
    defaultValue,
    visible,
    onConfirm,
    onCancel,
}) => {
    const [categoryName, setCategoryName] = useState("");

    useEffect(() => {
        setCategoryName(defaultValue);
    }, [defaultValue]);

    const handleCategoryNameChange = useCallback(
        (event) => {
            setCategoryName(event.target.value);
        },
        [setCategoryName]
    );

    const onSubmit = () => {
        onConfirm(action, categoryName);
        setCategoryName(defaultValue);
    };

    // updating text in modal based on modal action
    const title = action === "rename" ? "Rename Category" : "Add Category";
    const actionText =
        action === "rename" ? "Updated Category Name: " : "New Category Name:";

    return (
        <>
            {visible && (
                <ResponsiveDialog
                    title={title}
                    onClose={onCancel}
                    size={"small"}
                >
                    <p
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            margin: "10px",
                        }}
                    >
                        {actionText}
                    </p>
                    <TextBox
                        value={categoryName}
                        onChange={handleCategoryNameChange}
                        placeholder="Name"
                    />
                    <Button
                        themeColor={"secondary"}
                        fillMode={"solid"}
                        rounded={"medium"}
                        onClick={onCancel}
                        style={{ width: "100px", margin: "10px" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        themeColor={"primary"}
                        fillMode={"solid"}
                        rounded={"medium"}
                        onClick={() => onSubmit()}
                        style={{ width: "100px", margin: "10px" }}
                    >
                        Submit
                    </Button>
                </ResponsiveDialog>
            )}
        </>
    );
};

const ItemCategoryTree = ({
    onItemClick,
    select,
    selectedItem,
    clearSelection,
    onCategoryClick,
    showToolbar = true,
}) => {
    const [categoryData, setCategoryData] = useState([]);
    const {
        bool: categoryConfirmDialogueVisible,
        toggle: toggleCategoryConfirmDialogueVisible,
    } = useToggle();
    const {
        bool: categoryNameDialogueVisible,
        toggle: toggleCategoryNameDialogueVisible,
    } = useToggle();
    const [categoryNameModalAction, setCategoryNameModalAction] = useState("");
    const [categoryNameModalDefaultValue, setCategoryNameModalDefaultValue] =
        useState("");
    const [expand, setExpand] = useState({
        ids: [],
        idField: "id",
    });
    const [alertState, setAlertState] = useState({
        visible: false,
        message: "",
    });

    const createItemModal = useModal();
    const detailItemModal = useModal();
    const itemPackageModal = useModal();
    const onExpandChange = (event) => {
        let ids = expand.ids.slice();
        const index = ids.indexOf(event.item.text);

        index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
        setExpand({ ids, idField: "text" });
    };

    const loadTree = useCallback(() => {
        getAllCategories().then(setCategoryData);
    }, [getAllCategories, setCategoryData]);

    const onNewRootCategoryClick = useCallback(() => {
        setCategoryNameModalAction("root");
        setCategoryNameModalDefaultValue("");
        toggleCategoryNameDialogueVisible();
    }, [
        setCategoryNameModalAction,
        toggleCategoryNameDialogueVisible,
        setCategoryNameModalDefaultValue,
        selectedItem,
    ]);

    const onNewSubCategoryClick = useCallback(() => {
        setCategoryNameModalAction("sub");
        setCategoryNameModalDefaultValue("");
        toggleCategoryNameDialogueVisible();
    }, [
        setCategoryNameModalAction,
        toggleCategoryNameDialogueVisible,
        setCategoryNameModalDefaultValue,
        selectedItem,
    ]);

    const onRenameCategoryClick = useCallback(() => {
        setCategoryNameModalAction("rename");
        setCategoryNameModalDefaultValue(selectedItem?.name);
        toggleCategoryNameDialogueVisible();
    }, [
        setCategoryNameModalAction,
        toggleCategoryNameDialogueVisible,
        setCategoryNameModalDefaultValue,
        selectedItem,
    ]);

    const onDeleteCategoryClick = useCallback(() => {
        getAllItemsByCategory(selectedItem.id).then((res) => {
            if (res.data.length > 0) {
                setAlertState({
                    visible: true,
                    message: "Category contains items and cannot be deleted.",
                });
            } else {
                toggleCategoryConfirmDialogueVisible();
            }
        });
    }, [selectedItem]);

    // checks what action is submitted for a category name modal submission & sends to service layer
    const onCategoryNameSubmit = useCallback(
        (action, submittedCategoryName) => {
            if (action === "root") {
                createItemCategory(submittedCategoryName, null).then((_) =>
                    loadTree()
                );
            } else if (action === "sub") {
                createItemCategory(submittedCategoryName, selectedItem.id).then(
                    (_) => loadTree()
                );
            } else if (action === "rename") {
                updateItemCategoryName(
                    submittedCategoryName,
                    selectedItem.id,
                    selectedItem.parentCategoryId
                ).then((_) => loadTree());
            }
            toggleCategoryNameDialogueVisible();
        },
        [toggleCategoryNameDialogueVisible, selectedItem, loadTree]
    );

    const onCategoryDelete = useCallback(() => {
        getAllItemsByCategory(selectedItem.id)
            .then((items) => {
                const itemsInCategory = items.some(
                    (item) => item.id === selectedItem.id
                );
                if (itemsInCategory.length > 0) {
                    setAlertState({
                        visible: true,
                        message:
                            "Category contains items and cannot be deleted.",
                    });
                } else {
                    deleteItemCategory(selectedItem.id).then(() => {
                        toggleCategoryConfirmDialogueVisible();
                        clearSelection();
                        loadTree();
                    });
                }
            })
            .catch((error) => {
                console.error("Failed to fetch items", error);
            });
        deleteItemCategory(selectedItem.id).then((_) => {
            toggleCategoryConfirmDialogueVisible();
            clearSelection();
            loadTree();
        });
    }, [
        toggleCategoryConfirmDialogueVisible,
        selectedItem,
        deleteItemCategory,
        loadTree,
    ]);
    // Use the useEffect hook to fetch item data from the API when the component mounts
    //
    useEffect(() => {
        loadTree();
    }, [loadTree]);

    /**
     * @function onDrop
     * @description Sends API request to the backend with the updated Item Category data
     * @param event.destination {Object} - The destination item
     * @param event.source {Object} - The source item
     * @param event.operation {string} - The operation being performed
     * @return {void}
     */
    const onDrop = async (event) => {
        await moveItemCategory({
            sourceId: event.source.data.id,
            destinationId: event.destination.data.id,
            operation: event.operation,
        });
    };

    /**
     * @function recursivelyUnselectItems
     * @description Recursively unselects all items in the categoryData
     * This could be optimized in the future.
     * @param data {Array} - The data source of the TreeView
     * @return {void}
     */
    useEffect(() => {
        const recursivelyUnselectItems = (data) => {
            data.forEach((item) => {
                if (item.items) {
                    recursivelyUnselectItems(item.items);
                }
                item.selected = false;
            });
        };

        recursivelyUnselectItems(categoryData);

        setCategoryData(categoryData);
    }, [select, expand]);

    const onItemCategoryClick = (event) => {
        onCategoryClick(event.item);
    };
    return (
        <div>
            {showToolbar && (
                <Toolbar>
                    <ToolbarItem>
                        <Button
                            icon={"plus"}
                            themeColor={"primary"}
                            onClick={onNewRootCategoryClick}
                        >
                            Add Root Category
                        </Button>
                    </ToolbarItem>
                    {selectedItem && selectedItem && selectedItem.name ? (
                        <>
                            <ToolbarItem>
                                <Button
                                    icon={"plus"}
                                    themeColor={"primary"}
                                    onClick={onNewSubCategoryClick}
                                >
                                    Add Subcategory
                                </Button>
                            </ToolbarItem>
                            <ToolbarItem>
                                <Button
                                    icon={"pencil"}
                                    themeColor={"info"}
                                    onClick={onRenameCategoryClick}
                                >
                                    Rename Category
                                </Button>
                            </ToolbarItem>
                            <ToolbarItem>
                                <Button
                                    icon={"trash"}
                                    themeColor={"error"}
                                    onClick={onDeleteCategoryClick}
                                >
                                    Delete Category
                                </Button>
                            </ToolbarItem>
                            <ToolbarItem>
                                {/* This is the confirmation toggle, this will be visible once the create button is toggled */}
                                <Button
                                    themeColor={"success"}
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    icon={"k-icon k-i-plus"}
                                    onClick={() => createItemModal.open()}
                                >
                                    Add Item(s) to Category
                                </Button>
                                <ItemCreationComponent
                                    selectedItem={selectedItem}
                                    {...createItemModal}
                                />
                            </ToolbarItem>
                            <ToolbarItem>
                                <DetailItemModal
                                    {...detailItemModal}
                                    categoryId={selectedItem.id}
                                />
                                <Button
                                    themeColor={"success"}
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    icon={"k-icon k-i-plus"}
                                    onClick={() => detailItemModal.open()}
                                >
                                    Add Detail Item to Category
                                </Button>
                            </ToolbarItem>
                            <ToolbarItem>
                                <ItemPackageModal
                                    {...itemPackageModal}
                                    record={{
                                        itemCategory: selectedItem,
                                    }}
                                />
                                <ActionButton
                                    icon={"plus"}
                                    permissions={[PermissionsEnum.CreateItem]}
                                    buttonText={"Add Package to Category"}
                                    theme={"success"}
                                    onClick={() => itemPackageModal.open()}
                                />
                            </ToolbarItem>
                            <ToolbarSpacer />
                            <ToolbarSeparator />
                        </>
                    ) : null}
                </Toolbar>
            )}
            <ConfirmDialogue
                visible={categoryConfirmDialogueVisible}
                onCancel={toggleCategoryConfirmDialogueVisible}
                onConfirm={() => onCategoryDelete()}
            />
            <ItemCategoryNameModal
                action={categoryNameModalAction}
                defaultValue={categoryNameModalDefaultValue}
                visible={categoryNameDialogueVisible}
                onCancel={toggleCategoryNameDialogueVisible}
                onConfirm={onCategoryNameSubmit}
            />
            <AlertModal
                message={alertState.message}
                visible={alertState.visible}
                onClose={() => setAlertState({ ...alertState, visible: false })}
            />
            <div style={{ maxHeight: 420, overflow: "auto" }}>
                <DragAndDropTreeView
                    data={processTreeViewItems(categoryData, {
                        select: select,
                        expand: expand,
                    })}
                    setData={setCategoryData}
                    dragCluePos={{
                        y: 180,
                        x: 250,
                    }}
                    subField={"items"}
                    expandIcons={true}
                    onExpandChange={onExpandChange}
                    aria-multiselectable={false}
                    onItemClick={onItemClick}
                    onItemCategoryClick={onItemCategoryClick}
                    onDrop={onDrop}
                />
            </div>
        </div>
    );
};

export default ItemCategoryTree;
