import React, { useContext, useEffect } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import styles from "./PaymentsGrid.module.scss";
import { JobContext } from "../../../../../providers/Deprecated/Customer/JobProvider";
import { JobDocumentContext } from "../../../../../providers/Deprecated/Customer/JobDocumentProvider";
import { PaymentContext } from "../../../../../providers/Deprecated/Customer/PaymentProvider";
import { useModal } from "../../../../../hooks/useModal";
import JobPaymentServices from "../../../../../services/Deprecated/customer/JobPaymentServices";
import {
    CustomCell,
    CustomDateCell,
} from "../../../../../components/Deprecated/CustomGridCells";
import { PaymentsHelper } from "../../../../../resources/Deprecated/PaymentHelpers/paymentsHelper";
import ActionButtonsCommandCell from "../../../../../components/Deprecated/ActionButtonsCommandCell";
import PaymentFormModal from "../../../Payments/Components/PaymentFormModal";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import TransferBtn from "../../../Payments/Components/TransferBtn";
import TransferModal from "../../../Payments/Components/TransferModal";
import PaymentCreateBtn from "../../../Payments/Components/PaymentCreateBtn";
import JobApplyPaymentModal from "./JobApplyPaymentModal";
import { DataGrid } from "../../../../../components/Grids/DataGrid";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";

const initFilter = {
    filters: [
        {
            field: "jobDocumentId",
            operator: "eq",
            value: null,
        },
    ],
};

const PaymentsGrid = () => {
    const jobContext = useContext(JobContext);
    const { refreshJobDocData } = useContext(JobDocumentContext);
    const paymentContext = useContext(PaymentContext);
    const applyPaymentModal = useModal();

    //? Let the context know we are working with job payments
    useEffect(() => {
        paymentContext.setIsJobPayment(true);
    }, []);

    const handlePaymentDelete = async (id) => {
        await JobPaymentServices.delete(id);
        refreshJobDocData();
    };

    /**
     * @function onApplyPaymentClick
     * @description Opens the apply payment modal and sets the payment id in the context
     * @param {object} props.dataItem - The payment object
     * @returns {void}
     */
    const onApplyPaymentClick = (props) => {
        paymentContext.setDisbursingPaymentId(props.dataItem.id);
        applyPaymentModal.open();
    };

    return (
        <Card style={{ width: "100%" }}>
            <CardHeader>
                <CardTitle>Job Payments</CardTitle>
            </CardHeader>
            <CardBody className={styles.PaymentGridBody}>
                <TransferModal />
                <PaymentCreateBtn />
                <JobApplyPaymentModal {...applyPaymentModal} />
                <DataGrid
                    uri={`/Customer/Job/Billing/GetAll?jobId=${jobContext.job.id}&`}
                    initialFilter={initFilter}
                >
                    <GridColumn
                        field="type"
                        title="Type:"
                        locked={true}
                        width={200}
                        className={"text-overflow"}
                    />
                    <GridColumn
                        field="details"
                        title="Details:"
                        width={300}
                        className={"text-overflow"}
                    />
                    <GridColumn
                        field="date"
                        title="Date:"
                        width={100}
                        cell={(props) => (
                            <CustomDateCell
                                {...props}
                                data={props.dataItem.date}
                            />
                        )}
                    />
                    <GridColumn
                        field="employeeWhoCreated.name"
                        title="Created By:"
                        width={200}
                    />
                    <GridColumn
                        field="total.amount"
                        title="Amount:"
                        width={150}
                        format="{0:c}"
                    />
                    <GridColumn
                        field="remainder"
                        title="Applied:"
                        width={150}
                        cell={(props) => (
                            <CustomCell {...props}>
                                {PaymentsHelper.percentageAndDisbursedDisplay(
                                    props
                                )}
                            </CustomCell>
                        )}
                    />
                    <GridColumn
                        width={275}
                        cell={(props) =>
                            //? Is the remainder less than the amount? If so, this payment has been disbursed.
                            props.dataItem.remainder.amount <
                            props.dataItem.total.amount ? (
                                <ActionButtonsCommandCell
                                    record={props.dataItem}
                                    dataId={props.dataItem.id}
                                    EditComponent={PaymentFormModal}
                                    requiredPermissionsEdit={[
                                        PermissionsEnum.UpdateCustomerPayment,
                                    ]}
                                    additionalButtons={[
                                        <ActionButton
                                            key={0}
                                            onClick={() =>
                                                onApplyPaymentClick(props)
                                            }
                                            buttonText="Apply"
                                            permissions={[
                                                PermissionsEnum.DisburseCustomerPayment,
                                            ]}
                                        />,
                                        <TransferBtn
                                            dataItem={props.dataItem}
                                            key={1}
                                        />,
                                    ]}
                                />
                            ) : (
                                <ActionButtonsCommandCell
                                    record={props.dataItem}
                                    dataId={props.dataItem.id}
                                    action={handlePaymentDelete}
                                    EditComponent={PaymentFormModal}
                                    requiredPermissionsEdit={[
                                        PermissionsEnum.UpdateCustomerPayment,
                                    ]}
                                    requiredPermissionsAction={[
                                        PermissionsEnum.DeleteCustomerPayment,
                                    ]}
                                    additionalButtons={[
                                        <ActionButton
                                            key={0}
                                            onClick={() =>
                                                onApplyPaymentClick(props)
                                            }
                                            buttonText="Apply"
                                            permissions={[
                                                PermissionsEnum.DisburseCustomerPayment,
                                            ]}
                                        />,
                                        <TransferBtn
                                            dataItem={props.dataItem}
                                            key={1}
                                        />,
                                    ]}
                                />
                            )
                        }
                    />
                </DataGrid>
            </CardBody>
        </Card>
    );
};

export default PaymentsGrid;
