import React, { useContext, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import styles from "./JobSummary.module.scss";
import PaymentDisbursement from "./PaymentDisbursement";
import InvoiceSummary from "./InvoiceSummary";
import LaborSummary from "./LaborSummary";
import AllocationSummary from "./AllocationSummary";
import VendorDocumentSummary from "./VendorDocumentSummary";
import { PaymentContext } from "../../../../../providers/Deprecated/Customer/PaymentProvider";
import { CurrentSelectedSummary } from "../../../../../resources/Enums/CurrentSelectedSummary";
import { formatUSDCurrency } from "../../../../../resources/Deprecated/currencyHelper";
import { CenterLoader } from "../../../../../components/Deprecated/CenterLoader";

const JobSummary = () => {
    const { jobSummary } = useContext(PaymentContext);
    const [selected, setSelected] = useState(CurrentSelectedSummary.Payments);

    return jobSummary ? (
        <span className={styles.SummaryContainer}>
            <Card className={styles.SummaryCard}>
                <CardHeader>
                    <CardTitle>Job Summary</CardTitle>
                </CardHeader>
                <CardBody>
                    <GridLayout
                        cols={[
                            {
                                width: 175,
                            },
                            {
                                width: 25,
                            },
                            {
                                width: "auto",
                            },
                            {
                                width: "auto",
                            },
                        ]}
                    >
                        {/* SALES ORDER */}
                        <GridLayoutItem col={1} row={1} colSpan={2}>
                            <p className={styles.Title}>SALES ORDER TOTAL</p>
                        </GridLayoutItem>
                        <GridLayoutItem col={3} row={1} colSpan={1}>
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.salesOrder.total < 0
                                            ? "red"
                                            : "",
                                }}
                            >
                                {formatUSDCurrency(jobSummary.salesOrder.total)}
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem col={4} row={1} colSpan={1}>
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.salesOrder.total < 0
                                            ? "red"
                                            : "",
                                }}
                            >
                                {jobSummary.salesOrder.percentage}%
                            </p>
                        </GridLayoutItem>
                        {/* INVOICE */}
                        <GridLayoutItem
                            col={1}
                            row={2}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.Title}
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Invoice)
                                }
                            >
                                INVOICE TOTAL
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={2}
                            row={2}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Invoice)
                                }
                                className={
                                    "k-icon k-i-arrow-chevron-right " +
                                    styles.ArrowStyle
                                }
                            />
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={3}
                            row={2}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.invoice.total < 0
                                            ? "red"
                                            : "",
                                }}
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Invoice)
                                }
                            >
                                {formatUSDCurrency(jobSummary.invoice.total)}
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={4}
                            row={2}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.invoice.total < 0
                                            ? "red"
                                            : "",
                                }}
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Invoice)
                                }
                            >
                                {jobSummary.invoice.percentage}%
                            </p>
                        </GridLayoutItem>
                        {/* PAYMENTS RECEIVED */}
                        <GridLayoutItem
                            col={1}
                            row={3}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.Title}
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Payments)
                                }
                            >
                                PAYMENTS RECEIVED
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={2}
                            row={3}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Payments)
                                }
                                className={
                                    "k-icon k-i-arrow-chevron-right " +
                                    styles.ArrowStyle
                                }
                            />
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={3}
                            row={3}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.paymentsReceived.total < 0
                                            ? "red"
                                            : "",
                                }}
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Payments)
                                }
                            >
                                {formatUSDCurrency(
                                    jobSummary.paymentsReceived.total
                                )}
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={4}
                            row={3}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.paymentsReceived.total < 0
                                            ? "red"
                                            : "",
                                }}
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Payments)
                                }
                            >
                                {jobSummary.paymentsReceived.percentage}%
                            </p>
                        </GridLayoutItem>
                        {/* CREDITS APPLIED */}
                        <GridLayoutItem col={1} row={4} colSpan={2}>
                            <p className={styles.Title}>CREDITS APPLIED</p>
                        </GridLayoutItem>
                        <GridLayoutItem col={3} row={4} colSpan={1}>
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.creditsApplied.total < 0
                                            ? "red"
                                            : "",
                                }}
                            >
                                {formatUSDCurrency(
                                    jobSummary.creditsApplied.total
                                )}
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem col={4} row={4} colSpan={1}>
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.creditsApplied.total < 0
                                            ? "red"
                                            : "",
                                }}
                            >
                                {jobSummary.creditsApplied.percentage}%
                            </p>
                        </GridLayoutItem>
                        {/* PURCHASE ORDER */}
                        <GridLayoutItem
                            col={1}
                            row={5}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.Title}
                                onClick={() =>
                                    setSelected(
                                        CurrentSelectedSummary.VendorDocument
                                    )
                                }
                            >
                                PURCHASE ORDER TOTAL
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={2}
                            row={5}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                onClick={() =>
                                    setSelected(
                                        CurrentSelectedSummary.VendorDocument
                                    )
                                }
                                className={
                                    "k-icon k-i-arrow-chevron-right " +
                                    styles.ArrowStyle
                                }
                            />
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={3}
                            row={5}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.purchaseOrder.total < 0
                                            ? "red"
                                            : "",
                                }}
                                onClick={() =>
                                    setSelected(
                                        CurrentSelectedSummary.VendorDocument
                                    )
                                }
                            >
                                {formatUSDCurrency(
                                    jobSummary.purchaseOrder.total
                                )}
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={4}
                            row={5}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.purchaseOrder.total < 0
                                            ? "red"
                                            : "",
                                }}
                                onClick={() =>
                                    setSelected(
                                        CurrentSelectedSummary.VendorDocument
                                    )
                                }
                            >
                                {jobSummary.purchaseOrder.percentage}%
                            </p>
                        </GridLayoutItem>
                        {/* ALLOCATED ITEMS */}
                        <GridLayoutItem
                            col={1}
                            row={6}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.Title}
                                onClick={() =>
                                    setSelected(
                                        CurrentSelectedSummary.Allocated
                                    )
                                }
                            >
                                ALLOCATED ITEMS TOTAL
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={2}
                            row={6}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                onClick={() =>
                                    setSelected(
                                        CurrentSelectedSummary.Allocated
                                    )
                                }
                                className={
                                    "k-icon k-i-arrow-chevron-right " +
                                    styles.ArrowStyle
                                }
                            />
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={3}
                            row={6}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.allocatedItems.total < 0
                                            ? "red"
                                            : "",
                                }}
                                onClick={() =>
                                    setSelected(
                                        CurrentSelectedSummary.Allocated
                                    )
                                }
                            >
                                {formatUSDCurrency(
                                    jobSummary.allocatedItems.total
                                )}
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={4}
                            row={6}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.allocatedItems.total < 0
                                            ? "red"
                                            : "",
                                }}
                                onClick={() =>
                                    setSelected(
                                        CurrentSelectedSummary.Allocated
                                    )
                                }
                            >
                                {jobSummary.allocatedItems.percentage}%
                            </p>
                        </GridLayoutItem>
                        {/* LABOR */}
                        <GridLayoutItem
                            col={1}
                            row={7}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.Title}
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Labor)
                                }
                            >
                                JOB LABOR TOTAL
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={2}
                            row={7}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Labor)
                                }
                                className={
                                    "k-icon k-i-arrow-chevron-right " +
                                    styles.ArrowStyle
                                }
                            />
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={3}
                            row={7}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.labor.total < 0 ? "red" : "",
                                }}
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Labor)
                                }
                            >
                                {formatUSDCurrency(jobSummary.labor.total)}
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={4}
                            row={7}
                            colSpan={1}
                            className={styles.Clickable}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.labor.total < 0 ? "red" : "",
                                }}
                                onClick={() =>
                                    setSelected(CurrentSelectedSummary.Labor)
                                }
                            >
                                {jobSummary.labor.percentage}%
                            </p>
                        </GridLayoutItem>
                        {/* BALANCE DUE */}
                        <GridLayoutItem
                            col={1}
                            row={8}
                            colSpan={2}
                            className={styles.Divider}
                        >
                            <p className={styles.Title}>BALANCE DUE</p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={3}
                            row={8}
                            colSpan={1}
                            className={styles.Divider}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.balanceDue.total < 0
                                            ? "red"
                                            : "",
                                }}
                            >
                                {formatUSDCurrency(jobSummary.balanceDue.total)}
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={4}
                            row={8}
                            colSpan={1}
                            className={styles.Divider}
                        >
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.balanceDue.total < 0
                                            ? "red"
                                            : "",
                                }}
                            >
                                {jobSummary.balanceDue.percentage}%
                            </p>
                        </GridLayoutItem>
                        {/* GROSS PROFIT */}
                        <GridLayoutItem col={1} row={9} colSpan={2}>
                            <p className={styles.Title}>GROSS PROFIT</p>
                        </GridLayoutItem>
                        <GridLayoutItem col={3} row={9} colSpan={1}>
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.grossProfit.total < 0
                                            ? "red"
                                            : "green",
                                }}
                            >
                                {formatUSDCurrency(
                                    jobSummary.grossProfit.total
                                )}
                            </p>
                        </GridLayoutItem>
                        <GridLayoutItem col={4} row={9} colSpan={1}>
                            <p
                                className={styles.TechnicalData}
                                style={{
                                    color:
                                        jobSummary.grossProfit.total < 0
                                            ? "red"
                                            : "green",
                                }}
                            >
                                {jobSummary.grossProfit.percentage}%
                            </p>
                        </GridLayoutItem>
                    </GridLayout>
                </CardBody>
            </Card>
            {/* How we determine what modal to show */}
            {selected === CurrentSelectedSummary.Invoice && <InvoiceSummary />}
            {selected === CurrentSelectedSummary.Allocated && (
                <AllocationSummary />
            )}
            {selected === CurrentSelectedSummary.Labor && <LaborSummary />}
            {selected === CurrentSelectedSummary.VendorDocument && (
                <VendorDocumentSummary />
            )}
            {selected === CurrentSelectedSummary.Payments && (
                <PaymentDisbursement />
            )}
        </span>
    ) : (
        <CenterLoader />
    );
};

export default JobSummary;
