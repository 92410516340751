import * as React from "react";
import {useEffect} from "react";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {Error, Hint} from "@progress/kendo-react-labels";
import {filterBy} from "@progress/kendo-data-query";

/**
 * @function ComboBoxInput
 * @description A form input component that represents a ComboBox
 * @param fieldRenderProps.validationMessage {string} - The validation message for the field
 * @param fieldRenderProps.touched {boolean} - Whether the field has been touched
 * @param fieldRenderProps.id {string} - The ID of the field
 * @param fieldRenderProps.disabled {boolean} - Whether the field is disabled
 * @param fieldRenderProps.showDisabledValue {boolean} - Whether to show the disabled value
 * @param fieldRenderProps.hint {string} - The hint for the field
 * @param fieldRenderProps.value {string} - The value of the field
 * @param fieldRenderProps.others {object} - Additional props
 * @return {Element}
 * @constructor
 */
export const ComboBoxInput = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        id,
        disabled,
        showDisabledValue,
        hint,
        value,
        setValue = () => {},
        filter = true,
        suggest = true,
        data,
        onFocus,
        ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const shownValue = showDisabledValue ? value : undefined;

    const [filteredData, setFilteredData] = React.useState(data ?? []);

    useEffect(() => {
        // if passed data changes, reset filter to include everything
        setFilteredData(data);
    }, [data]);

    const filterData = (filter) => {
        return filterBy(data, filter);
    };
    const filterChange = (event) => {
        const filteredDate = filterData(event.filter);
        setFilteredData(filteredDate);

        // if the filtered data is down to just 1 item, set it as the value
        // and call a potential 'onChange' function that handles setting it
        // when the combo box is part of a form
        if (filteredData.length === 1) {
            setValue(filteredData[0]);
            if (others.onChange) {
                others.onChange({ value: filteredData[0] });
            }
        } else if (filteredData.length === 0) {
            setValue(null);
            if (others.onChange) {
                others.onChange({ value: null });
            }
        }
    };

    const ddlRef = React.useRef();
    const onDropdownFocus = (event) => {
        // sometimes forms override onFocus, so we need to make sure to do
        // the passed in version, and the ref call to open the DDL
        if (typeof onFocus == "function") {
            onFocus();
        }

        // wait a split second before opening to allow the onOpen to fire
        // if it isn't open, use toggleBtnClick to open the DDL on focus
        setTimeout(() => {
            if (!event.target.state.opened) {
                ddlRef?.current?.toggleBtnClick();
            }
        }, 100);
    };

    return (
        <div>
            <ComboBox
                ref={ddlRef}
                id={id}
                data={filteredData}
                ariaDescribedBy={`${hintId} ${errorId}`}
                value={disabled ? shownValue : value ?? undefined}
                filterable={filter}
                suggest={suggest}
                onFilterChange={filterChange}
                onFocus={onDropdownFocus}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </div>
    );
};
