import { savePDF } from "@progress/kendo-react-pdf";

/**
 * @function checkForDuplicatesInArr
 * @description Checks for duplicate values in an array.
 * @param {Array} arr - The array to check for duplicates.
 * @param {String} value - The value to check for duplicates.
 * @returns {Boolean}
 */
export const checkForDuplicatesInArr = (arr, value) => {
    if (!arr || !value) return;

    const duplicateCount = arr.filter((item) => item === value).length;
    return duplicateCount > 1;
};

/**
 * @function filterArrayByValue
 * @description Filters an array by a value.
 * @param {Array} arrToFilter - The array to filter.
 * @param {Array} filteringArr - The array to filter by.
 * @returns {Array}
 */
export const filterArrayByValue = (arrToFilter, filteringArr) => {
    if (!arrToFilter || !filteringArr) return [];

    for (let i = 0; i < arrToFilter.length; i++) {
        arrToFilter = arrToFilter.filter((filter) => {
            return !filteringArr.includes(filter);
        });
    }

    return arrToFilter;
};

/**
 * @function addressFormatter
 * @description Formats an Address object into string.
 * @param {{address1: string, address2: string, city: string, state: string, zipCode: string}} address
 * @param {"full" | "short"} format
 * @returns {string}
 */
export const addressFormatter = (address, format = "full") => {
    if (!address) return "";

    const { address1, address2, city, state, zipCode } = address;

    let combinedAddress = `${address1 ?? ""}${address2 ? " " + address2 : ""}`;

    switch (format) {
        case "full":
            if (combinedAddress !== "") combinedAddress += ",";

            return `${combinedAddress} ${city ? city + ", " : ""}${
                state ?? ""
            } ${zipCode ?? ""}`;
        case "short":
            return combinedAddress;
        default:
            throw new Error("Unknown address format for invalid format.");
    }
};

/**
 * @function addressesMatch
 * @description Formats an address.
 * @param {Object} firstAddress - Object of the first address.
 * @param {Object} secondAddress - Object of the second address.
 * @returns {boolean}
 */
export const addressesMatch = (firstAddress, secondAddress) => {
    return (
        firstAddress.address1 === secondAddress.address1 &&
        firstAddress.address2 === secondAddress.address2 &&
        firstAddress.city === secondAddress.city &&
        firstAddress.state === secondAddress.state &&
        firstAddress.zipCode === secondAddress.zipCode
    );
};

/**
 * @function shippingAddressFormatter
 * @description Formats an address into array, so we can parse easier.
 * @param {Object} dataItem - Object that contains the address props.
 * @returns {Array}
 */
export const shippingAddressFormatter = (dataItem) => {
    if (!dataItem) return [];

    const { address1, address2, city, state, zipCode } = dataItem;

    let combinedAddress = `${address1 ?? ""} ${address2 ?? ""}`;

    const combinedAddressLargerAreas = `${city ? city + ", " : ""} ${
        state ?? ""
    } ${zipCode ?? ""}`;

    return [combinedAddress, combinedAddressLargerAreas];
};

/**
 * @function exportPDF
 * @description Exports page as PDF.
 * @param {String} pdfName - Name of the PDF that will be created.
 * @param {any} refContainer - ID of the element to export.
 * @returns {void}
 */
export const exportPDF = (pdfName, refContainer) => {
    console.log("PDF NAME", pdfName);
    console.log("itemID", refContainer);
    let element = refContainer.current || document.body;
    savePDF(element, {
        paperSize: "Letter",
        margin: "1cm",
        fileName: pdfName,
        avoidLinks: true,
        creator: "CLEAR Business Insight",
        author: "CLEAR Business Insight",
        scale: 0.55,
    });
};

/**
 * @function resetIndices
 * @description Resets the indices of an array.
 * @param {Array} arr - Array to reset indices of.
 * @returns {Array}
 */
export const resetIndices = (arr) => {
    if (!arr) return [];

    return arr.map((item, index) => {
        item.index = index;
        item.indexPosition = index;
        return item;
    });
};
