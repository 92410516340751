import * as React from "react";
import { useContext, useEffect, useState } from "react";
import PermissionButton from "../../../../../components/Deprecated/PermissionButton";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";

import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import {
    getAllRoles,
    getDefaultRole,
    setDefaultRole,
} from "../../../../../services/Deprecated/authentication/roleService";
import ResponsiveDialog from "../../../../../components/Deprecated/DialogWrapper";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormDropDown } from "../../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../../resources/Deprecated/formValidators";
import BasicFormButtons from "../../../../../components/Deprecated/BasicFormButtons";

const DefaultRoleModal = () => {
    const { triggerReload } = useContext(ReloadDataContext);
    const [visible, setVisible] = useState(false);
    const [roles, setRoles] = useState([]);
    const [initialValues, setInitialValues] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        //? Only load in the roles if the modal is open
        if (!visible) {
            return;
        }

        getAllRoles().then((res) => {
            setRoles(res.data);
        });
        getDefaultRole().then((res) => {
            setInitialValues({ role: res });
        });
    }, [visible]);

    const onSubmit = (props) => {
        setLoaderVisible(true);

        setDefaultRole(props.role.id).then((_) => {
            triggerReload();
            setVisible(false);
            setInitialValues(undefined);
            setLoaderVisible(false);
        });
    };

    return (
        <>
            <PermissionButton
                requiredPermissions={[PermissionsEnum.UpdateRole]}
                themeColor={"primary"}
                fillMode={"solid"}
                rounded={"medium"}
                icon={"k-icon k-i-cog"}
                onClick={() => setVisible(true)}
            >
                Set Default Role
            </PermissionButton>
            {visible && initialValues && (
                <ResponsiveDialog
                    title={"Change Default User Role"}
                    onClose={() => setVisible(false)}
                    size={"small"}
                >
                    <Form
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <Field
                                    name={"role"}
                                    component={FormDropDown}
                                    data={roles}
                                    ataItemKey="roleId"
                                    textField="name"
                                    label={"Default Role:"}
                                    validator={requiredValidator}
                                />
                                <BasicFormButtons
                                    loaderVisible={loaderVisible}
                                    isCreate={false}
                                    onCancel={() => setVisible(false)}
                                    allowSubmit={!formRenderProps.allowSubmit}
                                />
                            </FormElement>
                        )}
                    />
                </ResponsiveDialog>
            )}
        </>
    );
};

export default DefaultRoleModal;
