import React, { useContext, useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import PaymentDetails from "./PaymentDetails";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import { CustomerTypeEnum } from "../../../../resources/Enums/CustomerStatusEnum";
import CustomerDetails from "./CustomerDetails";
import CustomerContactDetails from "./CustomerContactDetails";
import CommunicationDetails from "./CommunicationDetails";

const CustomerTabs = () => {
    const [selected, setSelected] = useState(0);
    const { customer } = useContext(CustomerContext);

    return (
        <TabStrip
            selected={selected}
            onSelect={(e) => setSelected(e.selected)}
            className="tabs"
        >
            <TabStripTab
                title={`${
                    customer.type === CustomerTypeEnum.Enum.Individual
                        ? "Individual"
                        : "Business"
                } Customer`}
            >
                <CustomerDetails />
            </TabStripTab>
            <TabStripTab title="Communications">
                <CommunicationDetails />
            </TabStripTab>
            <TabStripTab title="Payments">
                <PaymentDetails />
            </TabStripTab>
            {customer.type === CustomerTypeEnum.Enum.Business && (
                <TabStripTab title="Contacts">
                    <CustomerContactDetails />
                </TabStripTab>
            )}
        </TabStrip>
    );
};

export default CustomerTabs;
