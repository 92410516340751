import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    ExpansionPanel,
    ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import styles from "../EventDetailsPage.module.scss";
import { Reveal } from "@progress/kendo-react-animation";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import { getAllEventLabor } from "../../../../services/Deprecated/customer/eventServices";
import { TimeCardExtension } from "../../../HumanResources/TimeClockEntry/Components/TimeCardExtension";

const EventLoggedTimePortlet = () => {
    const { event } = useContext(EventContext);
    const [initialFilter, setInitialFilter] = useState(undefined);
    const [initialDateRange, setInitialDateRange] = useState(undefined);
    const [data, setData] = useState([]);
    const [expanded, setExpanded] = useState("");

    useEffect(() => {
        if (!event.id) return;

        setInitialDateRange({
            start: event.eventDate,
            end: event.eventDate,
        });
        setInitialFilter(`calendarEventId~eq~'${event.id}'`);
        getAllEventLabor(event.id).then((res) => {
            setData(res.data);
        });
    }, [event?.id]);

    return (
        <Card
            className={styles.LargeCard}
            style={{
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                minHeight: "324px",
            }}
        >
            <CardHeader
                className="k-hbox"
                style={{
                    background: "transparent",
                    width: "100%",
                }}
            >
                <CardTitle
                    style={{
                        marginBottom: "4px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <strong>Logged Time</strong>
                </CardTitle>
            </CardHeader>
            <CardBody
                style={{
                    padding: "5px",
                    overflowY: "auto",
                }}
            >
                {data.length === 0 && (
                    <span className={"JustifyCenterAndAlignCenter MediumGap"}>
                        <i
                            className={"k-icon k-i-warning"}
                            style={{ color: "orange", fontSize: "1.5rem" }}
                        />
                        <h3>No time clock entries found</h3>
                    </span>
                )}
                {data.map((employee) => (
                    <ExpansionPanel
                        title={employee.fullName}
                        subtitle={"View Time Card"}
                        expanded={expanded === employee.id}
                        tabIndex={0}
                        key={employee.id}
                        onAction={(event) => {
                            setExpanded(event.expanded ? "" : employee.id);
                        }}
                    >
                        <Reveal>
                            {expanded === employee.id && event.id && (
                                <ExpansionPanelContent>
                                    <TimeCardExtension
                                        employeeId={employee.id}
                                        initialFilter={initialFilter}
                                        initialDateRange={initialDateRange}
                                    />
                                </ExpansionPanelContent>
                            )}
                        </Reveal>
                    </ExpansionPanel>
                ))}
            </CardBody>
        </Card>
    );
};

export default EventLoggedTimePortlet;
