import React, { useContext } from "react";
import dayjs from "dayjs";
import { DateHeaderCell } from "@progress/kendo-react-scheduler";
import { SchedulerContext } from "../../../../providers/Deprecated/Customer/SchedulerProvider";
import { useModal } from "../../../../hooks/useModal";
import { EventModal } from "../../CalendarEvents/Components/EventModal/EventModal";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { ActionButton } from "../../../../components/Buttons/ActionButton";

export const CustomDateHeaderCellTimeLine = (props) => {
    const schedulerContext = useContext(SchedulerContext);
    const date = dayjs(props.date._localDate).format("ddd, MMM D");
    const day = new Date(props.date).getDay();
    const modal = useModal();

    if (
        (schedulerContext.calendarType === "work week" ||
            schedulerContext.calendarType === "2 week work week") &&
        (day === 0 || day === 6)
    ) {
        return;
    }

    const onCreateClick = () => {
        modal.open(undefined, {
            eventDate: dayjs(props.date._localDate).toDate(),
        });
    };

    return (
        <>
            <DateHeaderCell date={date} end={props.end} start={props.start} />
            <div
                style={{
                    borderRight: "1px solid #dee3ed",
                }}
            >
                <EventModal {...modal} />
                <ActionButton
                    onClick={onCreateClick}
                    buttonText={" "}
                    icon={"k-icon k-i-plus"}
                    permissions={[PermissionsEnum.CreateCalendarEvent]}
                    style={{ margin: "5px" }}
                />
            </div>
        </>
    );
};
