import {axiosInstance as axios} from "../../axiosInstance";
import {formatDateOnlyForNetwork} from "../../../resources/Deprecated/dateHelper";
import {formatServiceResponse} from "../../../utilities/serviceUtility";

/**
 * @function getAllEvents
 * @description Function to get all events
 * @returns {Object}
 */
export const getAllEvents = async (dateRange) => {
    try {
        const response = await axios.get(
            `/Customer/CalendarEvent/GetAll?start=${formatDateOnlyForNetwork(
                dateRange.start
            )}&end=${formatDateOnlyForNetwork(dateRange.end)}&`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllLaborInfoForJobEvents
 * @description Function to get all labor info for job events
 * @param {String} jobId
 * @returns {Object}
 */
export const getAllLaborInfoForJobEvents = async (jobId) => {
    try {
        const response = await axios.get(
            `/Customer/Job/CalendarEvent/GetLaborInfo?jobId=${jobId}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getAllLaborForEvent = async (eventId) => {
    const res = await axios.get(
        `Customer/CalendarEvent/Labor/GetAll?calendarEventId=${eventId}&`
    );
    return formatServiceResponse(res);
};

/**
 * @function getAllEventsForEventType
 * @description Function to get all events for a event type
 * @param {String} eventTypeId
 * @param {String} calendarEventId
 * @returns {Object}
 */
export const getAllEventsForEventType = async (
    calendarEventId,
    eventTypeId
) => {
    try {
        const response = await axios.get(
            `/Customer/CalendarEvent/GetAll?filter=(eventTypeId~eq~'${eventTypeId}')`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function getAllJobEventsForJobAndEventType
 * @description Function to get all events for a job and event type
 * @param {String} jobId
 * @param {String} eventTypeId
 * @returns {Object}
 */
export const getAllJobEventsForJobAndEventType = async (jobId, eventTypeId) => {
    try {
        const response = await axios.get(
            `/Customer/Job/CalendarEvent/GetAll?jobId=${jobId}&filter=(eventTypeId~eq~'${eventTypeId}')`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function createEvent
 * @description Function to create a event
 * @param {Object} eventCreationObject
 * @returns {Object}
 */
export const createEvent = async (eventCreationObject) => {
    try {
        return await axios.post(
            `/Customer/CalendarEvent/Create`,
            eventCreationObject
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function updateEvent
 * @description Function to update a event
 * @param {Object} eventEditObject
 * @returns {Object}
 */
export const updateEvent = async (eventEditObject) => {
    try {
        return await axios.put(
            `/Customer/CalendarEvent/Update`,
            eventEditObject
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function deleteEvent
 * @description Function to delete a event
 * @param {String} eventId
 * @returns {Object}
 */
export const deleteEvent = async (eventId) => {
    try {
        return await axios.delete(
            `/Customer/CalendarEvent/Delete?id=${eventId}`
        );
    } catch (error) {
        console.error(error);
        // Ensure a rejected Promise is returned in case of an error
        return Promise.reject(error);
    }
};

/**
 * @function getAllCustomerEventTypes
 * @description Function to get all event types
 * @returns {Object}
 */
export const getAllCustomerEventTypes = async () => {
    try {
        const response = await axios.get("/Customer/EventType/GetAll");
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function getEvent
 * @description Function to get event
 * @param {String} eventId
 * @returns {Object}
 */
export const getEvent = async (eventId) => {
    try {
        const response = await axios.get(
            `/Customer/CalendarEvent/Get?id=${eventId}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function createEventNote
 * @description Function to create a event note
 * @param {Object} eventNoteCreationObject
 * @returns {Object}
 */
export const createEventNote = async (eventNoteCreationObject) => {
    try {
        return await axios.post(
            `/Customer/CalendarEvent/Note/Create`,
            eventNoteCreationObject
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function updateEventNote
 * @description Function to update a event note
 * @param {Object} eventNoteEditObject
 * @returns {Object}
 */
export const updateEventNote = async (eventNoteEditObject) => {
    try {
        return await axios.put(
            `/Customer/CalendarEvent/Note/Update`,
            eventNoteEditObject
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function deleteEventNote
 * @description Function to delete a event note
 * @param {String} eventNoteId
 * @returns {Object}
 */
export const deleteEventNote = async (eventNoteId) => {
    try {
        return await axios.delete(
            `/Customer/CalendarEvent/Note/Delete?id=${eventNoteId}`
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getAllEventLabor = async (eventId) => {
    const res = await axios.get(
        `/Customer/CalendarEvent/Labor/GetAll?calendarEventId=${eventId}&`
    );
    return formatServiceResponse(res);
};

/**
 * @function createEventLabor
 * @description Function to create a event labor
 * @param {Object} eventLaborCreationObject
 * @returns {Object}
 */
export const createEventLabor = async (eventLaborCreationObject) => {
    try {
        return await axios.post(
            `/Customer/CalendarEvent/Labor/Create`,
            eventLaborCreationObject
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function deleteEventLabor
 * @description Function to delete a event labor
 * @param {String} laborId
 * @param {String} eventId
 * @returns {Object}
 */
export const deleteEventLabor = async (laborId, eventId) => {
    try {
        return await axios.delete(
            `/Customer/CalendarEvent/Labor/Delete?calendarEventId=${eventId}&employeeId=${laborId}`
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function createAppointmentTime
 * @description Function to create a appointment time
 * @param {Object} appointmentTimeCreationObject
 * @returns {Object}
 */
export const createAppointmentTime = async (appointmentTimeCreationObject) => {
    try {
        return await axios.post(
            `/Customer/CalendarEvent/AppointmentTime/Create`,
            appointmentTimeCreationObject
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function deleteAppointmentTime
 * @description Function to delete a appointment time
 * @param {String} appointmentTimeId
 * @returns {Object}
 */
export const deleteAppointmentTime = async (appointmentTimeId) => {
    try {
        return await axios.delete(
            `/Customer/CalendarEvent/AppointmentTime/Delete?id=${appointmentTimeId}`
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function updateAppointmentTime
 * @description Function to update a appointment time
 * @param {Object} appointmentTimeEditObject
 * @returns {Object}
 */
export const updateAppointmentTime = async (appointmentTimeEditObject) => {
    try {
        return await axios.put(
            `/Customer/CalendarEvent/AppointmentTime/Update`,
            appointmentTimeEditObject
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function createEventTaskCategory
 * @description Function to create a event taskCategory
 * @param {Object} eventTaskCategoryCreationObject
 * @returns {Object}
 */
export const createEventTaskCategory = async (
    eventTaskCategoryCreationObject
) => {
    try {
        return await axios.post(
            `/Customer/CalendarEvent/TaskCategory/Create`,
            eventTaskCategoryCreationObject
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function deleteEventTaskCategory
 * @description Function to delete a event taskCategory
 * @param {String} taskCategoryId
 * @param {String} eventId
 * @returns {Object}
 */
export const deleteEventTaskCategory = async (taskCategoryId, eventId) => {
    try {
        return await axios.delete(
            `/Customer/CalendarEvent/TaskCategory/Delete?calendarEventId=${eventId}&taskCategoryId=${taskCategoryId}`
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function deleteEventTask
 * @description Function to delete a event task
 * @param {String} taskId
 * @param {String} eventId
 * @returns {Object}
 */
export const deleteEventTask = async (taskId, eventId) => {
    try {
        return await axios.delete(
            `/Customer/CalendarEvent/Task/Delete?calendarEventId=${eventId}&taskId=${taskId}`
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function createEventTask
 * @description Function to create a event task
 * @param {Object} eventTaskCreationObject
 * @returns {Object}
 */
export const createEventTask = async (eventTaskCreationObject) => {
    try {
        return await axios.post(
            `/Customer/CalendarEvent/Task/Create`,
            eventTaskCreationObject
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function deleteTask
 * @description Function to delete a event task
 * @param {String} taskId
 * @param {String} eventId
 * @returns {Object}
 */
export const deleteTask = async (taskId, eventId) => {
    try {
        return await axios.delete(
            `/Customer/CalendarEvent/Task/Delete?calendarEventId=${eventId}&taskId=${taskId}`
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function getAllTasks
 * @description Function to get all tasks
 * @returns {Object}
 */
export const getAllTasks = async () => {
    try {
        const response = await axios.get(`/Customer/Task/GetAll`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function getAllTaskCategories
 * @description Function to get all task categories
 * @returns {Object}
 */
export const getAllTaskCategories = async () => {
    try {
        const response = await axios.get(`/Customer/TaskCategory/GetAll`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function getAllTasksAndTaskCategoriesForEventType
 * @description Function to get all tasks and task categories for an event type
 * @param {String} eventTypeId
 * @returns {Object}
 */
export const getAllTasksAndTaskCategoriesForEventType = async (eventTypeId) => {
    try {
        const response = await axios.get(
            `/Customer/EventType/TaskCategory/GetAll?eventTypeId=${eventTypeId}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getJobEvents = async () => {
    try {
        const response = await axios.get(`/Customer/CalendarEvent/GetAll?`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getAllTaskCategoriesForCalendarEvent = async (calendarEventId) => {
    const res = await axios.get(
        `/Customer/CalendarEvent/TaskCategory/GetAll?calendarEventId=${calendarEventId}&`
    );
    return formatServiceResponse(res);
};

export const getAllTasksForCalendarEvent = async (calendarEventId) => {
    const res = await axios.get(
        `/Customer/CalendarEvent/Task/GetAll?calendarEventId=${calendarEventId}&`
    );
    return formatServiceResponse(res);
};
