import dayjs from "dayjs";
import { VendorDocumentType } from "../../../../../../resources/Enums/VendorDocumentType.js";
import StringHelper from "../../../../../../resources/StringHelper.js";

export class VendorDocumentModalHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForNetwork(dataItem) {
        return {
            id: dataItem.id,
            type: dataItem.type,
            address: dataItem.address,
            documentNumber: dataItem.documentNumber,
            businessInformationId: dataItem.businessInformation.id,
            jobId: dataItem.job.id,
            vendorId: dataItem.vendor.id,
            fullyReceivedDate: dataItem.fullyReceivedDate
                ? dayjs(dataItem.fullyReceivedDate).toDate()
                : undefined,
            expectedDeliveryDate: dataItem.expectedDeliveryDate
                ? dayjs(dataItem.expectedDeliveryDate).toDate()
                : undefined,
            orderedById: dataItem.orderedBy.id,
            confirmedById: dataItem.confirmedBy?.id,
            receivedById: dataItem.receivedBy?.id,
            lineItems: dataItem.lineItems.map((li) => {
                return {
                    id: isNaN(li.id) ? li.id : undefined, // Means it's a new item
                    description: li.description,
                    price: li.price,
                    quantity: li.quantity,
                    isFieldItem: false,
                    type: li.type,
                    itemId: li.item?.id, // This will be VendorItemId
                    jobId: li.job.id,
                };
            }),
        };
    }

    /**
     * @function formatDataForModal
     * @description Format data for the event modal
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForModal(dataItem) {
        const data = dataItem ?? {};

        return {
            ...dataItem,
            fullyReceivedDate:
                data.fullyReceivedDate &&
                dayjs(data.fullyReceivedDate).format("MM/DD/YYYY"),
            expectedDeliveryDate:
                data.expectedDeliveryDate &&
                dayjs(data.expectedDeliveryDate).format("MM/DD/YYYY"),
            type: data.type ?? VendorDocumentType.Enum.PurchaseOrder,
            lineItems:
                data.lineItems?.map((item) => {
                    return {
                        ...item,
                        IN_LINE_EDIT: true,
                    };
                }) ?? [],
        };
    }

    /**
     * @function generateModalTitle
     * @description Generate the modal title
     * @param record {Object} - The record object to generate a title for
     * @return {`View ${string}`|`Edit ${string}`|`Create ${string}`}
     */
    static generateModalTitle = (record) => {
        const typeStr = VendorDocumentType.ParseToString(record.type);
        const typeStrFormatted =
            StringHelper.addSpacesToCapitalLetters(typeStr);

        if (record?.childVendorDocuments?.length > 0) {
            return `View ${typeStrFormatted}`;
        }

        if (record.id) {
            return `Edit ${typeStrFormatted}`;
        }

        return `Create ${typeStrFormatted}`;
    };

    static formatLineItemForGrid = (li) => {
        return {
            id: li.id,
            job: li.job,
            quantity: 1,
            quantityReceived: 0,
            price: li.item.retailPrice,
            description: li.item.poDescription,
            item: {
                ...li.item,
                id: li.vendorItemId,
            },
            total: {
                amount: li.item.retailPrice.amount,
                currency: li.item.retailPrice.currency,
            },
            type: li.type,
            isDescriptionEditAllowed: li.item.isDescriptionEditAllowed,
            IN_LINE_EDIT: true,
        };
    };
}
