import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import styles from "./InvoiceSummary.module.scss";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { PaymentContext } from "../../../../../providers/Deprecated/Customer/PaymentProvider";
import { JobDocumentContext } from "../../../../../providers/Deprecated/Customer/JobDocumentProvider";
import { NewJobDocumentTypeEnum } from "../../../../../resources/Enums/JobDocumentTypeEnum";
import { CustomCell } from "../../../../../components/Deprecated/CustomGridCells";
import { formatUSDCurrency } from "../../../../../resources/Deprecated/currencyHelper";
import { PaymentsHelper } from "../../../../../resources/Deprecated/PaymentHelpers/paymentsHelper";

const PaymentDisbursement = () => {
    const [data, setData] = useState([]);
    const paymentContext = useContext(PaymentContext);
    const jobDocContext = useContext(JobDocumentContext);

    useEffect(() => {
        setData(
            paymentContext.documents.filter(
                (i) => i.type === NewJobDocumentTypeEnum.Enum.Invoice
            )
        );
    }, [paymentContext.documents]);

    return (
        <Card className={styles.InvoiceCard}>
            <CardHeader>
                <CardTitle>Invoice Summary</CardTitle>
            </CardHeader>
            <CardBody>
                <Grid className={styles.InvoiceGrid} data={data}>
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <GridNoRecords> </GridNoRecords>
                    <GridColumn
                        field="documentNumber"
                        title="Document Number:"
                        cell={(props) => {
                            return (
                                <CustomCell>
                                    <span
                                        className={"link"}
                                        onClick={() =>
                                            jobDocContext.setSelectedJobDocument(
                                                {
                                                    id: props.dataItem.id,
                                                    operation: "edit",
                                                }
                                            )
                                        }
                                    >
                                        #{props.dataItem.documentNumber}
                                    </span>
                                </CustomCell>
                            );
                        }}
                    />
                    <GridColumn field="documentDate" title="Date:" />
                    <GridColumn
                        field="total.amount"
                        title="Total:"
                        format="{0:c}"
                    />
                    <GridColumn
                        field="balance"
                        title="Balance:"
                        cell={(props) => {
                            return (
                                <CustomCell>
                                    {formatUSDCurrency(
                                        props.dataItem.total.amount -
                                            props.dataItem.totalPaid.amount
                                    )}
                                </CustomCell>
                            );
                        }}
                    />
                    <GridColumn
                        field="totalPaid.amount"
                        title="Total Paid:"
                        cell={(props) => {
                            const total = props.dataItem.total.amount;
                            const balance =
                                props.dataItem.total.amount -
                                props.dataItem.totalPaid.amount;
                            const paidFormatted = formatUSDCurrency(
                                props.dataItem.totalPaid.amount
                            );
                            const percentage =
                                PaymentsHelper.getPercentageForDisbursedPayment(
                                    total,
                                    balance
                                );

                            return (
                                <CustomCell>{`${paidFormatted} (${percentage})`}</CustomCell>
                            );
                        }}
                    />
                </Grid>
            </CardBody>
        </Card>
    );
};

export default PaymentDisbursement;
