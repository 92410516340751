import React, { createContext, useContext, useEffect, useState } from "react";
import { ReloadDataContext } from "../../ReloadDataProvider";
import { JobContext } from "./JobProvider.jsx";
import JobPaymentServices from "../../../services/Deprecated/customer/JobPaymentServices.js";
import {
    getJobSummary,
    getPayment,
} from "../../../services/Deprecated/customer/paymentServices.js";
import { getAllDocuments } from "../../../services/Deprecated/customer/jobServices.js";

export const PaymentContext = createContext(null);

const PaymentProvider = ({ children }) => {
    const reloadDataContext = useContext(ReloadDataContext);
    const jobContext = useContext(JobContext);
    //? If payment is present, disbursement modal will show
    const [disbursingPayment, setDisbursingPayment] = useState(undefined);
    const [disbursingPaymentId, setDisbursingPaymentId] = useState(undefined);
    const [transferringPaymentId, setTransferringPaymentId] =
        useState(undefined);
    const [documents, setDocuments] = useState([]);
    const [isJobPayment, setIsJobPayment] = useState(false);
    const [jobSummary, setJobSummary] = useState(undefined);

    useEffect(() => {
        /**
         * Fetches payment data for the given disbursingPaymentId.
         * @async
         * @function fetchPaymentData
         * @returns {Promise<Object>} - A promise that resolves with the payment data or rejects with an error object
         * @throws {Error} - If an error occurs while fetching the payment details
         */
        if (!disbursingPaymentId) {
            setDisbursingPayment(undefined);
            return;
        }

        const fetchPaymentData = async () => {
            if (isJobPayment) {
                return await JobPaymentServices.get(disbursingPaymentId);
            } else {
                return await getPayment(disbursingPaymentId);
            }
        };

        fetchPaymentData().then((result) => {
            setDisbursingPayment(result.data);
        });
    }, [disbursingPaymentId, reloadDataContext.reloadData]);

    useEffect(() => {
        if (!isJobPayment) {
            setDocuments([]);
            return;
        }

        // Get all Sales Orders and Invoices for the job
        getAllDocuments(
            `(type~eq~1~or~type~eq~2)~and~jobId~eq~'${jobContext.job.id}'`
        ).then((result) => {
            setDocuments(result.data);
        });
    }, [isJobPayment, reloadDataContext.reloadData]);

    useEffect(() => {
        if (!jobContext.job?.id) {
            setJobSummary(undefined);
            return;
        }

        const fetchJobSummary = async () =>
            await getJobSummary(jobContext.job?.id);

        fetchJobSummary().then((r) => setJobSummary(r.data));
    }, [reloadDataContext.reloadData]);

    const value = {
        disbursingPayment,
        setDisbursingPaymentId,
        setTransferringPaymentId,
        transferringPaymentId,
        isJobPayment,
        setIsJobPayment,
        documents,
        jobSummary,
    };
    return (
        <PaymentContext.Provider value={value}>
            {children}
        </PaymentContext.Provider>
    );
};

export default PaymentProvider;
