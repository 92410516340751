import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import styles from "./VendorDocumentSummary.module.scss";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Link } from "react-router-dom";
import { JobContext } from "../../../../../providers/Deprecated/Customer/JobProvider";
import { CenterLoader } from "../../../../../components/Deprecated/CenterLoader";
import { CustomCell } from "../../../../../components/Deprecated/CustomGridCells";
import VendorDocumentService from "../../../../../services/VendorDocumentService.js";

const VendorDocumentSummary = () => {
    const jobContext = useContext(JobContext);
    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoadingData(true);

        const fetchPOSummary = async () =>
            await new VendorDocumentService(
                "/Inventory/Vendor/Document"
            ).getSummary(jobContext.job?.id);

        fetchPOSummary().then((res) => {
            setData(res.data);
            setLoadingData(false);
        });
    }, [jobContext.job?.id]);

    return loadingData ? (
        <CenterLoader />
    ) : (
        <Card className={styles.VendorDocumentCard}>
            <CardHeader>
                <CardTitle>Item Receipt Summary</CardTitle>
            </CardHeader>
            <CardBody>
                <Grid className={styles.VendorDocumentGrid} data={data}>
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <GridNoRecords> </GridNoRecords>
                    <GridColumn
                        title="Document Number:"
                        locked={true}
                        cell={(props) => {
                            return (
                                <CustomCell>
                                    <span>
                                        #{props.dataItem.documentNumber}
                                    </span>
                                </CustomCell>
                            );
                        }}
                    />
                    <GridColumn
                        title={"Vendor"}
                        field={"vendor.name"}
                        cell={(props) => {
                            return (
                                <CustomCell>
                                    <span className={"text-overflow link"}>
                                        <Link
                                            to={{
                                                pathname: `/inventory/vendor/${props.dataItem.id}`,
                                            }}
                                        >
                                            {props.dataItem.vendor.name}
                                        </Link>
                                    </span>
                                </CustomCell>
                            );
                        }}
                    />
                    <GridColumn
                        title={"Item"}
                        field={"item.name"}
                        className={"text-overflow"}
                    />
                    <GridColumn title={"Quantity"} field={"quantity"} />
                    <GridColumn
                        title={"Avg Cost"}
                        format={"{0:c}"}
                        field={"cost.amount"}
                    />
                    <GridColumn
                        title={"Total"}
                        format={"{0:c}"}
                        field={"total.amount"}
                    />
                </Grid>
            </CardBody>
        </Card>
    );
};

export default VendorDocumentSummary;
