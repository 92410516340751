import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { JobContext } from "../../../providers/Deprecated/Customer/JobProvider";
import { CustomerContext } from "../../../providers/Deprecated/Customer/CustomerProvider";
import { useModal } from "../../../hooks/useModal";
import { getAllTimeClockEntriesForJob } from "../../../services/Deprecated/humanResources/timeClockServices";
import { deleteJob } from "../../../services/Deprecated/customer/jobServices";
import JobModal from "./Components/JobModal";
import JobDocumentTabs from "../JobDocuments/Components/JobDocumentTabs";
import { Card } from "@progress/kendo-react-layout";
import JobTabs from "./Components/JobTabs";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { ActionButton } from "../../../components/Buttons/ActionButton";

const JobDetailsPage = (props) => {
    const history = useHistory();
    const jobContext = useContext(JobContext);
    const customerContext = useContext(CustomerContext);
    const jobId = props?.match?.params?.id;
    const [timeClockEntries, setTimeClockEntries] = useState([]);
    const editModal = useModal();

    useEffect(() => {
        jobContext.setJobId(jobId);
    }, [jobId]);

    useEffect(() => {
        if (!customerContext.customer.id) {
            customerContext.setCustomerId(jobContext.job.customer?.id);
        }
    }, [jobContext.job?.id]);

    useEffect(() => {
        const fetchTimeClockEntries = async () => {
            try {
                const response = await getAllTimeClockEntriesForJob(jobId);
                setTimeClockEntries(response.data);
            } catch (error) {
                console.error("Failed to fetch Time Clock Entries", error);
            }
        };
        fetchTimeClockEntries();
    }, []);

    const isDeletable = () => {
        return !timeClockEntries.some((entry) => entry.id === jobId);
    };

    const deleteJobViewPage = async () => {
        if (!isDeletable()) {
            alert("Tip: Cannot delete job with Time Clocks.");
            return;
        }
        try {
            await deleteJob(jobId);
            jobContext.setJobId(undefined);
            history.push(`/jobs/${jobId}`);
        } catch (error) {
            console.log("Error Deleting Job", error);
        }
    };

    const gotoCustomer = () => {
        const customerId = customerContext.customer.id;
        history.push(`/customer/${customerId}/`);
    };

    return (
        jobContext?.job && (
            <div>
                {/* Job Edit Modal */}
                <JobModal {...editModal} />

                {/* Header */}
                <header className="vendor-name-header">
                    {jobContext?.job?.name} Job Details
                </header>

                {/* Action Buttons */}
                <div className="k-flex-100 flex-container">
                    <div className="content-wrapper">
                        <Card
                            className="option-card-container"
                            style={{ border: "none", maxWidth: "1200px" }}
                        >
                            {jobContext.job.isMarkedDead ||
                            jobContext.job.isArchived ? (
                                <div>
                                    <button
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        onClick={gotoCustomer}
                                    >
                                        <span className={"k-icon k-i-undo"} />
                                        Go To Customer
                                    </button>
                                    <Tooltip
                                        anchorElement="target"
                                        position="left"
                                    >
                                        <span title="Tip: Cannot edit or delete a job that is marked dead or archived.">
                                            <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-disabled">
                                                Edit
                                            </button>
                                            <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-disabled">
                                                Delete
                                            </button>
                                        </span>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div className={"JustifyRightAlignCenter"}>
                                    <ActionButton
                                        buttonText={"Edit"}
                                        onClick={() =>
                                            editModal.open(
                                                jobId,
                                                jobContext.job
                                            )
                                        }
                                        permissions={[
                                            PermissionsEnum.UpdateCustomer,
                                        ]}
                                    />
                                    <ActionButton
                                        buttonText="Delete"
                                        onClick={deleteJobViewPage}
                                        needsConfirmation={true}
                                        confirmationText="Are you sure you want to delete this job?"
                                        permissions={[
                                            PermissionsEnum.DeleteCustomer,
                                        ]}
                                        disabled={!isDeletable()}
                                        disabledToolTipMessage="Tip: Cannot delete job with Time Clocks."
                                    />
                                    <ActionButton
                                        buttonText="Go To Customer"
                                        onClick={gotoCustomer}
                                        theme="primary"
                                    />
                                </div>
                            )}
                        </Card>

                        {/* Job Detail Tabs */}
                        <div className="tab-container">
                            <Card
                                className="card-container"
                                style={{ border: "none" }}
                            >
                                <JobTabs />
                            </Card>
                            {jobContext.needsAllocations && (
                                <h3
                                    className={
                                        "JustifyLeftAlignCenter MediumGap ZeroMargin"
                                    }
                                    style={{ marginLeft: "20px" }}
                                >
                                    <i
                                        className={
                                            "k-icon k-icon-md k-i-warning"
                                        }
                                        style={{
                                            color: "orange",
                                        }}
                                    />{" "}
                                    Job has items remaining to be allocated.
                                </h3>
                            )}
                        </div>

                        {/* Job Document Tabs */}
                        <div className="tab-container">
                            <Card
                                className="card-container"
                                style={{ border: "none" }}
                            >
                                <JobDocumentTabs />
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default JobDetailsPage;
