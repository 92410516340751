import React, { createContext, useContext, useEffect, useState } from "react";
import { JobContext } from "./JobProvider.jsx";
import {
    getAllDocuments,
    getAllJobItemsForJob,
    getJobDocument,
} from "../../../services/Deprecated/customer/jobServices.js";
import VendorDocumentService from "../../../services/VendorDocumentService.js";
import { JobDocumentTypeEnum } from "../../../resources/Enums/JobDocumentTypeEnum.js";

export const JobDocumentContext = createContext(null);

const JobDocumentProvider = ({ children }) => {
    //? This is a bit of a hacky way to ensure all relationships are loaded in
    //? We have to load in 6 different relationships, so we use this to count
    //? How many have loaded in, and once they have all loaded in, we toggle the state to true
    //?
    const [relationShipLoadCount, setRelationShipLoadCount] = useState(0);
    const [relationshipsLoaded, setRelationshipsLoaded] = useState(false);
    const [toastMessage, _setToastMessage] = useState(null);
    const [runningTotal, setRunningTotal] = useState(0);
    //? This is the job document that is currently selected
    //?
    const [selectedJobDocument, setSelectedJobDocument] = useState({
        id: undefined,
        operation: undefined,
    });
    const [jobDocument, setJobDocument] = useState(undefined);
    const [reloadJobDocument, setReloadJobDocument] = useState(false);
    const [isJobDocumentEditable, setIsJobDocumentEditable] = useState(true);

    //? 0 = Estimate, 1 = SalesOrder, 2 = Invoice, 3 = VendorDocument, 4 = FieldVendorDocument,
    //? 5 = Credit, 6 = ChangeOrder, 7 = StockOrder
    //?
    const [jobDocuments, setJobDocuments] = useState({
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
    });
    const [reloadJobDoc, setReloadJobDoc] = useState(false);

    const [totals, setTotals] = useState({
        subtotal: 0,
        discounts: 0,
        total: 0,
    });
    const [jobItems, setJobItems] = useState([]);

    const jobContext = useContext(JobContext);
    const jobId = jobContext.job.id;

    //? I know this is quite verbose, but this is the only way I could think of
    //? To give us the control we need over renders
    //?
    //? Get all estimates
    //?
    useEffect(() => {
        if (!jobId) return;

        const fetchData = async () => {
            const estimates = await getAllDocuments(
                `type~eq~0~and~jobId~eq~'${jobId}'`
            );

            const previousJobDocuments = jobDocuments;
            previousJobDocuments[0] = estimates.data;
            setJobDocuments(previousJobDocuments);
        };

        fetchData().then(() => {
            setRelationShipLoadCount((prev) => prev + 1);
        });
    }, [jobId, reloadJobDoc]);

    // get all items that belong to the job from job's job docs
    useEffect(() => {
        const fetchData = async () => {
            if (!jobId) return;

            const documentsResponse = await getAllJobItemsForJob(jobId);
            const documents = documentsResponse.data;

            const allItems = documents
                .map((doc) => doc.item)
                .flat()
                .reduce((acc, cur) => {
                    const exists = acc.find((x) => x.id === cur.id);
                    if (!exists) {
                        acc.push(cur);
                    }
                    return acc;
                }, []);
            setJobItems(allItems);
        };

        fetchData();
    }, [jobId, reloadJobDoc]);

    //? Get all sales orders
    //?
    useEffect(() => {
        if (!jobId) return;

        const fetchData = async () => {
            const salesOrders = await getAllDocuments(
                `type~eq~1~and~jobId~eq~'${jobId}'`
            );

            const previousJobDocuments = jobDocuments;
            previousJobDocuments[1] = salesOrders.data;
            setJobDocuments(previousJobDocuments);
        };

        fetchData().then(() => {
            setRelationShipLoadCount((prev) => prev + 1);
        });
    }, [jobId, reloadJobDoc]);

    //? Get all invoices
    //?
    useEffect(() => {
        if (!jobId) return;

        const fetchData = async () => {
            const invoices = await getAllDocuments(
                `type~eq~2~and~jobId~eq~'${jobId}'`
            );

            const previousJobDocuments = jobDocuments;
            previousJobDocuments[2] = invoices.data;
            setJobDocuments(previousJobDocuments);
        };

        fetchData().then(() => {
            setRelationShipLoadCount((prev) => prev + 1);
        });
    }, [jobId, reloadJobDoc]);

    //? Get all purchase orders
    //?
    useEffect(() => {
        if (!jobId) return;

        const fetchData = async () => {
            const vendorDocuments = await new VendorDocumentService(
                "Inventory/Vendor/Document"
            ).getAllPurchaseOrders(jobId);

            const previousJobDocuments = jobDocuments;
            previousJobDocuments[3] = vendorDocuments.data;
            setJobDocuments(previousJobDocuments);
        };

        fetchData().then(() => {
            setRelationShipLoadCount((prev) => prev + 1);
        });
    }, [jobId, reloadJobDoc]);

    //? Check to ensure all relationships loaded in
    //? If they have, toggle the relationshipsLoaded state
    //?
    useEffect(() => {
        if (relationShipLoadCount === 8) {
            setRelationshipsLoaded(true);
        }
    }, [relationShipLoadCount]);

    /**
     * @function refreshJobDocData
     * @description Refresh the job document data
     * @returns {void}
     */
    const refreshJobDocData = () => {
        setReloadJobDoc(!reloadJobDoc);
    };

    //? If there is a selectedJobDocument.id, fetch the Job Document associated
    //? with it and set the jobDocument state
    //?
    useEffect(() => {
        if (!selectedJobDocument.id) {
            setJobDocument(undefined);
            return;
        }

        const fetchJobDocument = async (jobDocumentId) => {
            try {
                await getJobDocument(jobDocumentId).then(
                    (jobDocumentResponse) => {
                        setJobDocument(jobDocumentResponse.data);
                        const hasChildDocuments =
                            jobDocumentResponse?.data?.childJobDocuments
                                ?.length > 0;

                        const isInvoice =
                            jobDocumentResponse.data?.type ===
                            JobDocumentTypeEnum.Invoice;

                        // Invoices can never be edited
                        if (isInvoice) {
                            setIsJobDocumentEditable(false);
                            return;
                        }

                        if (hasChildDocuments) {
                            setIsJobDocumentEditable(false);
                            return;
                        }

                        setIsJobDocumentEditable(true);
                    }
                );
            } catch (error) {
                console.error("Error fetching jobDocument details:", error);
            }
        };

        fetchJobDocument(selectedJobDocument.id);
        //? Reload the data when the selectedJobDocument.id changes
        //? Or if the reloadDataContext.reloadData state changes
        //?
    }, [selectedJobDocument.id, reloadJobDocument]);

    const checkForExistingDocument = (
        currentJobDocumentId,
        newJobDocumentType
    ) => {
        // Access the jobDocuments state to get all documents
        const documents = jobDocuments[newJobDocumentType];

        // Check if any document in the targeted type has the current document as its parent
        const existingDocument = documents.find(
            (doc) => doc.parentJobDocumentId === currentJobDocumentId
        );

        // If an existing document is found, return true, otherwise return false
        return !!existingDocument;
    };

    //? This is the value that will be passed to any component that consumes this context
    //?
    const value = {
        relationshipsLoaded,
        jobDocuments,
        refreshJobDocData,
        selectedJobDocument,
        setSelectedJobDocument,
        jobDocument,
        setReloadJobDocument,
        reloadJobDocument,
        toastMessage,
        runningTotal,
        setRunningTotal,
        isJobDocumentEditable,
        checkForExistingDocument,
        totals,
        setTotals,
        jobItems,
    };

    return (
        <JobDocumentContext.Provider value={value}>
            {children}
        </JobDocumentContext.Provider>
    );
};

export default JobDocumentProvider;
