import React from "react";
import { formatUSDCurrency } from "../../../../resources/Deprecated/currencyHelper";

const TotalsComponent = ({ discounts, total, totalPaid }) => {
    return (
        <div className="JDLI-Totals">
            <div>
                <span className="JDLI-Totals-label">Discounts:</span>{" "}
                {formatUSDCurrency(discounts)}
            </div>
            <div>
                <span className="JDLI-Totals-label">Total:</span>{" "}
                {formatUSDCurrency(total)}
            </div>
            {totalPaid !== 0 && (
                <div>
                    <span className="JDLI-Totals-label">Total Paid:</span>{" "}
                    {formatUSDCurrency(totalPaid)}
                </div>
            )}
        </div>
    );
};

export default TotalsComponent;
