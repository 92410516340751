import React, { useContext } from "react";
import { BreadcrumbContext } from "../providers/BreadCrumbProvider";
import "../styles/CommonTextStyles.scss";

/**
 * @function LinkTag
 * @description A component that renders a link tag
 * @param [props.breadCrumbInfo] {Object} - The breadcrumb information for changing the route
 * @param props.text {String} - The text to display
 * @param [props.onClick] {Function} - The function to call when the link is clicked
 * @return {Element}
 * @constructor
 */
export default function LinkTag(props) {
    const { breadCrumbInfo, text, onClick } = props;
    const { handleRouteChange } = useContext(BreadcrumbContext);

    const handleClick = () => {
        if (breadCrumbInfo) {
            handleRouteChange(breadCrumbInfo);
        }

        if (onClick) {
            onClick();
        }
    };

    return (
        <a onClick={handleClick} className={"Link"}>
            {text}
        </a>
    );
}
