import React, { useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import EventProvider, {
    EventContext,
} from "../../../providers/Deprecated/Customer/EventProvider";
import { Card } from "@progress/kendo-react-layout";
import { FileManager } from "../../../components/FileManager/FileManager";
import { deleteEvent } from "../../../services/Deprecated/customer/eventServices";
import { CustomerContext } from "../../../providers/Deprecated/Customer/CustomerProvider";
import { JobContext } from "../../../providers/Deprecated/Customer/JobProvider";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import dayjs from "dayjs";
import styles from "./EventDetailsPage.module.scss";
import { ActionCell } from "../../../components/GridColumnComponents/ActionCell";
import { useModal } from "../../../hooks/useModal";
import { GridCellWrapper } from "../../../components/Wrappers/GridCellWrapper";
import { EventTabs } from "./Components/EventTabs";
import EventTypeDatesPortlet from "./Components/EventTypeDatesPortlet";
import AppointmentTimePortlet from "./Components/AppointmentTimePortlet";
import SpecificationPortlet from "./Components/SpecificationPortlet";
import EventTasksAndCategoriesPortlets from "./Components/EventTasksAndCategoriesPortlets";
import EventLaborersPortlet from "./Components/EventLaborersPortlet";
import EventLoggedTimePortlet from "./Components/EventLoggedTimePortlet";
import EventNotesPortlet from "./Components/EventNotesPortlet";
import { EventModal } from "./Components/EventModal/EventModal";

const EventDetailsWrapper = (props) => {
    return (
        <EventProvider>
            <EventDetailsPage {...props} />
        </EventProvider>
    );
};
const fileRestrictions = {
    allowedExtensions: [".jpeg", ".pdf", ".jpg", ".png"],
};

const EventDetailsPage = (props) => {
    const eventContext = useContext(EventContext);
    const customerContext = useContext(CustomerContext);
    const jobContext = useContext(JobContext);
    const eventId = props?.match?.params?.id;
    const eventModal = useModal();
    const history = useHistory();

    const deleteEventDetailsPage = () => {
        return deleteEvent(eventId).then(() => {
            eventContext.setEventId(undefined);
            history.push(redirectUri);
        });
    };

    //? Load in the event
    useEffect(() => {
        eventContext.setEventId(eventId);
    }, [eventId]);

    //? When going to the details page, we want to load in the Customer and the Job if the Event has them
    useEffect(() => {
        customerContext.setCustomerId(eventContext.event.customer?.id);
        jobContext.setJobId(eventContext.event.job?.id);
    }, [eventContext.event]);

    const redirectUri = useMemo(() => {
        const uri = eventContext.event.customer
            ? `/customer/${eventContext.event.customer.id}`
            : "/scheduler/";

        return eventContext.event.job
            ? `${uri}/job/${eventContext.event.job.id}`
            : uri;
    }, [eventContext.event]);

    return (
        <div>
            <EventModal {...eventModal} />
            <header className="vendor-name-header JustifyLeftAlignLeft FlexColumn SmallGap">
                <span>
                    {`${eventContext.event.eventDateTitle} ${
                        eventContext.event.eventDate
                            ? `${dayjs
                                  .utc(eventContext.event.eventDate)
                                  .format("(MM/DD/YYYY)")}`
                            : ""
                    }`}
                </span>
                <span>{eventContext.event?.eventType?.name}</span>
            </header>
            <div className={"DisplayFlex AlignItemsEnd JustifyRight SmallGap"}>
                <GridCellWrapper className={"JustifyCenterAndAlignCenter"}>
                    <ActionCell
                        additionalActions={[
                            {
                                permissions: [PermissionsEnum.ViewCustomer],
                                buttonText: "Go To Customer",
                                onClick: () =>
                                    history.push(
                                        `/customer/${eventContext.event.customer?.id}/`
                                    ),
                                disabled: !eventContext.event.customer,
                                disabledToolTipMessage:
                                    "No Customer for this Event was found",
                                icon: "k-icon k-i-undo",
                            },
                            {
                                permissions: [PermissionsEnum.ViewJob],
                                buttonText: "Go To Job",
                                onClick: () =>
                                    history.push(
                                        `/customer/${eventContext.event.customer?.id}/job/${eventContext.event.job?.id}/`
                                    ),
                                disabled: !eventContext.event.job,
                                disabledToolTipMessage:
                                    "No Job for this Event was found",
                                icon: "k-icon k-i-undo",
                            },
                        ]}
                    />
                    <ActionCell
                        onEdit={() => eventModal.open(eventId)}
                        onDelete={deleteEventDetailsPage}
                        permissions={{
                            edit: [PermissionsEnum.UpdateCalendarEvent],
                            delete: [PermissionsEnum.DeleteCalendarEvent],
                        }}
                    />
                </GridCellWrapper>
            </div>
            <Card
                style={{
                    border: "none",
                }}
            >
                <EventTabs />
            </Card>
            <div className={styles.EventDetailMainContainer}>
                <EventTypeDatesPortlet {...props} />
                <div className={styles.EventDetailsRightContainer}>
                    <div className={styles.EventDetailRow}>
                        <AppointmentTimePortlet {...props} />
                        <SpecificationPortlet {...props} />
                        <EventTasksAndCategoriesPortlets {...props} />
                        <EventLaborersPortlet {...props} />
                        <EventLoggedTimePortlet {...props} />
                        <div style={{ flexGrow: 1, width: "50%" }}>
                            <FileManager
                                disabled={false}
                                allowedExtensions={fileRestrictions}
                                saveUri={"/General/File/UploadFile"}
                                removeUri={"/General/File/DeleteFile"}
                                readUri={"/General/File/GetDirectoryContents"}
                                location={`EventFiles-${eventId}`}
                            />
                        </div>
                        <EventNotesPortlet {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventDetailsWrapper;
