import React, { useContext, useEffect, useState } from "react";
import { Card, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useHistory } from "react-router-dom";
import { CustomerContext } from "../../../providers/Deprecated/Customer/CustomerProvider";
import { useModal } from "../../../hooks/useModal";
import { getJobEvents } from "../../../services/Deprecated/customer/eventServices";
import { deleteCustomer } from "../../../services/Deprecated/customer/customerServices";
import { CustomerModal } from "./Components/CustomerModal";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import CustomerTabs from "./Components/CustomerTabs";
import ActiveJobsList from "../Jobs/Components/ActiveJobsList";
import DeadJobsList from "../Jobs/Components/DeadJobsList";
import ArchivedJobsList from "../Jobs/Components/ArchivedJobsList";
import { ActionButton } from "../../../components/Buttons/ActionButton";

const CustomerDetailsPage = (props) => {
    const history = useHistory();
    const editModal = useModal();
    const customerContext = useContext(CustomerContext);
    const customerId = props?.match?.params?.id;
    const [customerItemsTabSelected, setCustomerItemsTabSelected] =
        React.useState(0);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        customerContext.setCustomerId(customerId);
    }, [customerId]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await getJobEvents();
                setEvents(response.data);
            } catch (error) {
                console.error("Failed to fetch events", error);
            }
        };

        fetchEvents();
    }, []);

    const isDeletable = () => {
        return !events.some((event) => event.customerId === customerId);
    };

    const deleteCustomerViewPage = async () => {
        try {
            if (!isDeletable()) {
                alert("Tip: Cannot delete customer with events.");
                return;
            }
            await deleteCustomer(customerId);
            customerContext.setCustomerId(undefined);
            history.push("/Customer");
        } catch (error) {
            console.error("Error deleting customer:", error);
        }
    };

    const gotoCustomers = () => {
        history.push(`/Customer`);
    };

    return (
        <div>
            <CustomerModal {...editModal} />
            <header className="vendor-name-header">
                {customerContext.customer.id ? (
                    customerContext.customer.combinedName
                ) : (
                    <CenterLoader />
                )}
            </header>
            <div className={"JustifyRightAlignCenter"}>
                <ActionButton
                    buttonText={"Go To Customers"}
                    icon={"k-icon k-i-undo"}
                    onClick={() => gotoCustomers()}
                />
                <ActionButton
                    buttonText={"Delete"}
                    onClick={() => deleteCustomerViewPage}
                    needsConfirmation={true}
                    confirmationText={
                        "Are you sure you want to delete this customer?"
                    }
                    permissions={[PermissionsEnum.DeleteCustomer]}
                />
                <ActionButton
                    buttonText={"Edit"}
                    onClick={() => editModal.open(customerId)}
                    permissions={[PermissionsEnum.UpdateCustomer]}
                />
            </div>
            <div className="tab-container">
                <Card className="card-container" style={{ border: "none" }}>
                    <CustomerTabs />
                </Card>
            </div>
            <div className="k-flex-100 flex-container">
                <div className="content-wrapper">
                    <Card className="card-container" style={{ border: "none" }}>
                        <TabStrip
                            selected={customerItemsTabSelected}
                            onSelect={(e) =>
                                setCustomerItemsTabSelected(e.selected)
                            }
                        >
                            <TabStripTab
                                title="Active Jobs"
                                contentClassName={"force-content-flex"}
                            >
                                <ActiveJobsList />
                            </TabStripTab>
                            <TabStripTab
                                title="Dead Jobs"
                                contentClassName={"force-content-flex"}
                            >
                                <DeadJobsList />
                            </TabStripTab>
                            <TabStripTab
                                title="Archived Jobs"
                                contentClassName={"force-content-flex"}
                            >
                                <ArchivedJobsList />
                            </TabStripTab>
                        </TabStrip>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default CustomerDetailsPage;
