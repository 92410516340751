import React, { useContext, useEffect, useState } from "react";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { PaymentContext } from "../../../../../providers/Deprecated/Customer/PaymentProvider";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { JobDocumentContext } from "../../../../../providers/Deprecated/Customer/JobDocumentProvider";
import { getAllDocuments } from "../../../../../services/Deprecated/customer/jobServices";
import { JobDocumentHelper } from "../../../../../resources/Deprecated/JobDocumentHelper";
import { multiJobDisburse } from "../../../../../services/Deprecated/customer/paymentServices";
import { Dialog } from "@progress/kendo-react-dialogs";
import { formatUSDCurrency } from "../../../../../resources/Deprecated/currencyHelper";
import { EnumCell } from "../../../../../components/GridColumnComponents/EnumCell";
import { NewJobDocumentTypeEnum } from "../../../../../resources/Enums/JobDocumentTypeEnum";
import { BaseCell } from "../../../../../components/GridColumnComponents/BaseCell";
import { FormButtons } from "../../../../../components/Buttons/FormButtons";

const JobApplyPaymentModal = (props) => {
    const { close, visible } = props;
    const { disbursingPayment } = useContext(PaymentContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const { refreshJobDocData } = useContext(JobDocumentContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [remainder, setRemainder] = useState(0);
    const [jobDocuments, setJobDocuments] = useState([]);

    /**
     * Set the remainder to the total amount of the payment
     */
    useEffect(() => {
        if (disbursingPayment) {
            setRemainder(disbursingPayment.remainder.amount);
        }
    }, [disbursingPayment]);

    /**
     * Handles the sorting of documents to display to the User.
     * We only want to display SalesOrders or Invoices, if a SO has
     * an Invoice then we do not want to show the SO.
     */
    useEffect(() => {
        // If the modal is not visible or there is no payment to disburse, return
        if (!visible || !disbursingPayment) return;

        getAllDocuments(
            `(type~eq~1~or~type~eq~2)~and~jobId~eq~'${disbursingPayment.job.id}'`
        ).then((res) => {
            // Sort documents to ensure invoices are always hashed first
            const documents = res.data.sort((a, b) => b.type - a.type);
            const hashMap = new Map();
            const sortedData = [];

            for (let i = 0; i < documents.length; i++) {
                // Skip if Document is already hashed, ensuring no duplicates
                if (hashMap.has(documents[i].documentNumber)) {
                    continue;
                } else {
                    hashMap.set(documents[i].documentNumber);
                }

                // Skip if the document has been fully paid
                if (
                    documents[i].totalPaid.amount === documents[i].total.amount
                ) {
                    continue;
                }

                sortedData.push({
                    ...documents[i],
                    remainder: JobDocumentHelper.getRemainder(documents[i]),
                    appliedAmount: 0,
                    inEdit: true, // Set edit to true so that all Items are editable in the Grid
                });
            }

            setJobDocuments(sortedData);
        });
    }, [visible, disbursingPayment]);

    /**
     * @function onSubmit
     * @description Handles the submission of the applied amounts to the documents
     * @returns {void}
     */
    const onSubmit = () => {
        setLoaderVisible(true);

        const formattedJobDocs = jobDocuments.map((doc) => {
            return {
                id: doc.id,
                appliedAmount: doc.appliedAmount,
            };
        });
        const formattedData = {
            documents: formattedJobDocs,
            paymentId: disbursingPayment.id,
        };

        multiJobDisburse(formattedData).then((_) => {
            triggerReload();
            refreshJobDocData(); // Reloads the Document portlets to show our changes
            onClose();
            setLoaderVisible(false);
        });
    };

    /**
     * @function onClose
     * @description Closes the modal and resets the state of the modal
     * @returns {void}
     */
    const onClose = () => {
        setRemainder(0);
        setJobDocuments([]);
        close();
    };

    /**
     * @function itemChange
     * @description Handles the change of the applied amount on the document
     * @param e - The event data
     * @returns {void}
     */
    const itemChange = (e) => {
        let newData = jobDocuments.map((item) => {
            if (item.id === e.dataItem.id) {
                // Ensures the applied amount is not greater than the remainder
                if (e.value > item.remainder) {
                    item.appliedAmount = item.remainder;
                    return item;
                }

                // Ensures the applied amount is not less than 0
                if (e.value < 0) {
                    item.appliedAmount = 0;
                    return item;
                }

                item.appliedAmount = e.value;
            }
            return item;
        });

        const totalApplied = newData.reduce(
            (acc, curr) => acc + curr.appliedAmount,
            0
        );

        setRemainder(disbursingPayment.remainder.amount - totalApplied);
        setJobDocuments(newData);
    };

    return (
        <>
            {visible && disbursingPayment && (
                <Dialog
                    title={"Enter amount(s) to apply to document(s)"}
                    onClose={onClose}
                    width={750}
                    height={475}
                >
                    <p className={"AlignTextRight"}>
                        Total Amount:{" "}
                        <strong>
                            {formatUSDCurrency(disbursingPayment.total.amount)}
                        </strong>
                    </p>
                    <Grid
                        style={{ minHeight: "200px" }}
                        data={jobDocuments}
                        editField={"inEdit"}
                        onItemChange={itemChange}
                    >
                        <GridNoRecords>
                            No Documents Present on Job
                        </GridNoRecords>
                        <GridColumn
                            field="documentNumber"
                            title="Document #"
                            width={125}
                            editable={false}
                            cell={(props) => (
                                <BaseCell {...props}>
                                    #{props.dataItem.documentNumber}
                                </BaseCell>
                            )}
                        />
                        <GridColumn
                            field="type"
                            title="Type"
                            width={125}
                            editable={false}
                            cell={(props) => (
                                <EnumCell
                                    {...props}
                                    enumerable={NewJobDocumentTypeEnum}
                                />
                            )}
                        />
                        <GridColumn
                            field="total.amount"
                            title="Total"
                            width={125}
                            format={"{0:c}"}
                            editable={false}
                        />
                        <GridColumn
                            field="remainder"
                            title="Amt. Remaining"
                            format={"{0:c}"}
                            width={125}
                            editable={false}
                        />
                        <GridColumn
                            field="appliedAmount"
                            title="Amt. To Apply"
                            editor="numeric"
                            format={"{0:c}"}
                            editable={true}
                        />
                    </Grid>
                    <p className={"AlignTextRight"}>
                        Remainder:{" "}
                        <strong
                            style={{ color: remainder < 0 ? "red" : "black" }}
                        >
                            {formatUSDCurrency(remainder)}
                        </strong>
                    </p>
                    <FormButtons
                        theme={"primary"}
                        allowSubmit={remainder >= 0}
                        buttonText={"Apply"}
                        actionOnCancel={onClose}
                        loaderVisible={loaderVisible}
                        onSubmit={onSubmit}
                    />
                </Dialog>
            )}
        </>
    );
};

export default JobApplyPaymentModal;
