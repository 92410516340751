import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { VendorDocumentType } from "../../../../../../resources/Enums/VendorDocumentType.js";
import VendorDocumentService from "../../../../../../services/VendorDocumentService.js";
import { VendorDocumentModalHelper } from "../Helpers/VendorDocumentModalHelper.js";
import { AuthContext } from "../../../../../../providers/Deprecated/Authentication/User/AuthProvider.jsx";
import { JobDocumentContext } from "../../../../../../providers/Deprecated/Customer/JobDocumentProvider.jsx";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider.jsx";

export const VendorDocumentModalContext = createContext(null);

const VendorDocumentModalProvider = ({ children }) => {
    const { user } = useContext(AuthContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const jobDocumentContext = useContext(JobDocumentContext);
    const [reloadItemGridKey, setReloadItemGridKey] = useState(Math.random());
    const [reloadDocumentKey, setReloadDocumentKey] = useState(Math.random());
    const [formData, setFormData] = useState(undefined);
    const lineItems = useRef([]);
    const [canEdit, setCanEdit] = useState(false);
    const [canReceive, setCanReceive] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [submitLoaderVisible, setSubmitLoaderVisible] = useState(false);
    const closeAfterSubmit = useRef(false);
    const service = useRef(new VendorDocumentService());
    const [modalState, setModalState] = useState({});

    // Initialize FormData and LineItems
    useEffect(() => {
        if (!modalState.visible) return;

        if (modalState.id) {
            service.current.get(modalState.id).then((res) => {
                const data = VendorDocumentModalHelper.formatDataForModal(
                    res.data
                );
                lineItems.current = data.lineItems;
                delete data.lineItems;

                setFormData(data);
            });
        } else {
            const modalData = VendorDocumentModalHelper.formatDataForModal(
                modalState.record
            );
            modalData.orderedBy = user.activeEmployee;
            setFormData(modalData);
        }
    }, [modalState.visible, modalState.id]);

    // Editable status
    useEffect(() => {
        if (formData) {
            const { type, childVendorDocuments = [] } = formData;
            const hasChildren = childVendorDocuments.length > 0;
            setCanEdit(
                type === VendorDocumentType.Enum.PurchaseOrder && !hasChildren
            );
            setCanReceive(
                type === VendorDocumentType.Enum.ItemReceipt && !hasChildren
            );
            setCanDelete(
                !hasChildren &&
                    modalState?.id &&
                    !lineItems.current.some((i) => i.quantityReceived > 0)
            );
        }
    }, [formData]);

    const onModalClose = () => {
        setFormData(undefined);
        setSubmitLoaderVisible(false);
        lineItems.current = [];
        closeAfterSubmit.current = false;
        modalState.close();
    };

    const onModalSubmit = (e) => {
        setSubmitLoaderVisible(true);

        const values = VendorDocumentModalHelper.formatDataForNetwork({
            ...e.values,
            lineItems: lineItems.current,
        });
        const request = modalState.id
            ? service.current.update(values)
            : service.current.create(values);
        request.then(() => {
            if (jobDocumentContext) {
                jobDocumentContext.refreshJobDocData();
            } else {
                triggerReload();
            }

            setSubmitLoaderVisible(false);

            if (closeAfterSubmit.current) {
                onModalClose();
            }
        });
    };

    const onDelete = () => {
        service.current.delete(modalState.id).then((res) => {
            if (jobDocumentContext) {
                jobDocumentContext.refreshJobDocData();
            } else {
                triggerReload();
            }

            if (res.success) {
                onModalClose();
            }
        });
    };

    const onChangeStatus = (status) => {
        service.current.changeStatus(modalState.id, status).then((res) => {
            if (res.success) {
                triggerReload();
                onModalClose();
            }
        });
    };

    // Reloads the item grid
    const reloadItemGrid = () => {
        setReloadItemGridKey(Math.random());
    };

    // Reloads the document information
    const reloadDocumentInformation = () => {
        setReloadDocumentKey(Math.random());
    };

    return (
        <VendorDocumentModalContext.Provider
            value={{
                reloadItemGridKey,
                reloadItemGrid,
                reloadDocumentKey,
                reloadDocumentInformation,
                formData,
                setFormData,
                canEdit,
                canReceive,
                canDelete,
                lineItems,
                setModalState,
                modalState,
                onModalClose,
                onModalSubmit,
                onDelete,
                submitLoaderVisible,
                closeAfterSubmit,
                onChangeStatus,
            }}
        >
            {children}
        </VendorDocumentModalContext.Provider>
    );
};

export default VendorDocumentModalProvider;
