import React from "react";
import JobDocumentPortlet from "./JobDocumentPortlet";
import VendorDocumentProvider from "../../../../providers/Deprecated/Inventory/Vendor/VendorDocumentProvider";

const JobTransactions = () => {
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                    maxWidth: "1800px",
                    gap: "20px",
                }}
            >
                <JobDocumentPortlet portletName={"Estimates"} jobDocType={0} />
                <JobDocumentPortlet
                    portletName={"Sales Orders"}
                    jobDocType={1}
                />
                <JobDocumentPortlet portletName={"Invoices"} jobDocType={2} />
                <VendorDocumentProvider>
                    <JobDocumentPortlet
                        portletName={"Purchase Orders"}
                        jobDocType={3}
                    />
                </VendorDocumentProvider>
            </div>
        </div>
    );
};

export default JobTransactions;
