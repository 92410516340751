import React from "react";
import dayjs from "dayjs";
import StringHelper from "../../../resources/StringHelper.js";
import { VendorDocumentType } from "../../../resources/Enums/VendorDocumentType.js";

/**
 * @function DocumentInformationDisplay
 * @description Displays information for a Document
 * @param props.formData {object} - the data for the document
 * @return {React.JSX.Element}
 * @constructor
 */
export const DocumentInformationDisplay = (props) => {
    const { formData } = props;

    const displayType = () => {
        const typeStr = VendorDocumentType.ParseToString(formData.type);
        return StringHelper.addSpacesToCapitalLetters(typeStr);
    };

    return (
        <div className={`JustifyLeftAlignLeft FlexColumn MediumGap`}>
            <span
                className={"JustifyLeftAlignLeft SmallGap"}
                style={{ fontSize: 32 }}
            >
                <strong>{displayType()}: </strong>
                <span>(#{formData.documentNumber})</span>
            </span>
            <span style={{ fontSize: 16, color: "#666666" }}>
                Date: {dayjs(formData.date).format("MM/DD/YYYY")}
            </span>
        </div>
    );
};
