import React, { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import clone from "just-clone";
import { GridLayout } from "@progress/kendo-react-layout";
import { DetailItemInformationFormExtension } from "./DetailItemInformationFormExtension";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import DetailItemService from "../../../../../services/Deprecated/customer/DetailItemService";
import ResponsiveDialog from "../../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../../components/Buttons/FormButtons";

const DialogTitleEntity = "Detail Item";

/**
 * @function DetailItemModal
 * @description Modal for creating and updating labor items
 * @param props.visible {boolean} - Determines if the modal is visible
 * @param props.close {function} - Function to close the modal
 * @param props.id {string} - The id of the labor item
 * @param props.isCreate {boolean} - Determines if the item is being created
 * @param props.isUpdate {boolean} - Determines if the item is being updated
 * @param [props.categoryId] {string} - The id of the category
 * @return {React.JSX.Element}
 * @constructor
 */
export const DetailItemModal = (props) => {
    const {
        visible,
        close,
        id,
        isCreate,
        isUpdate,
        categoryId = undefined,
    } = props;
    const [initFormValues, setInitFormValues] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const { triggerReload } = useContext(ReloadDataContext);

    // Handles initializing the form values
    useEffect(() => {
        if (visible === false) return;

        if (isUpdate) {
            DetailItemService.get(id).then((response) => {
                setInitFormValues(response.data);
            });
        } else {
            setInitFormValues({
                isActive: true,
                retailPrice: {
                    amount: 0,
                    currency: "USD",
                },
            });
        }
    }, [visible]);

    const onFormSubmit = (dataItem) => {
        setLoaderVisible(true);
        const submissionData = clone(dataItem);

        submissionData.categoryId = categoryId ?? submissionData.category.id;
        submissionData.incomeAccountId = submissionData.incomeAccount.id;
        submissionData.expenseAccountId = submissionData.expenseAccount.id;

        if (isCreate) {
            DetailItemService.create(submissionData).then((_) =>
                successSubmit()
            );
        } else {
            DetailItemService.update(submissionData).then((_) =>
                successSubmit()
            );
        }
    };

    // Handles closing the form
    const closeForm = () => {
        setInitFormValues(undefined);
        close();
    };

    // Handles a successful form submission
    const successSubmit = () => {
        triggerReload();
        closeForm();
        setLoaderVisible(false);
    };

    return (
        visible &&
        initFormValues && (
            <Form
                initialValues={initFormValues}
                onSubmit={onFormSubmit}
                render={(formRenderProps) => (
                    <ResponsiveDialog
                        title={
                            isUpdate
                                ? `Edit ${DialogTitleEntity}`
                                : `Create ${DialogTitleEntity}`
                        }
                        onClose={closeForm}
                        size={"medium"}
                    >
                        <FormElement>
                            <GridLayout
                                rows={[
                                    { height: "auto" },
                                    { height: "auto" },
                                    { height: "auto" },
                                ]}
                                cols={[{ width: "48%" }, { width: "48%" }]}
                                gap={{ rows: 15, cols: 15 }}
                            >
                                <DetailItemInformationFormExtension />
                            </GridLayout>
                            <FormButtons
                                actionOnCancel={closeForm}
                                loaderVisible={loaderVisible}
                                isCreate={isCreate}
                                allowSubmit={formRenderProps.allowSubmit}
                            />
                        </FormElement>
                    </ResponsiveDialog>
                )}
            />
        )
    );
};
