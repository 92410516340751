import React, { useContext } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { useModal } from "../../../../hooks/useModal";
import { EventHelper } from "../Helpers/EventHelper";
import { updateEvent } from "../../../../services/Deprecated/customer/eventServices";
import SpecificationModal from "./SpecificationModal";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { ActionButton } from "../../../../components/Buttons/ActionButton";

const SpecificationPortlet = (props) => {
    const eventId = props?.match?.params?.id;
    const eventContext = useContext(EventContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const specModal = useModal();

    const onDelete = () => {
        const data = EventHelper.formatEventForNetwork(eventContext.event);
        data.specification = null;

        updateEvent(data).then((_) => {
            triggerReload();
        });
    };

    return (
        <Card
            style={{
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                minHeight: "129px",
            }}
        >
            <SpecificationModal {...specModal} record={eventContext.event} />
            <CardHeader
                className="k-hbox"
                style={{
                    background: "transparent",
                    width: "100%",
                }}
            >
                <CardTitle
                    style={{ width: "100%" }}
                    className={
                        "DisplayFlex JustifySpaceBetween AlignItemsCenter"
                    }
                >
                    <strong>Specification</strong>
                    <ActionButton
                        buttonText={"Create"}
                        icon={"k-icon k-i-plus"}
                        disabled={eventContext.event.specification}
                        onClick={() => specModal.open()}
                        disabledToolTipMessage={
                            "Cannot create a new specification when one already exists."
                        }
                    />
                </CardTitle>
            </CardHeader>
            <CardBody
                style={{
                    padding: "5px",
                    overflowY: "auto",
                }}
            >
                {eventContext.event.id === eventId &&
                    eventContext.event.specification && (
                        <Grid
                            data={[eventContext.event]}
                            style={{
                                height: "50px",
                            }}
                        >
                            <GridNoRecords> </GridNoRecords>
                            <GridColumn
                                field="specification"
                                title="Specification:"
                                headerClassName={"hide-element"}
                            />
                            <GridColumn
                                filterable={false}
                                sortable={false}
                                headerClassName={"hide-element"}
                                width={125}
                                cell={() => (
                                    <ActionCell
                                        onDelete={() => onDelete()}
                                        onEdit={() => specModal.open()}
                                        permissions={{
                                            edit: [
                                                PermissionsEnum.UpdateCalendarEvent,
                                            ],
                                            delete: [
                                                PermissionsEnum.UpdateCalendarEvent,
                                            ],
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    )}
            </CardBody>
        </Card>
    );
};

export default SpecificationPortlet;
