import React, { useContext, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { PaymentContext } from "../../../../providers/Deprecated/Customer/PaymentProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { JobDocumentContext } from "../../../../providers/Deprecated/Customer/JobDocumentProvider";
import {
    getAllDocuments,
    getAllJobs,
} from "../../../../services/Deprecated/customer/jobServices";
import { NewJobDocumentTypeEnum } from "../../../../resources/Enums/JobDocumentTypeEnum";
import { disbursePayment } from "../../../../services/Deprecated/customer/paymentServices";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { formatUSDCurrency } from "../../../../resources/Deprecated/currencyHelper";
import {
    FormDropDown,
    FormNumericInput,
} from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import styles from "../../Customers/Payments.module.scss";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";

const CreatePaymentDisbursementModal = ({ visible, setVisible }) => {
    const paymentContext = useContext(PaymentContext);
    const reloadDataContext = useContext(ReloadDataContext);
    const jobDocContext = useContext(JobDocumentContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [documentRemainder, setDocumentBalance] = useState(undefined);

    useEffect(() => {
        const disbursingPayment = paymentContext.disbursingPayment;

        //? Does the payment exist? If not do nothing
        if (disbursingPayment === undefined) {
            return;
        }

        //? Is this payment a job payment?
        if (disbursingPayment.job) {
            //? Get documents for job
            getAllDocuments(
                `(type~eq~1~or~type~eq~2)~and~jobId~eq~'${disbursingPayment.job.id}'`
            ).then((res) => {
                // Sort documents to ensure invoices are always hashed first
                const documents = res.data.sort((a, b) => b.type - a.type);
                const hashMap = new Map();
                const formattedData = [];

                for (let i = 0; i < documents.length; i++) {
                    // Skip if Document is already hashed, ensuring no duplicates
                    if (hashMap.has(documents[i].documentNumber)) {
                        continue;
                    } else {
                        hashMap.set(documents[i].documentNumber);
                    }

                    const enumParsed = NewJobDocumentTypeEnum.ParseToString(
                        documents[i].type
                    );
                    const formattedName = `${enumParsed}: ${documents[i].name} - #${documents[i].documentNumber}`;

                    formattedData.push({
                        name: formattedName,
                        id: documents[i].id,
                        remainder: documents[i].total,
                    });
                }

                setDocuments(formattedData);
            });
        } else {
            //? Get jobs for customer
            getAllJobs(`customerId~eq~'${disbursingPayment.customer.id}'`).then(
                (jobs) => {
                    setJobs(jobs.data);
                }
            );
        }
    }, [paymentContext.disbursingPayment]);

    /**
     * Function called when the cancel action is triggered.
     * It sets the "setVisible" state variable to "false".
     *
     * @function
     * @name onCancel
     * @return {void}
     */
    const onCancel = () => {
        setVisible(false);
        setDocumentBalance(undefined);
    };

    /**
     * Submits data to disburse payment.
     * @param {object} dataItem - The data to be submitted.
     * @returns {Promise} A promise that resolves once the payment is disbursed.
     */
    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        dataItem.customerId = paymentContext.disbursingPayment.customer.id;
        dataItem.jobId =
            dataItem.job?.id ?? paymentContext.disbursingPayment.job.id;
        dataItem.documentId = dataItem?.document?.id ?? undefined;
        dataItem.paymentId = paymentContext.disbursingPayment.id;
        dataItem.amount = dataItem.total.amount;

        disbursePayment(dataItem).then(() => {
            reloadDataContext.triggerReload();
            setVisible(false);
            setLoaderVisible(false);

            if (jobDocContext) {
                jobDocContext.refreshJobDocData();
            }
        });
    };

    /**
     * Validates a disbursement value against the payment context.
     * @param {number} value - The disbursement amount to be validated.
     * @returns {string|undefined} - The error message if the disbursement is invalid, undefined otherwise.
     */
    const disbursementValidator = (value) => {
        if (value > paymentContext.disbursingPayment.remainder)
            return "Amount must not exceed remaining balance.";
        if (documentRemainder && value > documentRemainder)
            return "Amount must not exceed remaining document balance";
    };

    return (
        <div>
            {visible && (
                <Form
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Apply Payment"}
                            onClose={() => onCancel()}
                            size={"medium"}
                        >
                            <FormElement>
                                {/** If payment is a Job Payment show documents to disburse to, else show jobs **/}
                                {paymentContext.disbursingPayment.job ? (
                                    <>
                                        <strong>
                                            Document Balance:{" "}
                                            {formatUSDCurrency(
                                                documentRemainder ?? 0
                                            )}
                                        </strong>
                                        <Field
                                            name={"document"}
                                            component={FormDropDown}
                                            validator={requiredValidator}
                                            data={documents}
                                            dataItemKey="id"
                                            textField="name"
                                            label={"Document:"}
                                            onChange={(c) =>
                                                setDocumentBalance(
                                                    c.value.remainder.amount
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <Field
                                        name={"job"}
                                        component={FormDropDown}
                                        validator={requiredValidator}
                                        data={jobs}
                                        dataItemKey="id"
                                        textField="name"
                                        label={"Job:"}
                                    />
                                )}
                                <Field
                                    name={"total.amount"}
                                    component={FormNumericInput}
                                    validator={disbursementValidator}
                                    format={"c2"}
                                    label={"Amount:"}
                                />
                                {!loaderVisible ? (
                                    <div
                                        className={
                                            styles.DisbursePaymentBtnGrouping
                                        }
                                    >
                                        <Button
                                            themeColor={"primary"}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            onClick={onCancel}
                                            className={"modal-action-buttons"}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            themeColor={"success"}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            className={"modal-action-buttons"}
                                            icon={"k-icon k-i-plus"}
                                            disabled={
                                                !formRenderProps.allowSubmit
                                            }
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                ) : (
                                    <CenterLoader />
                                )}
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default CreatePaymentDisbursementModal;
