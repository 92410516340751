import { ItemTypeEnum } from "../../../../resources/Enums/ItemTypeEnum";

export class JobDocumentHelper {
    static changeItemConditional(field, event, updateValid) {
        switch (field) {
            case "quantity":
                return this.#quantityConditional(event, updateValid);
            case "price.amount":
                return this.#priceConditional(event, updateValid);
            default:
                return true;
        }
    }

    static canEnterEditConditional(field, dataItem) {
        switch (field) {
            case "description":
                return (
                    dataItem.item.isDescriptionEditAllowed ||
                    dataItem.type !== ItemTypeEnum.Item
                );
            case "price.amount":
                return dataItem.type !== ItemTypeEnum.Note;
            case "quantity":
                return dataItem.type === ItemTypeEnum.Item;
            default:
                return true;
        }
    }

    static formatJobDocumentLineItems(jobDocumentLineItems) {
        return jobDocumentLineItems.map((lineItem, index) => {
            lineItem.index = index;

            if (
                lineItem.type !== ItemTypeEnum.Item &&
                lineItem.type !== ItemTypeEnum.Discount
            ) {
                lineItem.quantity = 0;
            }
            switch (lineItem.type) {
                case ItemTypeEnum.Note:
                    lineItem.item = { name: "---NOTE---" };
                    break;
                case ItemTypeEnum.Discount:
                    lineItem.item = { name: "---DISCOUNT---" };
                    break;
            }
            lineItem.expanded = lineItem.children.length > 0;
            return lineItem;
        });
    }

    //?
    //? Private Methods
    //?
    static #quantityConditional(event, updateValid) {
        if (event.value < 1) {
            updateValid = false;
        } else {
            event.dataItem.total.amount =
                event.value * event.dataItem.price.amount;
        }

        return updateValid;
    }

    static #priceConditional(event, updateValid) {
        if (event.value < 0 && event.dataItem.type === ItemTypeEnum.Item) {
            updateValid = false;
        } else {
            event.dataItem.total.amount =
                event.value * (event.dataItem.quantity ?? 1);
        }

        return updateValid;
    }
}

/**
 * Moves an item in an array up or down, with special rules for DocumentDiscount and ItemDiscount.
 * @param {Array} array - The array containing items.
 * @param {Object} item - The item to be moved.
 * @param {string} direction - The direction to move ('up' or 'down').
 * @returns {Array|null} - The array with the item moved, or null if the move is not possible.
 */
export const moveItemInArray = (array, item, direction) => {
    // Finding the index of the item to be moved here.
    const index = array.findIndex((i) => i.index === item.index);

    // Returning null if the item is not found or cannot be moved.
    if (index === -1) {
        console.warn("Item not found in array.");
        return null;
    }
    if (item.type === "DocumentDiscount") {
        console.warn("Cannot move DocumentDiscount.");
        return null;
    }

    // Calculate the new index if the item was moved up or down
    let newIndex = direction === "up" ? index - 1 : index + 1;

    // Just returning null if the new index is outside the array bounds
    if (newIndex < 0 || newIndex >= array.length) {
        console.warn("Cannot move item outside of array bounds.");
        return null;
    }

    // Implemented special handling for moving Item relative to ItemDiscount
    if (item.type === "Item" && array[newIndex].type === "Discount") {
        if (direction === "down") {
            newIndex++;
        } else if (index > 0 && array[index - 1].type === "Discount") {
            newIndex--;
        }
    }

    // Ensuring the new index is still within bounds after adjustments
    if (newIndex < 0 || newIndex >= array.length) {
        console.warn("Adjusted position is outside of array bounds.");
        return null;
    }

    // Swap the items in the array here
    [array[index], array[newIndex]] = [array[newIndex], array[index]];

    // Return a copy of the array with the item moved
    return array.slice();
};
