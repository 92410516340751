import React, { useContext, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import CreatePaymentDisbursementModal from "./CreatePaymentDisbursementModal";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import styles from "../../Customers/Payments.module.scss";
import { PaymentContext } from "../../../../providers/Deprecated/Customer/PaymentProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { JobDocumentContext } from "../../../../providers/Deprecated/Customer/JobDocumentProvider";
import { deletePayment } from "../../../../services/Deprecated/customer/paymentServices";
import ConfirmDialogue from "../../../../components/Deprecated/ConfirmDialogue";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { EnumCell } from "../../../../components/GridColumnComponents/EnumCell";
import { CustomDateCell } from "../../../../components/Deprecated/CustomGridCells";
import { formatUSDCurrency } from "../../../../resources/Deprecated/currencyHelper";
import { NewJobDocumentTypeEnum } from "../../../../resources/Enums/JobDocumentTypeEnum";

const DisbursementListModal = () => {
    const paymentContext = useContext(PaymentContext);
    const reloadDataContext = useContext(ReloadDataContext);
    const jobDocContext = useContext(JobDocumentContext);
    const [visible, setVisible] = useState(false);
    const [delCfirmVisible, setDelCfirmVisible] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [deletingPaymentId, setDeletingPaymentId] = useState(undefined);
    //? Is the parent disbursed from a job? If so we want the invoices to be displayed
    const titleField = paymentContext.isJobPayment ? "Document:" : "Job:";
    const formTitleField = paymentContext.isJobPayment
        ? "jobDocument.name"
        : "job.name";

    const onCancel = () => paymentContext.setDisbursingPaymentId(undefined);

    const onDeleteConfirm = () => {
        setLoaderVisible(true);

        deletePayment(deletingPaymentId).then(() => {
            setDelCfirmVisible(false);
            setLoaderVisible(false);
            //! Possible refactor opportunity, currently also reloads the customer, and jobs
            reloadDataContext.triggerReload();

            if (jobDocContext) {
                jobDocContext.refreshJobDocData();
            }
        });
    };

    const handleDeleteClick = (paymentId) => {
        setDeletingPaymentId(paymentId);
        setDelCfirmVisible(true);
    };

    return (
        <div>
            {paymentContext.disbursingPayment && (
                <>
                    <ConfirmDialogue
                        visible={delCfirmVisible}
                        onConfirm={() => onDeleteConfirm()}
                        onCancel={() => setDelCfirmVisible(false)}
                        loaderVisible={loaderVisible}
                        actionName={"Delete"}
                    />
                    <CreatePaymentDisbursementModal
                        visible={visible}
                        setVisible={setVisible}
                    />
                    <ResponsiveDialog
                        title={"Applied Payments"}
                        onClose={() => onCancel()}
                        size={"large"}
                    >
                        <span className={styles.DisbursementGridList}>
                            <Grid
                                data={
                                    paymentContext.disbursingPayment
                                        .childrenPayments
                                }
                                style={{ height: "200px" }}
                            >
                                {paymentContext.isJobPayment && (
                                    <GridColumn
                                        field={"jobDocument.type"}
                                        title={"Type:"}
                                        width={150}
                                        cell={(props) => (
                                            <EnumCell
                                                {...props}
                                                enumerable={
                                                    NewJobDocumentTypeEnum
                                                }
                                            />
                                        )}
                                    />
                                )}
                                <GridColumn
                                    field={formTitleField}
                                    title={titleField}
                                    className={"text-overflow"}
                                />
                                <GridColumn
                                    field={"total.amount"}
                                    title={"Amount:"}
                                    format={"{0:c}"}
                                />
                                <GridColumn
                                    field="date"
                                    title="Date:"
                                    width={100}
                                    cell={(props) => (
                                        <CustomDateCell
                                            {...props}
                                            data={props.dataItem.date}
                                        />
                                    )}
                                />
                                <GridColumn
                                    width={45}
                                    cell={(props) => {
                                        return (
                                            <td
                                                className={
                                                    props.dataItem
                                                        .hasBeenDisbursed
                                                        ? "hide-element"
                                                        : ""
                                                }
                                            >
                                                <span
                                                    className={`k-icon k-i-reset ${styles.RevertPaymentBtn}`}
                                                    onClick={() =>
                                                        handleDeleteClick(
                                                            props.dataItem.id
                                                        )
                                                    }
                                                />
                                            </td>
                                        );
                                    }}
                                />
                            </Grid>
                            <span className={styles.DisbursementBtnGrouping}>
                                <strong>
                                    Remaining:{" "}
                                    {formatUSDCurrency(
                                        paymentContext.disbursingPayment
                                            .remainder.amount
                                    )}
                                </strong>
                                <Button
                                    themeColor={"success"}
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    className={"modal-action-buttons"}
                                    icon={"k-icon k-i-plus"}
                                    onClick={() => setVisible(true)}
                                    disabled={
                                        paymentContext.disbursingPayment
                                            .remainder.amount === 0
                                    }
                                >
                                    Apply
                                </Button>
                            </span>
                        </span>
                    </ResponsiveDialog>
                </>
            )}
        </div>
    );
};

export default DisbursementListModal;
