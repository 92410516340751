import * as React from "react";
import { useContext } from "react";
import {
    SchedulerItem,
    SchedulerItemContent,
} from "@progress/kendo-react-scheduler";
import SchedulerTimeClockViewModal from "./SchedulerTimeClockViewModal";
import { PrintCalendarEventCardModal } from "./PrintCalendarEventCardModal/PrintCalendarEventCardModal";
import { BreadcrumbContext } from "../../../../providers/BreadCrumbProvider";
import { useModal } from "../../../../hooks/useModal";
import { utcToLocalFormatter } from "../../../../resources/Deprecated/stringFormatter";

export const CustomSchedulerItem = (props) => {
    const bcContext = useContext(BreadcrumbContext);
    const printModal = useModal();

    const appointmentTimeDisplay = () => {
        if (!props.isAllDay) {
            return `${utcToLocalFormatter(
                props.dataItem.appointmentTime.startTime,
                "h:mm A"
            )} - ${utcToLocalFormatter(
                props.dataItem.appointmentTime.endTime,
                "h:mm A"
            )}`;
        }
    };

    const jobDocumentDisplay = () => {
        if (props.dataItem.jobDocument) {
            return props.dataItem.jobDocument.name;
        }
    };

    const laborersDisplay = () => {
        const timeClockEntryModal = useModal();
        const onLaborerClick = (laborer, eventId) => {
            timeClockEntryModal.open(undefined, {
                employeeId: laborer.id,
                employeeName: laborer.name,
                calendarEventId: eventId,
            });
        };

        if (props.dataItem.laborers.length === 0) {
            return (
                <strong style={{ color: "red" }}>
                    ***NO LABOR ASSIGNED***
                </strong>
            );
        } else {
            return (
                <>
                    <SchedulerTimeClockViewModal
                        {...timeClockEntryModal}
                        dateRange={{
                            start: props.dataItem.eventDate,
                            end: props.dataItem.eventDate,
                        }}
                    />
                    {props.dataItem.laborers.map((laborer, index) => (
                        <span
                            key={laborer.id}
                            className={"link"}
                            onClick={() =>
                                onLaborerClick(laborer, props.dataItem.id)
                            }
                        >
                            {laborer.name}
                            {index < props.dataItem.laborers.length - 1
                                ? ", "
                                : ""}
                        </span>
                    ))}
                </>
            );
        }
    };

    const gotoJob = (dataItem) =>
        bcContext.handleRouteChange({
            uri: `/customer/${dataItem.customer.id}/job/${dataItem.job.id}/`,
            breadcrumbInfo: [
                {
                    id: dataItem.customer.id,
                    text: dataItem.customer.name,
                },
                {
                    id: dataItem.job.id,
                    text: dataItem.job.name,
                },
            ],
        });

    const gotoCustomer = (dataItem) =>
        bcContext.handleRouteChange({
            uri: `/customer/${dataItem.customer.id}/`,
            breadcrumbInfo: [
                {
                    id: dataItem.customer.id,
                    text: dataItem.customer.name,
                },
            ],
        });

    const gotoEvent = (dataItem) =>
        bcContext.handleRouteChange({
            uri: `/event/${dataItem.id}/`,
            breadcrumbInfo: [
                {
                    id: dataItem.id,
                    text: dataItem.eventDateTitle ?? "Current Event",
                },
            ],
        });

    // Bit scuffed, but the idea is to force a double click to edit the event.
    // This is because the event card on double click will open the edit modal,
    // Doing this allows us to write less code.
    const forceDoubleClickForEdit = (id) => {
        const eventCard = document
            .getElementById(`${id}-openEventLink`)
            .closest("div.k-event");

        const mouseEvent = new MouseEvent("dblclick", {
            view: window,
            bubbles: true,
            cancelable: true,
        });

        eventCard.dispatchEvent(mouseEvent);
    };

    return (
        <SchedulerItem {...props}>
            <SchedulerItemContent>
                <PrintCalendarEventCardModal {...printModal} />
                <span
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                        gap: "2px",
                        color: "black",
                    }}
                >
                    <strong
                        className={"link"}
                        onClick={() => gotoEvent(props.dataItem)}
                    >
                        {props.dataItem.eventDateTitle}
                    </strong>
                    {props.dataItem.customer ? (
                        <strong
                            className={"link"}
                            onClick={() => gotoCustomer(props.dataItem)}
                        >
                            {props.dataItem.customer.name}
                        </strong>
                    ) : (
                        <strong
                            id={`${props.dataItem.id}-openEventLink`}
                            className={"link"}
                            onClick={() =>
                                forceDoubleClickForEdit(props.dataItem.id)
                            }
                        >
                            {!props.dataItem.eventType.isAssociatedToCoA ? (
                                <>***OPEN EVENT***</>
                            ) : (
                                <>{props.dataItem.eventType.name}</>
                            )}
                        </strong>
                    )}
                    <strong
                        className={"link"}
                        onClick={() => gotoJob(props.dataItem)}
                    >
                        {props.dataItem.job?.name}
                    </strong>
                    <span>{appointmentTimeDisplay()}</span>
                    <span>{jobDocumentDisplay()}</span>
                    <div>{laborersDisplay()}</div>
                    {props.dataItem.specification && (
                        <div>
                            <strong>Specs: </strong>
                            {props.dataItem.specification}
                        </div>
                    )}
                    <i
                        className={"k-icon k-i-print"}
                        onClick={() => printModal.open(props.dataItem.id)}
                    />
                </span>
            </SchedulerItemContent>
        </SchedulerItem>
    );
};
