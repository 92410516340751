import React, { useCallback, useContext, useEffect, useState } from "react";
import { Scheduler, TimelineView } from "@progress/kendo-react-scheduler";
import { Day } from "@progress/kendo-date-math";
import SchedulerProvider, {
    SchedulerContext,
} from "../../../providers/Deprecated/Customer/SchedulerProvider";
import { CustomerContext } from "../../../providers/Deprecated/Customer/CustomerProvider";
import { JobContext } from "../../../providers/Deprecated/Customer/JobProvider";
import { startOfWeek } from "../../../resources/Deprecated/dateHelper";
import {
    parseEvents,
    parseEventTypes,
} from "../../../resources/Deprecated/schedulerHelper";
import { CustomSchedulerViewSlot } from "./Components/CustomSchedulerViewSlot";
import { CustomSchedulerItem } from "./Components/CustomSchedulerItem";
import { CustomSchedulerViewItem } from "./Components/CustomSchedulerViewItem";
import { CustomSchedulerHeader } from "./Components/CustomSchedulerHeader";
import { CustomEditItem } from "./Components/CustomSchedulerEditItem";
import { CustomDateHeaderCellTimeLine } from "./Components/CustomDateHeaderCellTimeLine";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";

//? Give us access to the Provider without having to load it in every component
//?
const SchedulerWrapper = () => {
    return (
        <SchedulerProvider>
            <SchedulerDashboardPage />
        </SchedulerProvider>
    );
};

//? This is the actual component that will be rendered
//?
const SchedulerDashboardPage = () => {
    const schedulerContext = useContext(SchedulerContext);
    const customerContext = useContext(CustomerContext);
    const jobContext = useContext(JobContext);
    const [date, setDate] = useState(startOfWeek(new Date()));
    const [columnWidth, _setColumnWidth] = useState(180);

    useEffect(() => {
        customerContext.setCustomerId(undefined);
        jobContext.setJobId(undefined);
    }, []);

    /**
     * @function onDateChange
     * @description Takes in Date Change Event and sets event accordingly
     * @param {Object} event - Event Object
     */
    const onDateChange = (event) => {
        const eventDate = new Date(event.value);
        setDate(eventDate);
        schedulerContext.setCurrentDate(eventDate);
    };

    const SchedulerCallback = useCallback(() => {
        if (schedulerContext.eventsLoading) {
            return <></>;
        }

        return (
            <Scheduler
                data={parseEvents(schedulerContext.events)}
                onDateChange={onDateChange}
                defaultDate={date}
                date={date}
                height={"auto"}
                viewSlot={CustomSchedulerViewSlot}
                item={CustomSchedulerItem}
                viewItem={CustomSchedulerViewItem}
                style={{
                    minHeight: "50vh",
                    fontSize: "smaller",
                    wordWrap: "break-word",
                    fontStyle: "italic",
                }}
                footer={() => <React.Fragment />}
                header={(props) => <CustomSchedulerHeader {...props} />}
                group={{
                    resources: ["EventType"],
                    orientation: "vertical",
                }}
                resources={[
                    {
                        name: "EventType",
                        data: parseEventTypes(
                            schedulerContext.relationships.eventTypes
                        ),
                        field: "eventTypeId",
                        valueField: "value",
                        textField: "text",
                        colorField: "color",
                    },
                ]}
                editable={{
                    add: false,
                    remove: true,
                    drag: false,
                    resize: false,
                    edit: false,
                }}
                editItem={CustomEditItem}
            >
                <TimelineView
                    dateHeaderCell={CustomDateHeaderCellTimeLine}
                    columnWidth={columnWidth}
                    currentTimeMarker={false}
                    workWeekStart={Day.Monday}
                    numberOfDays={
                        schedulerContext.calendarType === "2 week" ||
                        schedulerContext.calendarType === "2 week work week"
                            ? 14
                            : 7
                    }
                    slotDuration={601}
                    slotDivisions={1}
                />
            </Scheduler>
        );
    }, [
        schedulerContext.relationshipsLoading,
        schedulerContext.eventsLoading,
        schedulerContext.calendarType,
    ]);

    return schedulerContext.relationshipsLoading ? (
        <CenterLoader />
    ) : (
        <SchedulerCallback />
    );
};

export default SchedulerWrapper;
