import React, { useContext } from "react";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { ErrorContext } from "../../providers/ErrorProvider.jsx";

const ErrorComponent = () => {
    const { errors, clearErrors } = useContext(ErrorContext);

    // Clear errors after 10 seconds
    //
    React.useEffect(() => {
        const timer = setTimeout(() => {
            clearErrors();
        }, 10000);
        return () => clearTimeout(timer);
    });

    return (
        <NotificationGroup
            style={{
                right: 10,
                bottom: 0,
            }}
        >
            <Fade>
                {errors &&
                    errors.map((error) => (
                        <Notification
                            key={error.title}
                            type={{
                                style: "error",
                                icon: true,
                            }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: "1rem",
                                width: "auto",
                                height: "40px",
                                fontSize: "1rem",
                            }}
                            closable={true}
                            onClose={() => clearErrors()}
                        >
                            <span>
                                {error.title} : {error.message}
                            </span>
                        </Notification>
                    ))}
            </Fade>
        </NotificationGroup>
    );
};

export default ErrorComponent;
