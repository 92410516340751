import * as React from "react";
import {useEffect, useState} from "react";
import {BaseCell} from "./BaseCell";
import {Input} from "@progress/kendo-react-inputs";

/**
 * @function EditableTextCell
 * @description A cell that allows for editing via a text box.
 * @param props.field {string} - The field to edit
 * @param props.dataItem {object} - The data item to edit
 * @param props.editField {string} - The field to watch for editing
 * @param props.onChange {function} - The function to call when the value changes
 * @return {Element}
 * @constructor
 */
export const EditableTextCell = (props) => {
    const {
        field = "",
        dataItem,
        editField,
        onChange,
        disabled,
        ...others
    } = props;
    const [value, setValue] = useState(dataItem[field]);

    useEffect(() => {
        setValue(dataItem[field]);
    }, [dataItem[field]]);

    const handleChange = (e) => {
        if (onChange) {
            onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
            setValue(e.target.value);
        }
    };

    return (
        <BaseCell {...others}>
            {dataItem[editField] ? (
                <Input
                    type="text"
                    value={value ?? ""}
                    onChange={handleChange}
                    disabled={disabled}
                />
            ) : (
                value
            )}
        </BaseCell>
    );
};
