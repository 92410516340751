import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { SchedulerHeader } from "@progress/kendo-react-scheduler";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { SchedulerContext } from "../../../../providers/Deprecated/Customer/SchedulerProvider";

export const CustomSchedulerHeader = (props) => {
    const schedulerContext = useContext(SchedulerContext);
    const [headerChildren, setHeaderChildren] = useState(props.children);
    const [buttonToggle, setButtonToggle] = useState({
        workWeek: schedulerContext.calendarType === "work week",
        twoWeekWorkWeek: schedulerContext.calendarType === "2 week work week",
        week: schedulerContext.calendarType === "week",
        twoWeek: schedulerContext.calendarType === "2 week",
    });

    const onButtonClick = (event) => {
        const buttonName = event.target.innerText;
        setButtonToggle({
            workWeek: buttonName === "Work Week",
            twoWeekWorkWeek: buttonName === "2 Week Work Week",
            week: buttonName === "Week",
            twoWeek: buttonName === "2 Week",
        });

        schedulerContext.setCalendarType(buttonName.toLowerCase());
    };

    // Remove the 'TimeLine' button from the Header.
    // if you have a better idea please implement it because this saddens me.
    useEffect(() => {
        if (props.children) {
            setHeaderChildren([
                props.children[0],
                props.children[1],
                props.children[2],
            ]);
        }
    }, [props.children]);

    return (
        <div>
            <SchedulerHeader {...props}>
                {headerChildren}
                <ButtonGroup>
                    <Button
                        selected={buttonToggle.week}
                        togglable={true}
                        onClick={onButtonClick}
                    >
                        Week
                    </Button>
                    <Button
                        selected={buttonToggle.twoWeek}
                        togglable={true}
                        onClick={onButtonClick}
                    >
                        2 Week
                    </Button>
                </ButtonGroup>
            </SchedulerHeader>
        </div>
    );
};
