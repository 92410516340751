import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import JobDetails from "./JobDetails";

const JobTabs = () => {
    const [selected, setSelected] = useState(0);

    return (
        <TabStrip
            selected={selected}
            onSelect={(e) => setSelected(e.selected)}
            className="tabs"
            style={{
                height: "430px",
                maxWidth: "1180px",
                width: "100%",
            }}
        >
            <TabStripTab title="Details">
                <JobDetails />
            </TabStripTab>
        </TabStrip>
    );
};

export default JobTabs;
