import React, { useContext, useState } from "react";
import { SchedulerEditItem } from "@progress/kendo-react-scheduler";
import "../../../../styles/eventStyles.scss";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { useModal } from "../../../../hooks/useModal";
import { deleteEvent } from "../../../../services/Deprecated/customer/eventServices";
import { EventModal } from "../../CalendarEvents/Components/EventModal/EventModal";
import ConfirmDialogue from "../../../../components/Deprecated/ConfirmDialogue";

export const CustomEditItem = (props) => {
    const { bool: deleteVisible, toggle: toggleDeleteVisible } = useToggle();
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const [selectedEvent, setSelectedEvent] = useState(undefined);
    const modal = useModal();
    const reloadDataContext = useContext(ReloadDataContext);

    const onDeleteConfirm = () => {
        toggleLoaderVisible();

        if (selectedEvent === undefined) {
            toggleDeleteVisible();
            toggleLoaderVisible();
            return;
        }

        deleteEvent(selectedEvent.id).then(() => {
            setSelectedEvent(undefined);
            reloadDataContext.triggerReload();
            toggleDeleteVisible();
            toggleLoaderVisible();
        });
    };

    const onRemoveClick = (event) => {
        const dataItem = event.target.props.dataItem;
        setSelectedEvent(dataItem);
        toggleDeleteVisible();
    };

    const onEditClick = (event) => {
        modal.open(event.target.props.dataItem.id);
    };

    return (
        <>
            <EventModal {...modal} />
            <ConfirmDialogue
                visible={deleteVisible}
                onConfirm={onDeleteConfirm}
                onCancel={toggleDeleteVisible}
                loaderVisible={loaderVisible}
                actionName={"Delete"}
            />
            <SchedulerEditItem
                showRemoveDialog={false}
                showOccurrenceDialog={false}
                {...props}
                onRemoveClick={onRemoveClick}
                onDoubleClick={onEditClick}
            />
        </>
    );
};
