import { axiosInstance as axios } from "../../../../services/axiosInstance";
import React, { useContext, useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { RoleModal } from "./Components/RoleModal";
import { useModal } from "../../../../hooks/useModal";
import { PermissionContext } from "../../../../providers/Deprecated/Authentication/Permission/PermissionListBoxProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import PermissionButton from "../../../../components/Deprecated/PermissionButton";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import DefaultRoleModal from "./Components/DefaultRoleModal";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import PermissionListBox from "../../../../components/Deprecated/PermissionListBox";
import {
    getAllPermissions,
    updatePermissionsRole,
} from "../../../../services/Deprecated/authentication/permissionService";

const RoleDashboardPage = () => {
    const [roleData, setRoleData] = useState([]);
    const reloadDataContext = useContext(ReloadDataContext);
    const permissionContext = useContext(PermissionContext);
    const roleModal = useModal();
    const { selectedRole, setSelectedRole } = permissionContext;
    const { setAllPermissions } = permissionContext;

    const [notificationState, setNotificationState] = useState({
        visible: false,
        message: "",
        type: "",
    });

    const displayNotification = (message, type) => {
        setNotificationState({ visible: true, message, type });
        setTimeout(
            () =>
                setNotificationState({ visible: false, message: "", type: "" }),
            3000
        );
    };

    const { permissionGroups, getAssignedPermissionsCountForGroup } =
        permissionContext;

    const handleRoleChange = (event) => {
        console.log(event.target.value);
        setSelectedRole(event.target.value);
    };

    useEffect(() => {
        axios
            .get("/Authentication/Role/GetAll")
            .then((response) => {
                setRoleData(response.data.data);
                if (selectedRole?.id) {
                    const updatedRole = response.data.data.find(
                        (x) => x.id === selectedRole.id
                    );
                    setSelectedRole(updatedRole);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [reloadDataContext.reloadData]);

    const deleteRole = async () => {
        try {
            // Make a DELETE request to the API to delete the role
            const response = await axios.delete(
                `/Authentication/Role/Delete?id=${selectedRole.id}`
            );
            if (response.status === 200) {
                // Handle success, e.g., show a success message, update state, etc.
                reloadDataContext.setReloadData(Math.random());
                setSelectedRole(null);
            }
            displayNotification(
                "RoleDashboardPage deleted successfully!",
                "success"
            );
        } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error("Error deleting role:", error);
            displayNotification(
                "Error deleting role. Please try again.",
                "error"
            );
        }
    };

    const savePermissions = async () => {
        try {
            let assigned = [];
            // Loop through each groupName in permissionListBoxState
            for (const groupName in permissionContext.permissionListBoxState) {
                const group =
                    permissionContext.permissionListBoxState[groupName]; // Get the group object for the current groupName
                // Concatenate the 'assigned' array with the 'assigned' array of the current group
                assigned = assigned.concat(group.assigned);
            }

            const permissionIds = assigned.map((item) => item.id);
            const roleId = selectedRole.id;
            // Use updatePermissionsRole function to save the changes
            const serviceResponse = await updatePermissionsRole(
                roleId,
                permissionIds
            );

            // Handle the service response as needed
            if (serviceResponse.success) {
                roleData.rolePermissions = assigned;
            } else {
                console.error(
                    "Error saving permissions:",
                    serviceResponse.error
                );
            }
            // Handle the API response as needed (e.g., show a success message)
            roleData.rolePermissions = assigned;
            reloadDataContext.triggerReload();
            displayNotification("Permissions saved successfully!");
        } catch (error) {
            console.error("Error saving permissions:", error);
            displayNotification(
                error.message || "Failed to save permissions.",
                "error"
            );
        }
    };
    useEffect(() => {
        getAllPermissions()
            .then((serviceResponse) => {
                setAllPermissions(serviceResponse.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <RoleModal {...roleModal} />
            <span
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                }}
            >
                <span
                    style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                        gap: "5px",
                    }}
                >
                    <PermissionButton
                        requiredPermissions={[PermissionsEnum.CreateRole]}
                        themeColor={"primary"}
                        fillMode={"solid"}
                        rounded={"medium"}
                        icon={"k-icon k-i-plus"}
                        onClick={() => roleModal.open()}
                    >
                        Create New Role
                    </PermissionButton>
                    {/* DEFAULT ROLE MODAL */}
                    <DefaultRoleModal />
                </span>
                <div className="role-dropdown">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            marginLeft: "5px",
                        }}
                    >
                        <DropDownList
                            dataItemKey="id"
                            textField="name"
                            data={roleData}
                            onChange={handleRoleChange}
                            value={selectedRole}
                            label={"Select a Role:"}
                            style={{
                                width: "180px",
                                marginTop: "5px",
                            }}
                        />
                        {selectedRole && (
                            // Conditionally render the buttons when selectedRole is not null
                            <div
                                style={{
                                    paddingTop: "25px",
                                    paddingLeft: "10px",
                                }}
                            >
                                <table>
                                    <tbody>
                                        <tr>
                                            <ActionButtonsCommandCell
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                }}
                                                record={selectedRole}
                                                dataId={selectedRole?.id}
                                                action={deleteRole}
                                                requiredPermissionsEdit={[
                                                    PermissionsEnum.UpdateRole,
                                                ]}
                                                requiredPermissionsAction={[
                                                    PermissionsEnum.DeleteRole,
                                                ]}
                                            />
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
                <div></div>
            </span>
            {selectedRole && (
                <div className="panelbar-wrapper">
                    <div className="save-button-container">
                        <PermissionButton
                            requiredPermissions={[PermissionsEnum.UpdateRole]}
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={savePermissions}
                            style={{ margin: "10px" }}
                        >
                            Save Permissions
                        </PermissionButton>
                    </div>
                    <PanelBar expandMode={"single"}>
                        {permissionGroups.map((pg, i) => {
                            const assignedPermissionsCount =
                                getAssignedPermissionsCountForGroup(pg.name);

                            const panelBarItemTitle = `${pg.title} (${assignedPermissionsCount} assigned)`;
                            return (
                                <PanelBarItem key={i} title={panelBarItemTitle}>
                                    <PermissionListBox
                                        assignedPermissionsForRole={
                                            selectedRole
                                        }
                                        desiredPermissionGroupIds={
                                            pg.permissionGroupIds
                                        }
                                        permissionGroupHeader={pg.name}
                                    />
                                </PanelBarItem>
                            );
                        })}
                    </PanelBar>
                </div>
            )}
            {selectedRole && (
                <div className="second-save-button-container">
                    <PermissionButton
                        requiredPermissions={[PermissionsEnum.UpdateRole]}
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={savePermissions}
                        style={{ margin: "10px" }}
                    >
                        Save Permissions
                    </PermissionButton>
                </div>
            )}
            {notificationState.visible && (
                <NotificationGroup
                    style={{
                        right: 5,
                        bottom: 5,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }}
                >
                    <Notification
                        type={{ style: "success", icon: true }}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "1rem",
                            width: "auto",
                            height: "40px",
                            fontSize: "1rem",
                        }}
                        closable={true}
                        onClose={() =>
                            setNotificationState({
                                visible: false,
                                message: "",
                                type: "",
                            })
                        }
                    >
                        <span>{notificationState.message}</span>
                    </Notification>
                </NotificationGroup>
            )}
        </div>
    );
};

export default RoleDashboardPage;
