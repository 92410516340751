import BaseService from "./BaseService";
import { formatServiceResponse } from "../utilities/serviceUtility.js";
import { axiosInstance as axios } from "./axiosInstance";
import { VendorDocumentType } from "../resources/Enums/VendorDocumentType.js";

/**
 * @class VendorDocumentService
 * @extends BaseService
 * @description Service class that handles all requests to the vendor document service
 * @constructor
 * @param uri {String} - The base URI for the service
 */
export default class VendorDocumentService extends BaseService {
    constructor() {
        super("Inventory/Vendor/Document");
    }

    /**
     * @function getAllPurchaseOrders
     * @description Function to get all purchase orders
     * @async
     * @param {string} [jobId]
     * @returns {Promise<ServiceResponse>} response.data
     */
    async getAllPurchaseOrders(jobId) {
        const type = VendorDocumentType.Enum.PurchaseOrder;
        const uri = jobId
            ? `${this.uri}/GetAll?filter=(jobId~eq~'${jobId}'~and~type~eq~'${type}')&`
            : `${this.uri}/GetAll?filter=(type~eq~'${type}')&`;
        const response = await axios.get(uri);
        return formatServiceResponse(response);
    }

    /**
     * @function getSummary
     * @description Returns the VendorDocument summary
     * @async
     * @param {string} jobId
     * @returns {Promise<ServiceResponse>} response.data
     */
    async getSummary(jobId) {
        if (!jobId) {
            throw new Error("Job ID is required");
        }

        const response = await axios.get(
            `${this.uri}/GetJobSummary?jobId=${jobId}&filter=(type~eq~'0')&`
        );
        return formatServiceResponse(response);
    }

    /**
     * @function changeStatus
     * @description Function to change the status of a document
     * @param id {string}
     * @param status {VendorDocumentType.Enum}
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async changeStatus(id, status) {
        const response = await axios.post(`${this.uri}/ChangeStatus?`, {
            documentId: id,
            type: status,
        });
        return formatServiceResponse(response);
    }

    /**
     * @function receive
     * @description Function to receive a line item
     * @param data {Object} - The data object
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async receive(data) {
        const res = await axios.post(`${this.uri}/LineItem/Receive?`, {
            quantity: data.quantity,
            lineItemId: data.lineItemId,
            storageLocationId: data.storageLocationId,
        });
        return formatServiceResponse(res);
    }

    /**
     * @function unReceive
     * @description Function to un-receive a line item
     * @param data {Object} - The data object
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async unReceive(data) {
        const res = await axios.post(`${this.uri}/LineItem/UnReceive?`, {
            quantity: data.quantity,
            lineItemId: data.lineItemId,
            storageLocationId: data.storageLocationId,
        });
        return formatServiceResponse(res);
    }
}
