/**
 * @class JobDocumentHelper
 * @description Helper class for job documents
 * @exports JobDocumentHelper
 */
export class JobDocumentHelper {
    /**
     * @function getRemainder
     * @description Calculates the remainder of the total minus the
     * total paid amount.
     * @param dataItem.total.amount - The total amount of the document
     * @param dataItem.totalPaid.amount - The total amount paid on the document
     * @return {number}
     */
    static getRemainder = (dataItem) => {
        return dataItem.total.amount - dataItem.totalPaid.amount;
    };
}
