import React, {useContext, useEffect, useState} from "react";
import {GridColumn} from "@progress/kendo-react-grid";
import {bulkPriceChange, createItem, deleteItem, getAllItemTypes, getItem, updateInLineGridItem,} from "../../../services/Deprecated/inventory/itemService";
import ItemRelationshipsProvider from "../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import {PermissionsEnum} from "../../../resources/Enums/PermissionsEnum";
import {YesNoBooleanCell} from "../../../components/Deprecated/CustomGridCells";
import {useModal} from "../../../hooks/useModal";
import {ActionButton} from "../../../components/Buttons/ActionButton";
import {PriceChangeModal, PriceChangeTypes,} from "../Components/PriceChangeModal";
import {FilterOperator} from "../../../resources/Enums/FilterOperator";
import {DataGrid} from "../../../components/Grids/DataGrid";
import {ReloadDataContext} from "../../../providers/ReloadDataProvider";
import DetailItemService from "../../../services/Deprecated/customer/DetailItemService";
import {ActionCell} from "../../../components/GridColumnComponents/ActionCell";
import ActionButtonsCommandCell from "../../../components/Deprecated/ActionButtonsCommandCell";
import {EditableDropDownCell} from "../../../components/GridColumnComponents/EditableDropDownCell";
import {EditableTextCell} from "../../../components/GridColumnComponents/EditableTextCell";
import {ToggleButton} from "../../../components/Buttons/ToggleButton/ToggleButton";
import ItemPackageService from "../../../services/Deprecated/inventory/ItemPackageService";
import {EditableNumericCell} from "../../../components/GridColumnComponents/EditableNumericCell";
import {createVendorItem, getVendorItemsByItemId,} from "../../../services/Deprecated/inventory/vendorService";
import itemEditForm from "./Components/ItemEditForm";
import {DetailItemModal} from "./DetailItems/Components/DetailItemModal";
import {ItemPackageModal} from "./Packages/Components/ItemPackageModal";

/**
 * @function ItemDashboardPage
 * @description A dashboard for displaying and managing items
 * @param [props.initialFilter] {object} - Initial filter to apply to the grid
 * @return {Element}
 * @constructor
 */
const ItemDashboardPage = (props) => {
    const { initialFilter } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const detailItemModal = useModal();
    const priceChangeModal = useModal();
    const itemPackageModal = useModal();
    const [hideColumns, setHideColumns] = useState(false);
    const [columnsMayHideAutomatically, setColumnsMayHideAutomatically] =
        useState(false);
    const [itemTypes, setItemTypes] = useState([]);
    const [isDuplicating, setIsDuplicating] = useState(false);

    useEffect(() => {
        const handleColumnHide = () => {
            if (window.innerWidth < 1700) {
                setHideColumns(true);
                setColumnsMayHideAutomatically(true);
            } else {
                setHideColumns(false);
                setColumnsMayHideAutomatically(false);
            }
        };

        // Add event listener for window resize
        window.addEventListener("resize", () => {
            handleColumnHide();
        });

        // Set initial
        handleColumnHide();
    }, []);

    useEffect(() => {
        getAllItemTypes().then((data) => setItemTypes(data.data));
    }, []);

    const grabCategoryIdFromFilter = () => {
        if (!initialFilter) return undefined;

        const categoryId = initialFilter.filters.find(
            (f) => f.field === "categoryId"
        );

        return categoryId ? categoryId.value : undefined;
    };

    const onPriceChange = async (data) => {
        const gridSearchQuery = document.getElementById("gridStateSearch");
        const gridSearchOperator = document.getElementById(
            "gridStateSearchToggle"
        );
        const enumerable =
            gridSearchOperator.value === "true"
                ? FilterOperator.Enum.StartsWith
                : FilterOperator.Enum.Contains;

        await bulkPriceChange({
            categoryId: grabCategoryIdFromFilter(),
            query: gridSearchQuery.value,
            operator: enumerable,
            priceChangeInformation: data,
        });
    };

    const onSaveChanges = async (data) => {
        for (const item of data) {
            if (item.isDetailItem) {
                await DetailItemService.updateInLineGrid(item);
            } else if (item?.itemType?.name === "Package") {
                await ItemPackageService.updateInLineGrid(item);
            } else {
                await updateInLineGridItem({
                    ...item,
                    itemTypeId: item.itemType.id,
                });
            }
        }

        triggerReload();
    };

    const duplicateItem = async (dataItem) => {
        if (isDuplicating) return; // Prevent multiple duplicates
        setIsDuplicating(true);

        try {
            // Fetch the full details of the item
            const itemResponse = await getItem(dataItem.id);

            if (!itemResponse.success) {
                console.error(
                    "Failed to fetch item details:",
                    itemResponse.errors
                );
                setIsDuplicating(false);
                return;
            }

            const fullItemDetails = itemResponse.data;

            // Create a duplicated item
            const duplicatedItem = {
                sku: fullItemDetails.sku,
                name: `${fullItemDetails.name} - Duplicate`,
                baseDescription: fullItemDetails.baseDescription,
                purchaseOrderDescription:
                    fullItemDetails.purchaseOrderDescription,
                retailPrice: fullItemDetails.retailPrice,
                manufacturerNumber: fullItemDetails.manufacturerNumber,
                isActive: fullItemDetails.isActive,
                isStockOrSupplyItem: fullItemDetails.isStockOrSupplyItem,
                isDescriptionEditAllowed:
                    fullItemDetails.isDescriptionEditAllowed,
                defaultLength: fullItemDetails.defaultLength,
                defaultWidth: fullItemDetails.defaultWidth,
                orderingUomId: fullItemDetails.orderingUom.id,
                retailUomId: fullItemDetails.retailUom.id,
                categoryId: fullItemDetails.category.id,
                itemTypeId: fullItemDetails.itemType.id,
                expenseAccountId: fullItemDetails.expenseAccount.id,
                incomeAccountId: fullItemDetails.incomeAccount.id,
                id: null, // Let the backend generate a new ID
            };

            // Send the request to create the new item using createItem
            const newItemResponse = await createItem(duplicatedItem);

            if (!newItemResponse || !newItemResponse.data) {
                console.error("Item creation failed.");
                setIsDuplicating(false);
                return;
            }

            const newItemId = newItemResponse.data.id;

            // Fetch Vendor Associations for the original item
            const vendorItemsResponse = await getVendorItemsByItemId(
                fullItemDetails.id
            );

            if (!vendorItemsResponse.success) {
                console.error(
                    "Failed to fetch vendor items:",
                    vendorItemsResponse.errors
                );
                setIsDuplicating(false);
                return;
            }

            const vendorItems = vendorItemsResponse.data;

            // Duplicate vendor associations
            const vendorAssociationRequests = vendorItems.map((vendorItem) => {
                return createVendorItem({
                    itemId: newItemId,
                    vendorId: vendorItem.vendor.id,
                    vendorCost: {
                        amount: vendorItem.vendorCost.amount,
                        currency: "USD",
                    },
                    freightCharge: {
                        amount: vendorItem.freightCharge.amount,
                        currency: "USD",
                    },
                });
            });

            // Wait for all vendor associations to be created
            await Promise.all(vendorAssociationRequests);

            // Trigger a reload to update the grid UI
            triggerReload();
        } catch (error) {
            console.error("Error duplicating item:", error);
        } finally {
            setIsDuplicating(false); // Reset duplicating flag
        }
    };

    const onDetailItemDelete = (id) => {
        DetailItemService.delete(id).then(() => triggerReload());
    };

    const onItemDelete = (id) => {
        deleteItem(id);
        triggerReload();
    };

    const onPackageDelete = (id) => {
        ItemPackageService.delete(id).then(() => triggerReload());
    };

    const fetchActions = (dataItem) => {
        const duplicateButton = (
            <ActionButton
                buttonText={"Duplicate"}
                theme={"primary"}
                onClick={() => duplicateItem(dataItem)}
                permissions={[PermissionsEnum.UpdateItem]}
                icon={"k-icon k-i-copy"}
            />
        );

        switch (dataItem.itemType?.name) {
            case "Detail":
                return (
                    <ActionCell
                        onDelete={() => onDetailItemDelete(dataItem.id)}
                        onEdit={() => detailItemModal.open(dataItem.id)}
                        permissions={{
                            view: [PermissionsEnum.ViewItem],
                            edit: [PermissionsEnum.UpdateItem],
                            delete: [PermissionsEnum.DeleteItem],
                        }}
                    />
                );
            case "Package":
                return (
                    <ActionCell
                        onDelete={() => onPackageDelete(dataItem.id)}
                        onEdit={() => itemPackageModal.open(dataItem.id)}
                        permissions={{
                            edit: [PermissionsEnum.UpdateItem],
                            delete: [PermissionsEnum.DeleteItem],
                        }}
                    />
                );
            case "Duplicate":
                return (
                    <ActionCell
                        onDelete={() => onItemDelete(dataItem.id)}
                        onEdit={() => itemEditForm.open(dataItem.id)}
                        permissions={{
                            edit: [PermissionsEnum.UpdateItem],
                            delete: [PermissionsEnum.DeleteItem],
                        }}
                    />
                );
            default:
                return (
                    <ActionButtonsCommandCell
                        record={dataItem}
                        dataId={dataItem.id}
                        action={deleteItem}
                        EditComponent={itemEditForm}
                        viewPage={{
                            uri: `/Inventory/Item/${dataItem.id}`,
                            breadcrumbInfo: [
                                {
                                    id: dataItem.id,
                                    text: dataItem.name,
                                },
                            ],
                        }}
                        requiredPermissionsEdit={[PermissionsEnum.UpdateItem]}
                        requiredPermissionsAction={[PermissionsEnum.DeleteItem]}
                        additionalButtons={[duplicateButton]}
                    />
                );
        }
    };

    return (
        <ItemRelationshipsProvider>
            <div className={"JustifyLeftAlignLeft FlexColumn LargeGap"}>
                <DetailItemModal {...detailItemModal} />
                <ItemPackageModal {...itemPackageModal} />
                <PriceChangeModal
                    {...priceChangeModal}
                    submitCallback={onPriceChange}
                    fields={[
                        {
                            id: 1,
                            name: "Retail Price",
                        },
                    ]}
                    types={[
                        PriceChangeTypes.Dollar,
                        PriceChangeTypes.Percent,
                        PriceChangeTypes.PercentGreaterThanMaxCost,
                    ]}
                />
                <ActionButton
                    buttonText={"Apply a Price Change"}
                    theme={"primary"}
                    onClick={() => priceChangeModal.open()}
                    permissions={[PermissionsEnum.UpdateItem]}
                    icon={"k-icon k-i-dollar"}
                />
                {columnsMayHideAutomatically && (
                    <ToggleButton
                        onToggleOn={() => setHideColumns(false)}
                        onToggleOff={() => setHideColumns(true)}
                        toggled={!hideColumns}
                        textStyle={{
                            fontWeight: 400,
                            fontSize: "14px",
                        }}
                        label={"Show All Columns"}
                    />
                )}
                <DataGrid
                    style={{
                        maxWidth: "99.99%",
                    }}
                    onSaveChanges={onSaveChanges}
                    useInlineEdit={true}
                    uri={"/Customer/Job/Item/GetAll?"}
                    initialFilter={initialFilter}
                    searchFields={[
                        "name",
                        "baseDescription",
                        "purchaseOrderDescription",
                    ]}
                    resizable={true}
                    initialSort={[
                        {
                            field: "name", // Sorting by name field
                            dir: "asc", // Ascending order
                        },
                    ]}
                >
                    <GridColumn
                        className={"k-table-td"}
                        field={"name"}
                        title={"Item:"}
                        width={300}
                        minResizableWidth={250}
                        cell={(props) => (
                            <EditableTextCell
                                {...props}
                                editField={"IN_LINE_EDIT"}
                            />
                        )}
                    />
                    {!hideColumns && (
                        <GridColumn
                            className={"k-table-td"}
                            field="baseDescription"
                            title="SE/SO Description:"
                            width={300}
                            minResizableWidth={100}
                            cell={(props) => (
                                <EditableTextCell
                                    {...props}
                                    editField={"IN_LINE_EDIT"}
                                />
                            )}
                        />
                    )}
                    {!hideColumns && (
                        <GridColumn
                            className={"k-table-td"}
                            field="purchaseOrderDescription"
                            title="PO Description:"
                            width={300}
                            minResizableWidth={100}
                            cell={(props) => (
                                <EditableTextCell
                                    {...props}
                                    editField={"IN_LINE_EDIT"}
                                    disabled={
                                        props.dataItem.itemType?.name ===
                                            "Detail" ||
                                        props.dataItem.itemType?.name ===
                                            "Package"
                                    }
                                />
                            )}
                        />
                    )}
                    {!hideColumns && (
                        <GridColumn
                            cell={(props) => (
                                <EditableTextCell
                                    {...props}
                                    editField={"IN_LINE_EDIT"}
                                    disabled={
                                        props.dataItem.itemType?.name ===
                                            "Detail" ||
                                        props.dataItem.itemType?.name ===
                                            "Package"
                                    }
                                />
                            )}
                            field="manufacturerNumber"
                            title="MFR #:"
                            width={100}
                            minResizableWidth={50}
                        />
                    )}
                    {!hideColumns && (
                        <GridColumn
                            editable={false}
                            field="itemType"
                            title="Item Type:"
                            width={250}
                            minResizableWidth={100}
                            cell={(props) => (
                                <EditableDropDownCell
                                    {...props}
                                    editField={"IN_LINE_EDIT"}
                                    data={itemTypes}
                                    textField={"name"}
                                    disabled={
                                        props.dataItem.itemType?.name ===
                                            "Detail" ||
                                        props.dataItem.itemType?.name ===
                                            "Package"
                                    }
                                />
                            )}
                        />
                    )}
                    <GridColumn
                        editor={"numeric"}
                        field="retailPrice.amount"
                        title="Retail Price:"
                        width={150}
                        minResizableWidth={50}
                        cell={(props) => (
                            <EditableNumericCell
                                {...props}
                                editField={"IN_LINE_EDIT"}
                                isCurrency={true}
                                disabled={
                                    props.dataItem.itemType?.name === "Package"
                                }
                            />
                        )}
                    />
                    <GridColumn
                        editable={false}
                        field="maxVendorCost.amount"
                        title="Max Vendor Cost:"
                        width={150}
                        minResizableWidth={50}
                        format={"{0:c}"}
                    />
                    <GridColumn
                        width={75}
                        editor={"boolean"}
                        field="isActive"
                        title="Active:"
                        resizable={false}
                        cells={{
                            data: YesNoBooleanCell,
                        }}
                    />
                    <GridColumn
                        width={275}
                        resizable={false}
                        filterable={false}
                        editable={false}
                        sortable={false}
                        cell={(props) => fetchActions(props.dataItem)}
                    />
                </DataGrid>
            </div>
        </ItemRelationshipsProvider>
    );
};

export default ItemDashboardPage;
